import {call, put} from "@redux-saga/core/effects";
import {logError} from "redux/actions/general-actions";
import {setRecords} from "redux/actions/searchbar-actions";
import RestClient from "util/RestClient";

export function* getRecords() {
	try {
		const res = yield call(async () => {
			return await RestClient.getRecordsList();
		});
		if (res.status === 200) {
			yield put(setRecords(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("searchbar-saga/getRecords: " + e.message))
	}
}
