import {addMissingTag, fetchTranslation, getLanguages} from "redux/actions/translation-actions";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

export default function TranslationManager(props) {
	const [currentLang, setCurrentLang] = useState(null);
	// TO GÓWNO ZAJEBIŚCIE OBCIĄŻA APLIKACJE, DZIAŁA ALE LEDWO

	// const {i18n} = useTranslation();

	// const debugMissingKey = (e) => {
	// 	console.debug(e.key);
	// 	props.addMissingTag(e.key);
	// };

	// const missingKey = () => {
	// 	if (!window.localStorage.translation) return;
	// 	window.localStorage.removeItem("translation");
	// };

	// useEffect(() => {
	// 	if (process.env.NODE_ENV === "development") {
	// 		i18n.on("missingKey", (lngs, namespace, key, res) => debugMissingKey({languages: lngs, namespace: namespace, key: key, res: res}));
	// 	} else {
	// 		i18n.on("missingKey", (lngs, namespace, key, res) => missingKey({languages: lngs, namespace: namespace, key: key, res: res}));
	// 	}
	// }, []);

	useEffect(() => {
		props.getLanguages();
		let currentLang = window.localStorage.language;
		if (currentLang) {
			setCurrentLang(currentLang);
		}
	}, []);

	useEffect(() => {
		if (props.loginReducer.currentUser?.selectedLanguage?.code && (!currentLang || props.loginReducer.currentUser.selectedLanguage.code !== window.localStorage.language)) {
			setCurrentLang(props.loginReducer.currentUser.selectedLanguage.code);
		}
	}, [props.loginReducer.currentUser?.selectedLanguage?.code]);

	useEffect(() => {
		if (!currentLang) return;
		props.fetchLanguage(currentLang);
	}, [currentLang]);

	return <></>;
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	getLanguages: () => {
		dispatch(getLanguages());
	},
	fetchLanguage: (code) => {
		dispatch(fetchTranslation(code));
	},
	addMissingTag: (tag) => {
		dispatch(addMissingTag(tag));
	},
});

TranslationManager = connect(mapStateToProps, mapDispatchToProps)(TranslationManager);
