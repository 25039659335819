export const GET_USER_BY_ID = "USERS/GET_BY_ID";
export const SET_USER = "USERS/SET";
export const UPDATE_USER = "USERS/UPDATE";
export const SET_USER_LOADING_STATUS = "USERS/SET_LOADING";
export const ADD_USER = "USERS/ADD_USER";

export const addUser = (form) => ({
	type: ADD_USER,
	payload: {
		form,
	},
});
export const setUserLoadingStatus = (isLoading) => ({
	type: SET_USER_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getUserById = (userId) => ({
	type: GET_USER_BY_ID,
	payload: {
		userId,
	},
});

export const setUser = (user) => ({
	type: SET_USER,
	payload: {
		user,
	},
});

export const updateUser = (form) => ({
	type: UPDATE_USER,
	payload: {
		form,
	},
});
