import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SideNav from "components/Layout/SideNav";
import {Navbar, Button} from "reactstrap";
import {faAlignJustify} from "@fortawesome/free-solid-svg-icons";
import "styles/General/Layout.scss";
import Searchbar from "./Searchbar";
import {setSideNavVisibility} from "redux/actions/general-actions";
import {connect} from "react-redux";
import Location from "./Location";
import useWindowSize from "hooks/useWindowSize";
import {useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {preloadStores} from "util/DevExtremeStores";
import {useTranslation} from "react-i18next";

export default function Layout(props) {
	const size = useWindowSize();
	const url = props.generalReducer.url;
	const collapsed = props.generalReducer.sideNavCollapsed;
	const {t} = useTranslation();
	const location = useLocation();
	const isDistributors = props.loginReducer.currentUser?.dealerId !== 1 && location.pathname.includes("distributors");
	const toggleSideNav = () => {
		props.setVisibility(!collapsed);
	};
	useEffect(() => {
		preloadStores();
	}, []);

	return (
		<>
			<div className="layout">
				<Navbar>
					<Button id="toggle-button" onClick={toggleSideNav} disabled={size.width > 680}>
						<FontAwesomeIcon icon={faAlignJustify} color="white" />
					</Button>
					<Searchbar />
					{size.width > 680 && <div style={{width: "2rem", height: "2rem"}} />}
				</Navbar>
				<SideNav collapsed={collapsed} />
				<div className="content" id="EssScrollLight">
					{!url ? (
						<>
							{isDistributors ? <div className="location">{t("Distributor")}</div> : <Location />}
							<div className="page-content">
								<Outlet />
							</div>
						</>
					) : (
						<iframe src={url} style={{height: "100%", width: "100%"}}></iframe>
					)}
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	generalReducer: state.general,
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	setVisibility: (collapsed) => {
		dispatch(setSideNavVisibility(collapsed));
	},
});

Layout = connect(mapStateToProps, mapDispatchToProps)(Layout);
