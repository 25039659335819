import {call, put} from "@redux-saga/core/effects";
import {showAlert} from "redux/actions/Alert-actions";
import {setDealerLoadingStatus} from "redux/actions/dealer-actions";
import {setDealer} from "redux/actions/dealer-actions";
import {logError} from "redux/actions/general-actions";
import {pushValue} from "redux/actions/searchbar-actions";
import RestClient from "util/RestClient";
import i18n from "../../i18n";

export function* getDealerById(action) {
	yield put(setDealerLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getDealerById(payload.dealerId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setDealer(res.data));
			yield put(pushValue(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("dealers-saga/getDealerById: " + e.message))
	}
	yield put(setDealerLoadingStatus(false));
}

export function* updateDealer(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.updateDealer(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(setDealer(res.data));
			yield put(pushValue(res.data));
			yield put(showAlert("success", i18n.t("Distributor information updated")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("dealers-saga/updateDealer: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't save distributor information")));
	}
}
