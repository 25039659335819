import {SHOW_ALERT, RESET_ALERT} from "../actions/Alert-actions";

const initialState = {
	msg: "",
	timeOut: true,
	color: "",
};

export const alertReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_ALERT: {
			return {...state, timeOut: action.payload.timeOut, msg: action.payload.message, color: action.payload.color};
		}
		case RESET_ALERT: {
			return initialState;
		}
		default:
			return state;
	}
};
