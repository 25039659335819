import {call, put} from "redux-saga/effects";
import RestClient from "../../util/RestClient";
import {showAlert} from "../actions/Alert-actions";
import {setUser} from "redux/actions/users-actions";
import {logError} from "redux/actions/general-actions";
import {setUserLoadingStatus} from "redux/actions/users-actions";
import {pushValue} from "redux/actions/searchbar-actions";
import i18n from "../../i18n";

export function* getUserById(action) {
	yield put(setUserLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getUserById(payload.userId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setUser(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("users-saga/getUserById: " + e.message))
	}
	yield put(setUserLoadingStatus(false));
}

export function* addUser(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.addUser(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(pushValue(res.data));
			yield put(showAlert("success", i18n.t("User added successfully")));
		} else {
			throw "Can't save new user";
		}
	} catch (e) {
		console.error(e);
		if (e.response.data.includes("Email or login")) {
			yield put(showAlert("danger", i18n.t("Email or login is already registered")));
		} else {
			yield put(showAlert("danger", i18n.t("Couldn't save user information")));
		}
	}
}

export function* updateUser(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.updateUser(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(setUser(res.data));
			yield put(pushValue(res.data));
			yield put(showAlert("success", i18n.t("User updated successfully")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("users-saga/updateUser: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't save user information")));
	}
}
