import React from "react";
import {connect} from "react-redux";
import {resetAlert} from "redux/actions/Alert-actions";

function GlobalAlert(props) {
	const msg = props.alerts.msg;
	// const msg = "TESTOWY ALERT KURWO"
	const color = props.alerts.color === "success" ? {background: "#d4edda", color: "#175726", border: "1px solid #c4e6cc"} : {background: "#f8d7da", color: "#791b26", border: "1px solid #F4C6CB"};
	// const color = "success";
	const timeOut = props.alerts.timeOut;

	if (msg !== "" && timeOut) {
		setTimeout(() => {
			props.resetAlert();
		}, 3000);
	}

	return (
		msg !== "" && (
			<div className="status-popup" style={color}>
				<b style={{fontFamily: "Open Sans"}}>{msg}</b>
			</div>
		)
	);
}

const mapStateToProps = (state) => ({
	alerts: state.alerts,
});

const mapDispatchToProps = (dispatch) => ({
	resetAlert: () => {
		dispatch(resetAlert());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlert);
