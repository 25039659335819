import {SET_DEALER_LOADING_STATUS} from "redux/actions/dealer-actions";
import {SET_DEALER} from "redux/actions/dealer-actions";

const initialState = {
	fetchedDealer: null,
	isLoading: true,
};

export const dealersReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DEALER: {
			return {...state, fetchedDealer: action.payload.dealer};
		}
		case SET_DEALER_LOADING_STATUS: {
			return {...state, isLoading: action.payload.isLoading};
		}
		default:
			return state;
	}
};
