import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faUser as User,
	faUserNinja as Ninja,
	faUserTie as Corp,
	faUserSecret as Incognito,
	faUserNurse as Nurse,
	faUserInjured as Injured,
	faUserGraduate as Graduate,
	faUserAstronaut as Astronaut,
	faAngry,
	faAtom,
	faBiohazard,
	faBomb,
	faCat,
	faChessKing,
	faCrow,
	faDiceD20,
	faDog,
	faDove,
	faDragon,
	faFish,
	faFlushed,
	faFrog,
	faGem,
	faGhost,
	faGrinTears,
	faHatWizard,
	faJedi,
	faMask,
	faMeteor,
	faPeace,
	faPizzaSlice,
	faRobot,
	faSkull,
	faSnowman,
	faSpider,
	faSun,
	faUserMd,
	faYinYang,
} from "@fortawesome/free-solid-svg-icons";

export default function UserAvatar(props) {
	const invert = props.invert;
	const [Avatar, setAvatar] = useState(User);
	useEffect(() => {
		switch (props.children) {
			case 1: {
				setAvatar(User);
				break;
			}
			case 2: {
				setAvatar(Ninja);
				break;
			}
			case 3: {
				setAvatar(Corp);
				break;
			}
			case 4: {
				setAvatar(Incognito);
				break;
			}
			case 5: {
				setAvatar(Nurse);
				break;
			}
			case 6: {
				setAvatar(Injured);
				break;
			}
			case 7: {
				setAvatar(Graduate);
				break;
			}
			case 8: {
				setAvatar(Astronaut);
				break;
			}
			case 9: {
				setAvatar(faUserMd);
				break;
			}
			case 10: {
				setAvatar(faAtom);
				break;
			}
			case 11: {
				setAvatar(faBiohazard);
				break;
			}
			case 12: {
				setAvatar(faBomb);
				break;
			}
			case 13: {
				setAvatar(faCat);
				break;
			}
			case 14: {
				setAvatar(faChessKing);
				break;
			}
			case 15: {
				setAvatar(faCrow);
				break;
			}
			case 16: {
				setAvatar(faDiceD20);
				break;
			}
			case 17: {
				setAvatar(faDog);
				break;
			}
			case 18: {
				setAvatar(faDove);
				break;
			}
			case 19: {
				setAvatar(faDragon);
				break;
			}
			case 20: {
				setAvatar(faFish);
				break;
			}
			case 21: {
				setAvatar(faFlushed);
				break;
			}
			case 22: {
				setAvatar(faFrog);
				break;
			}
			case 23: {
				setAvatar(faGem);
				break;
			}
			case 24: {
				setAvatar(faGhost);
				break;
			}
			case 25: {
				setAvatar(faGrinTears);
				break;
			}
			case 26: {
				setAvatar(faHatWizard);
				break;
			}
			case 27: {
				setAvatar(faJedi);
				break;
			}
			case 28: {
				setAvatar(faMask);
				break;
			}
			case 29: {
				setAvatar(faMeteor);
				break;
			}
			case 30: {
				setAvatar(faPeace);
				break;
			}
			case 31: {
				setAvatar(faPizzaSlice);
				break;
			}
			case 32: {
				setAvatar(faRobot);
				break;
			}
			case 33: {
				setAvatar(faSkull);
				break;
			}
			case 34: {
				setAvatar(faSnowman);
				break;
			}
			case 35: {
				setAvatar(faSpider);
				break;
			}
			case 36: {
				setAvatar(faSun);
				break;
			}
			case 37: {
				setAvatar(faAngry);
				break;
			}
			case 38: {
				setAvatar(faYinYang);
				break;
			}
			default: {
				setAvatar(User);
				break;
			}
		}
	}, [props.children]);
	return <FontAwesomeIcon color="black" icon={Avatar} style={invert ? {filter: "invert(0)"} : {}} className="user-card__icon" />;
}
