import "styles/Ess/EssSpinner.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {spinner} from "util/base64icons";
import esplogo from "resources/My ESP Logo.svg";

export default function EssSpinner(props) {
	const light = props.light;
	const size = props.size ?? "18rem";
	const imgSize = `calc((${size} / 3) * 1.35)`;
	return (
		<div id="EssSpinner" style={props.style}>
			<div className="spinner-container" style={{width: size, height: size}}>
				<img className="spinner-ring" src={spinner} style={{width: size, height: size}}></img>
				{!props.src && !props.icon && <img src={esplogo} style={{width: imgSize, height: imgSize}} className={"spinner-icon " + (light ? "spinner-icon-light" : "spinner-icon-dark")} />}
				{props.src && <img src={props.src} style={{width: imgSize, height: imgSize}} className={"spinner-icon " + (light ? "spinner-icon-light" : "spinner-icon-dark")} />}
				{props.icon && <FontAwesomeIcon style={{width: imgSize, height: imgSize}} icon={props.icon} color={light ? "white" : "black"} className={"spinner-icon"} />}
			</div>
		</div>
	);
}
