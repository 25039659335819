import React from "react";
import {NavLink} from "reactstrap";
import {Link, useLocation} from "react-router-dom";
import "styles/Ess/EssNavLink.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {setSideNavVisibility} from "redux/actions/general-actions";
import {setSearchbarValue} from "redux/actions/searchbar-actions";

export default function EssNavLink(props) {
	const location = useLocation();
	const active = location.pathname.includes(props.to) || props.active;
	const onClick = () => {
		props.collapse();
		props.setSearchbarValue();
	};
	const notification = props.notifications;
	return (
		<div className="ess-nav-link__wrapper" onClick={onClick}>
			<NavLink tag={Link} to={props.to}>
				<div className="ess-nav-link__container">
					<div className="ess-nav-link__icon-container">
						<span>
							{props.src && <img alt="icon" src={props.src} style={props.style} className={active ? "ess-nav-link__icon icon-active" : "ess-nav-link__icon"} />}
							{props.icon && <FontAwesomeIcon color="black" icon={props.icon} className={active ? "ess-nav-link__icon icon-active" : "ess-nav-link__icon"} />}
						</span>
					</div>
					<div className="ess-nav-link__text-container">
						<span className={active ? "ess-nav-link__text text-active" : "ess-nav-link__text"}>{props.children}</span>
						{notification > 0 && (
							<div className="notification">
								<span>{notification}</span>
							</div>
						)}
					</div>
				</div>
			</NavLink>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	collapse: () => {
		dispatch(setSideNavVisibility(true));
	},
	setSearchbarValue: () => {
		dispatch(setSearchbarValue(""));
	},
});

EssNavLink = connect(null, mapDispatchToProps)(EssNavLink);
