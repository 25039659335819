import {combineReducers} from "redux";
import {loginReducer} from "./login-reducer";
import {alertReducer} from "./alert-reducer";
import {serviceRequestsReducer} from "./serviceRequests-reducer";
import {generalReducer} from "./general-reducer";
import {debugReducer} from "./debug-reducer";
import {machinesReducer} from "./machines-reducer";
import {searchbarReducer} from "./searchbar-reducer";
import {customerReducer} from "./customers-reducer";
import {dealersReducer} from "./dealers-reducer";
import {usersReducer} from "./users-reducer";
import {translationReducer} from "./translation-reducer";
import {serviceReportsReducer} from "./serviceReports-reducer";

const rootReducer = combineReducers({
	login: loginReducer,
	alerts: alertReducer,
	serviceRequests: serviceRequestsReducer,
	serviceReports: serviceReportsReducer,
	general: generalReducer,
	debug: debugReducer,
	machines: machinesReducer,
	customers: customerReducer,
	dealers: dealersReducer,
	searchbar: searchbarReducer,
	users: usersReducer,
	translations: translationReducer,
});

export default rootReducer;
