import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setSearchbarValue} from "redux/actions/searchbar-actions";
import {getRecords} from "redux/actions/searchbar-actions";
import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {useNavigate, useLocation} from "react-router";
import {Input, InputGroup} from "reactstrap";
import "styles/General/Searchbar.scss";
import "styles/Ess/EssScrollbar.scss";
import {useTranslation} from "react-i18next";
import {setUrl} from "redux/actions/general-actions";

export default function Searchbar(props) {
	const [records, setRecords] = useState([]);
	const [resultsVisible, setVisible] = useState(false);
	const [value, setValue] = useState("");
	const searchbar = useRef();
	const navigate = useNavigate();
	const location = useLocation();
	const {t} = useTranslation();
	useEffect(() => {
		if (props.searchbarReducer.recordsList.length === 0) props.getRecords();
	}, []);

	const filteredRecords = records.filter((r) => r.name?.toLowerCase().includes(value.trim().length === 0 ? "( ͡° ͜ʖ ͡°)" : value.toLowerCase().trim()));

	useEffect(() => {
		const masterGroup = props.loginReducer.currentUser.masterGroup;
		const canAccessDealers = masterGroup.canAccessDealers && props.loginReducer.currentUser.dealerId === 1;
		const canAccessGroups = masterGroup.canAccessCustomers;
		const canAccessUsers = masterGroup.canAccessUsers;
		const canAccessRequests = masterGroup.canAccessServiceRequests || masterGroup.canBeAssignedToServiceRequest;
		const canAccessCustomers = masterGroup.canAccessCustomers;
		const canManagePortalUsers = masterGroup.canManagePortalUsers;
		let filteredRecords = props.searchbarReducer.recordsList.filter(
			(r) =>
				r.path === "Machines" ||
				(r.path === "Distributors" && canAccessDealers) ||
				(r.path === "Users" && canAccessUsers) ||
				(r.path === "Groups" && canAccessGroups) ||
				(r.path === "Customers" && canAccessCustomers) ||
				(r.path === "Service-Requests" && canAccessRequests) ||
				//t("Portal Users")
				(r.path === "Portal-Users" && canManagePortalUsers)
		);
		setRecords(filteredRecords);
	}, [props.searchbarReducer.recordsList, props.searchbarReducer.listChanged]);

	const showResults = () => {
		setVisible(props.searchbarReducer.searchbarValue.length > 0 && filteredRecords.length > 0);
	};
	const selectedRecordRef = useRef();
	const [hidingAllowed, setHidingAllowed] = useState(true);
	const [selectedRecord, setSelectedRecord] = useState(null);
	useEffect(() => {
		setVisible(props.searchbarReducer.searchbarValue.length > 0 && filteredRecords.length > 0);
		setSelectedRecord(filteredRecords[0]);
		if (props.searchbarReducer.searchbarValue === "") {
			if (hidingAllowed) {
				setVisible(false);
			}
			setValue("");
		}
	}, [props.searchbarReducer.searchbarValue]);
	const hideResults = () => {
		if (hidingAllowed) setVisible(false);
	};

	const enterLocation = (e) => {
		let filteredRecords = records.filter((r) => r.name?.toLowerCase().includes(value));
		if (value.includes("http")) {
			props.setUrl(value);
		} else {
			props.setUrl(null);
		}
		if (filteredRecords.length === 0) return;
		if (e.code === "ArrowDown") {
			e.preventDefault();
			let i = 0;
			if (!selectedRecord) {
				i = filteredRecords.findPropertyIndex("listIndex", filteredRecords[0].listIndex);
			} else {
				i = filteredRecords.findPropertyIndex("listIndex", selectedRecord?.listIndex);
			}
			if (i >= filteredRecords.length) {
				i = filteredRecords.length - 2;
			}
			setSelectedRecord(filteredRecords[i + 1]);
			selectedRecordRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
		}
		if (e.code === "ArrowUp") {
			e.preventDefault();
			let i = 0;
			if (!selectedRecord) {
				i = filteredRecords.findPropertyIndex("listIndex", filteredRecords[0].listIndex);
			} else {
				i = filteredRecords.findPropertyIndex("listIndex", selectedRecord?.listIndex);
			}
			if (i <= 0) {
				i = 1;
			}
			setSelectedRecord(filteredRecords[i - 1]);
			selectedRecordRef?.current?.scrollIntoView({behavior: "smooth", block: "end"});
		}
		if (filteredRecords.length === 1) {
			setSelectedRecord(filteredRecords[0]);
		}
		if (e.code === "Enter") {
			if (value === "") return;
			e.preventDefault();
			clickLocation(selectedRecord);
		}
	};

	const clickLocation = (record) => {
		if (!location.pathname.includes(record?.name ?? "")) navigate("/" + record.path.toLowerCase() + "/" + record?.name + "/" + record.findableId);
		hideResults();
		props.setValue("");
		setValue("");
	};

	const onChange = (e) => {
		setValue(e.target.value);
		props.setValue(e.target.value?.toLowerCase());
	};

	const forbidHiding = () => {
		setHidingAllowed(false);
	};

	const allowHiding = () => {
		setHidingAllowed(true);
	};

	const selectRecord = (record) => {
		setSelectedRecord(record);
	};

	return (
		<div className="searchbar__container" onBlur={hideResults} onFocus={showResults}>
			<InputGroup className="searchbar__input-container">
				<InputGroup addonType="prepend" className="searchbar__icon-container">
					<FontAwesomeIcon icon={faSearch} color="gray" className="searchbar__icon"></FontAwesomeIcon>
				</InputGroup>
				<Input ref={searchbar} placeholder={t("Search")} value={value} onKeyDown={(e) => enterLocation(e)} onChange={onChange} className="searchbar__input" />
			</InputGroup>
			<div id="EssScrollLight" onMouseEnter={forbidHiding} onMouseLeave={allowHiding} className={resultsVisible ? "searchbar__results" : "searchbar__results-hidden"}>
				{filteredRecords.map((r) => {
					return (
						<div
							{...(selectedRecord?.listIndex === r.listIndex ? {ref: selectedRecordRef} : {})}
							onMouseEnter={() => selectRecord(r)}
							key={r.findableId + r.path[0]}
							className={selectedRecord?.listIndex === r.listIndex ? "searchbar__record-active" : "searchbar__record"}
							onClick={() => clickLocation(r)}>
							<span className="searchbar__record-path">{t(r.path.replace("-", " "))}: </span>
							{r.name}
						</div>
					);
				})}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
	serviceReducer: state.serviceRequests,
	searchbarReducer: state.searchbar,
});

const mapDispatchToProps = (dispatch) => ({
	getRecords: () => {
		dispatch(getRecords());
	},
	setValue: (value) => {
		dispatch(setSearchbarValue(value));
	},
	setUrl: (url) => {
		dispatch(setUrl(url));
	},
});

Searchbar = connect(mapStateToProps, mapDispatchToProps)(Searchbar);
