import {SET_NEW_REPORT, SET_REPORT, SET_REPORT_LOADING_STATUS, SET_SEND_REPORT_RESULT} from "redux/actions/serviceReports-actions";

const initialState = {
	newReportData: null,
	fetchedReport: null,
	isLoadingReport: true,
	sendResult: 0,
};

export const serviceReportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_REPORT_LOADING_STATUS: {
			return {...state, isLoadingReport: action.payload.isLoading};
		}
		case SET_REPORT: {
			return {...state, fetchedReport: action.payload.report};
		}
		case SET_NEW_REPORT: {
			return {...state, newReportData: action.payload.report};
		}
		case SET_SEND_REPORT_RESULT: {
			return {...state, sendResult: action.payload.result};
		}
		default:
			return {...state};
	}
};
