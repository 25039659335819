import {SET_CUSTOMER_NOTIFICATIONS, SET_DOCS, SET_URL, UNLOCK_SECRET} from "redux/actions/general-actions";
import {SET_ADDRESSED_OBJECTS} from "redux/actions/general-actions";
import {SET_SERVICE_NOTIFICATIONS} from "redux/actions/general-actions";
import {RAISE_PROPERTY_CHANGED} from "redux/actions/general-actions";
import {SET_MACHINE_NOTIFICATIONS} from "redux/actions/general-actions";
import {SET_CHANGELOG_READED} from "redux/actions/general-actions";
import {SET_CHANGELOG} from "redux/actions/general-actions";
import {SET_APP_SCROLLABILITY} from "redux/actions/general-actions";
import {SET_SIDE_NAV_VISIBILITY} from "redux/actions/general-actions";

const initialState = {
	sideNavCollapsed: true,
	isScrollable: false,
	secret: false,
	featureCollection: null,
	changelog: [],
	changelogFetched: false,
	changelogReaded: false,
	machineNotificationsCount: 0,
	serviceNotificationsCount: 0,
	customerNotificationsCount: 0,
	propertyChanged: false,
	docs: [],
	url: null,
};

export function generalReducer(state = initialState, action) {
	switch (action.type) {
		case SET_URL: {
			return {...state, url: action.payload.url};
		}
		case SET_CUSTOMER_NOTIFICATIONS: {
			return {...state, customerNotificationsCount: action.payload.count};
		}
		case SET_DOCS: {
			return {...state, docs: action.payload.list};
		}
		case RAISE_PROPERTY_CHANGED: {
			return {...state, propertyChanged: !state.propertyChanged};
		}
		case SET_MACHINE_NOTIFICATIONS: {
			return {...state, machineNotificationsCount: action.payload.count};
		}
		case SET_SERVICE_NOTIFICATIONS: {
			return {...state, serviceNotificationsCount: action.payload.count};
		}
		case SET_CHANGELOG_READED: {
			return {...state, changelogReaded: true};
		}
		case SET_CHANGELOG: {
			return {...state, changelog: action.payload.changelog, changelogFetched: true};
		}
		case UNLOCK_SECRET: {
			return {...state, secret: true};
		}
		case SET_ADDRESSED_OBJECTS: {
			return {...state, featureCollection: action.payload.featureCollection};
		}
		case SET_SIDE_NAV_VISIBILITY: {
			return {...state, sideNavCollapsed: action.payload.collapsed};
		}
		case SET_APP_SCROLLABILITY: {
			return {...state, isScrollable: action.payload.isScrollable};
		}
		default:
			return state;
	}
}
