import md5 from "md5";

export function capitalize(s) {
	s = s + "";
	return s.charAt(0).toUpperCase() + s.slice(1);
}
export function decapitalize(s) {
	s = s + "";
	return s.charAt(0).toLowerCase() + s.slice(1);
}

export function shorten(str, maxLen = 55, separator = "") {
	if (str.length <= maxLen) return str;
	return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

export function shortenFileName(str, maxLength = 20) {
	if (str.length <= maxLength + 5) return str;
	let arr = str.split(".");
	let name = arr[0];
	return name.substr(0, Math.round(maxLength / 2)) + "[...]" + name.substr(name.length - 6, Math.round(maxLength / 2)) + "." + arr[arr.length - 1];
}

export function fromUTC(date) {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
}

export function fromEagleEpoch(seconds) {
	let epoch = fromUTC(new Date("2010-01-01T01:00:00"));
	epoch.setSeconds(epoch.getSeconds() + seconds);
	return epoch;
}

export const formatDate = (date, fullDate = true) => {
	let day = date.getDate();
	let month = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	let hour = date.getHours();
	let minute = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
	if (fullDate) {
		return `${day}.${month} ${hour}:${minute}`;
	} else {
		return `${day}.${month}`;
	}
};

Array.prototype.removeWhere = function (callback) {
	let i = this.length;
	while (i--) {
		if (callback(this[i], i)) {
			this.splice(i, 1);
		}
	}
};

/** Find index of an object in an array by its attribute
 * @param {string} attribute - Attribute by which you want to search
 * @param {value} value - Searched value
 * @returns Index of object in an array or undefined if not found.
 */
Array.prototype.findPropertyIndex = function (attribute, value) {
	for (let i = 0; i < this.length; i += 1) {
		if (this[i][attribute] === value) {
			return i;
		}
	}
	return undefined;
};

export function trimDate(str) {
	let date = new Date(Date.parse(str));
	return date.toLocaleDateString();
}

export function trimRoot(path) {
	let arr = path.split("/");
	let rootDir = arr.shift();
	return path.substr(rootDir.length + 1);
}

export function formatSize(size) {
	if (size < 1024) {
		return ` ${size}B`;
	} else if (size < 1048576) {
		return ` ${(size / 1024).toFixed(2)}KB`;
	} else if (size < 1073741824) {
		return ` ${(size / 1024 / 1024).toFixed(2)}MB`;
	} else {
		return ` ${(size / 1024 / 1024 / 1024).toFixed(2)}GB`;
	}
}

export function readFileAsBinary(file) {
	return new Promise(function (resolve, reject) {
		let fr = new FileReader();
		fr.onload = function () {
			let result = md5(new Uint8Array(fr.result));
			resolve({Path: trimRoot(file.webkitRelativePath), Type: file.type, Hash: result});
		};

		fr.onerror = function () {
			reject(fr);
		};

		fr.readAsArrayBuffer(file);
	});
}

export function decodeUrl(hash) {
	let buf = Buffer.from(reverseString(hash), "base64");
	return buf.toString("utf8");
}

export function reverseString(string) {
	return string.split("").reverse().join("");
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
