import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default function YesNoIcon(props) {
	return props.value || props.children ? (
		<FontAwesomeIcon color="green" style={{width: props.width ?? "2rem", height: props.height ?? "2rem"}} icon={faCheckCircle} />
	) : (
		<FontAwesomeIcon color="red" style={{width: props.width ?? "2rem", height: props.height ?? "2rem"}} icon={faTimesCircle} />
	);
}
