export const GET_NEW_REPORT = "REPORTS/GET_NEW_REPORT";
export const SET_NEW_REPORT = "REPORTS/SET_NEW_REPORT";
export const SET_REPORT_LOADING_STATUS = "REPORTS/SET_REPORT_LOADING_STATUS";
export const SEND_REPORT = "REPORTS/SEND_REPORT";
export const UPLOAD_REPORT_FILES = "REPORTS/UPLOAD_FILES";
export const SET_SEND_REPORT_RESULT = "REPORTS/SET_SEND_REPORT_RESULT";
export const GET_REPORT = "REPORTS/GET_REPORT";
export const SET_REPORT = "REPORTS/SET_REPORT";

export const getReport = (reportId) => ({
	type: GET_REPORT,
	payload: {
		reportId,
	},
});

export const setReport = (report) => ({
	type: SET_REPORT,
	payload: {
		report,
	},
});

export const setSendReportResult = (result) => ({
	type: SET_SEND_REPORT_RESULT,
	payload: {
		result,
	},
});

export const uploadReportFiles = (token, meta, files) => ({
	type: UPLOAD_REPORT_FILES,
	payload: {
		token,
		meta,
		files,
	},
});

export const sendReport = (report) => ({
	type: SEND_REPORT,
	payload: {
		report,
	},
});

export const setReportLoadingStatus = (isLoading) => ({
	type: SET_REPORT_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getNewReport = (requestId) => ({
	type: GET_NEW_REPORT,
	payload: {
		requestId,
	},
});

export const setNewReport = (report) => ({
	type: SET_NEW_REPORT,
	payload: {
		report,
	},
});
