export const GET_SERVICE_REQUEST = "SERVICE_REQUESTS/GET";
export const SET_SERVICE_REQUEST = "SERVICE_REQUESTS/SET";
export const SET_SERVICE_REQUEST_LOADING_STATUS = "SERVICE_REQUESTS/SET_LOADING_STATUS";
export const UPDATE_FAULT_STATUS = "SERVICE_REQUESTS/UPDATE_FAULT_STATUS";
export const NOTIFY_FAULT_STATUS = "SERVICE_REQUESTS/NOTIFY_FAULT_STATUS";
export const SEND_FAULT_STATUS = "SERVICE_REQUESTS/SEND_FAULT_STATUS";

export const SET_SERVICE_LOGS = "SERVICE_REQUEST/SET_SERVICE_LOGS";
export const SET_SERVICE_LOGS_LOADING_STATUS = "SERVICE_REQUESTS/SET_LOGS_LOADING_STATUS";

export const SET_TOKEN_LOADING_STATUS = "SERVICE_REQUESTS/SET_TOKEN_LOADING_STATUS";
export const SET_ATTACHMENT_UPLOAD_STATUS = "SERVICE_REQUESTS/SET_ATTACHMENT_UPLOAD_STATUS";
export const CHECK_UPLOAD_TOKEN = "SERVICE_REQUESTS/CHECK_UPLOAD_TOKEN";
export const SET_CHECK_TOKEN_RESULT = "SERVICE_REQUESTS/SET_CHECK_TOKEN_RESULT";
export const UPLOAD_FILES = "SERVICE_REQUESTS/UPLOAD_FILES";

export const GET_ISSUE_TIMELINE = "SERVICE_REQUESTS/GET_ISSUE_TIMELINE";
export const SET_ISSUE_TIMELINE = "SERVICE_REQUESTS/SET_ISSUE_TIMELINE";
export const SET_ISSUE_TIMELINE_LOADING_STATUS = "SERVICE_REQUESTS/SET_ISSUE_TIMELINE_LOADING_STATUS";

export const BAN_REQUEST = "SERVICE_REQUESTS/BAN_REQUEST";
export const RESET_COUNTERS = "SERVICE_REQUESTS/RESET_COUNTERS";

export const GET_SERVICE_NOTES = "SERVICE_REQUESTS/GET_SERVICE_NOTES";
export const SET_SERVICE_NOTES = "SERVICE_REQUESTS/SET_NOTES";
export const ADD_SERVICE_NOTE = "SERVICE_REQUESTS/ADD_NOTE";
export const EDIT_SERVICE_NOTE = "SERVICE_REQUESTS/EDIT_SERVICE_NOTE";
export const DELETE_SERVICE_NOTE = "SERVICE_REQUESTS/DELETE_SERVICE_NOTE";
export const ASSIGN_USERS = "SERVICE_REQUESTS/ASSIGN_USERS";
export const SET_USERS = "SERVICE_REQUESTS/SET_USERS";
export const CHANGE_CATEGORY = "SERVICE_REQUESTS/CHANGE_CATEGORY";
export const SET_NEW_CATEGORY = "SERVICE_REQUESTS/SET_NEW_CATEGORY";
export const SET_SHARED = "SET_SHARED";
export const FORWARD_SERVICE_REQUEST = "SERVICE_REQUEST/FORWARD_REQUEST";
export const SET_NOTES_LOADING = "SERVICE_REQUEST/SET_NOTES_LOADING";
export const CHANGE_SUB_CATEGORY = "SERVICE_REQUESTS/CHANGE_SUB_CATEGORY";
export const SET_NEW_SUB_CATEGORY = "SERVICE_REQUESTS/SET_NEW_SUB_CATEGORY";

export const GET_USER_TIME = "SERVICE_REQUESTS/GET_USER_TIME";
export const IS_LOADING_USER_TIME = "SERVICE_REQUESTS/IS_LOADING_USER_TIME";
export const SET_USER_TIME = "SERVICE_REQUESTS/SET_USER_TIME";
export const INSERT_TIME_CLOCK = "SERVICE_REQUESTS/INSERT_TIME_CLOCK";

export const getUserTime = (srId) => ({
	type: GET_USER_TIME,
	payload: {
		srId,
	},
});

export const setIsLoadingUserTime = (isLoading) => ({
	type: IS_LOADING_USER_TIME,
	payload: {
		isLoading,
	},
});

export const setUserTime = (isStopped, totalSeconds) => ({
	type: SET_USER_TIME,
	payload: {
		isStopped,
		totalSeconds,
	},
});

export const insertTimeClock = (srId) => ({
	type: INSERT_TIME_CLOCK,
	payload: {
		srId,
	},
});

export const setNotesLoading = (isLoading) => ({
	type: SET_NOTES_LOADING,
	payload: {
		isLoading,
	},
});

export const setIssueTimelineLoadingStatus = (isLoading, faultId) => ({
	type: SET_ISSUE_TIMELINE_LOADING_STATUS,
	payload: {
		isLoading,
		faultId,
	},
});

export const getIssueTimeline = (faultId) => ({
	type: GET_ISSUE_TIMELINE,
	payload: {
		faultId,
	},
});

export const setIssueTimeline = (timeline, faultId) => ({
	type: SET_ISSUE_TIMELINE,
	payload: {
		timeline,
		faultId,
	},
});

export const setShared = () => ({
	type: SET_SHARED,
});

export const setNewSubCategory = (faultId, subCategory) => ({
	type: SET_NEW_SUB_CATEGORY,
	payload: {
		faultId,
		subCategory,
	},
});

export const changeSubCategory = (faultId, subCategory) => ({
	type: CHANGE_SUB_CATEGORY,
	payload: {
		faultId,
		subCategory,
	},
});

export const setNewCategory = (faultId, category) => ({
	type: SET_NEW_CATEGORY,
	payload: {
		faultId,
		category,
	},
});

export const changeCategory = (faultId, category) => ({
	type: CHANGE_CATEGORY,
	payload: {
		faultId,
		category,
	},
});

export const setUsers = (userList) => ({
	type: SET_USERS,
	payload: {
		userList,
	},
});

export const assignUsers = (ticketCode, userList) => ({
	type: ASSIGN_USERS,
	payload: {
		ticketCode,
		userList,
	},
});

export const banRequest = (ticketCode, revert) => ({
	type: BAN_REQUEST,
	payload: {
		ticketCode,
		revert,
	},
});

export const resetCounters = (machineId) => ({
	type: RESET_COUNTERS,
	payload: {
		machineId,
	},
});

export const notifyFaultStatus = (fault) => ({
	type: NOTIFY_FAULT_STATUS,
	payload: {
		fault,
	},
});

export const getServiceNotes = (requestId) => ({
	type: GET_SERVICE_NOTES,
	payload: {
		requestId,
	},
});
export const addServiceNote = (note, file, requestId) => ({
	type: ADD_SERVICE_NOTE,
	payload: {
		note,
		file,
		requestId,
	},
});

export const editServiceNote = (note) => ({
	type: EDIT_SERVICE_NOTE,
	payload: {
		note,
	},
});

export const deleteServiceNote = (note) => ({
	type: DELETE_SERVICE_NOTE,
	payload: {
		note,
	},
});

export const setServiceNotes = (notes) => ({
	type: SET_SERVICE_NOTES,
	payload: {
		notes,
	},
});

export const setServiceLogsLoadingStatus = (isLoading) => ({
	type: SET_SERVICE_LOGS_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const setServiceLogs = (logs) => ({
	type: SET_SERVICE_LOGS,
	payload: {
		logs,
	},
});

export const sendFaultStatus = (ticketId, faultIndex, faultArgs) => ({
	type: SEND_FAULT_STATUS,
	payload: {
		ticketId,
		faultIndex,
		faultArgs,
	},
});

export const updateFaultStatus = (ticketId, faultIndex, faultArgs) => ({
	type: UPDATE_FAULT_STATUS,
	payload: {
		ticketId,
		faultIndex,
		faultArgs,
	},
});

export const getServiceRequestById = (serviceRequestId) => ({
	type: GET_SERVICE_REQUEST,
	payload: {
		serviceRequestId,
	},
});

export const setServiceRequest = (request) => ({
	type: SET_SERVICE_REQUEST,
	payload: {
		request,
	},
});

export const setServiceRequestLoadingStatus = (isLoading) => ({
	type: SET_SERVICE_REQUEST_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const setCheckTokenResult = (result, errorMessage = "") => ({
	type: SET_CHECK_TOKEN_RESULT,
	payload: {
		result,
		errorMessage,
	},
});

export const setTokenLoadingStatus = (isLoading) => ({
	type: SET_TOKEN_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const setAttachmentUploadStatus = (status) => ({
	type: SET_ATTACHMENT_UPLOAD_STATUS,
	payload: {
		status,
	},
});

export const uploadFiles = (token, meta, files) => ({
	type: UPLOAD_FILES,
	payload: {
		token,
		meta,
		files,
	},
});

export const checkUploadToken = (token) => ({
	type: CHECK_UPLOAD_TOKEN,
	payload: {
		token,
	},
});

export const forwardServiceRequest = (serviceRequestId, forwardReason) => ({
	type: FORWARD_SERVICE_REQUEST,
	payload: {
		serviceRequestId,
		forwardReason,
	},
});
