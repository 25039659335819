import axios from "axios";
import {HashFromShort, HashShortPassword} from "./PasswordHasher";

export const HOST_IP = window.location.hostname;

export const API_URL = process.env.NODE_ENV === "development" ? `http://${HOST_IP}:8000/api` : "https://myesp.eagle-group.eu/api";

export const SIGNALR_URL = process.env.NODE_ENV === "development" ? `http://${HOST_IP}:8000/signalr/machines` : "https://myesp.eagle-group.eu/signalr/machines";

export const BROWSER_SIGNALR_URL = process.env.NODE_ENV === "development" ? `http://${HOST_IP}:8000/signalr/browsers` : "https://myesp.eagle-group.eu/signalr/browsers";

const instance = axios.create({
	withCredentials: true,
	baseURL: API_URL,
	validateStatus: function (status) {
		return (status >= 200 && status < 400) || status === 401;
	},
});

export default class RestClient {
	//#region Main
	static async getRecordsList() {
		return await instance.post("/main/findables");
	}
	static async getAddressedObjects() {
		return await instance.post("/main/addresses");
	}
	static async logError(error) {
		let json = JSON.stringify(error);
		return await instance.post("/main/bugreport", json, {headers: {"Content-Type": "application/json"}});
	}
	static async getChangelog() {
		return await instance.post("/main/changelog");
	}
	static async getServiceNotifications() {
		return await instance.post("/main/requestnotification");
	}
	static async getMachineNotifications() {
		return await instance.post("/main/machinenotification");
	}
	static async getCustomerNotifications() {
		return await instance.post("/main/customernotification");
	}
	static async bugReport(error) {
		let json = JSON.stringify(error);
		let res = await instance.post("/main/userbugreport", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/main/userbugreport", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async toggleMailSubscription() {
		let res = await instance.post("/users/subscription-sr");
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/users/subscription-sr");
		}
		return res;
	}

	static async fetchDocs() {
		let res = await instance.post("/main/docs");
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/main/docs");
		}
		return res;
	}
	//#endregion
	//#region Translation

	static async addLanguage(code) {
		let json = JSON.stringify({language: code});
		let res = await instance.post("/translation/addLanguage/", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/translation/addLanguage/", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async updateAllLanguages() {
		let res = await instance.post("/translation/updateAllTranslations/");
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/translation/updateAllTranslations/");
		}
		return res;
	}

	static async addMissingTag(tag) {
		let json = JSON.stringify({projectId: 1, msgId: tag});
		let res = await instance.post("/translation/addTag/", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/translation/addTag/", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async fetchTranslation(code) {
		let json = JSON.stringify({projectId: 1, language: code});
		let res = await instance.post("/translation/getTranslation/", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/translation/getTranslation/", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async getLanguages() {
		return await instance.post("/translation/getLanguages");
	}
	static async saveUserLanguage(code) {
		let res = await instance.post("/translation/setLanguage/" + code);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/translation/setLanguage/" + code);
		}
		return res;
	}
	//#endregion
	//#region Authorization
	static async loginUser(login, password) {
		let json = JSON.stringify({Login: login, Password: ""});
		const salt_response = await instance.post("/authorize/get-login", json, {headers: {"Content-Type": "application/json"}});
		if (salt_response.status === 404) {
			let response = {data: salt_response.data, status: salt_response.status, message: salt_response.statusText};
			return response;
		}
		let ss_pass = HashFromShort(salt_response.data, password);
		json = JSON.stringify({Login: login, Password: ss_pass});
		return await instance.post("/authorize/get-token", json, {headers: {"Content-Type": "application/json"}});
	}

	static async confirmBrowser(token) {
		let json = JSON.stringify(token);
		return await instance.post("/authorize/verify-browser", json, {headers: {"Content-Type": "application/json"}});
	}

	static async resendConfirmEmail() {
		return await instance.post("/authorize/resend-browser");
	}

	static async getUser() {
		return await instance.post("/authorize/get-user");
	}

	static async logoutUser() {
		return await instance.post("/authorize/revoke-token");
	}

	static async refreshToken() {
		try {
			const res = await instance.post("/authorize/refresh-token");
			if (res.status === 200) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	static async forgotPassword(email) {
		let json = JSON.stringify(email);
		return await instance.post("/users/resetmail", json, {headers: {"Content-Type": "application/json"}});
	}

	static async checkResetToken(token) {
		return await instance.post("/users/resetCheck/" + token);
	}

	static async revokeToken() {
		await instance.post("/authorize/revoke-token");
	}

	static async changeAvatar(user) {
		let json = JSON.stringify(user);
		const res = await instance.put("/users/changeAvatar", json, {headers: {"Content-Type": "application/json"}});
		if (res.status === 204) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.put("/users/changeAvatar", json, {headers: {"Content-Type": "application/json"}});
		}
	}

	static async resetPassword(userId) {
		const res = await instance.post("/users/reset/" + userId);
		if (res.status === 204) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.post("/users/reset/" + userId);
		}
	}

	static async setNewPassword(newPassword) {
		let hashedPassword = {userId: newPassword.userId, shortHash: HashShortPassword(newPassword.shortHash)};
		let json = JSON.stringify(hashedPassword);
		return await instance.put("/users/setNewPassword", json, {headers: {"Content-Type": "application/json"}});
	}

	static async loginChangePassword(passwords) {
		let hashedPasswords = {...passwords, ShortOld: HashShortPassword(passwords.ShortOld), ShortNew: HashShortPassword(passwords.ShortNew)};
		let json = JSON.stringify(hashedPasswords);
		const res = await instance.put("/users/changePassword", json, {headers: {"Content-Type": "application/json"}});
		if (res.status === 204) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.put("/users/changePassword", json, {headers: {"Content-Type": "application/json"}});
		}
	}
	//#endregion
	//#region Customers
	static async getCustomerById(customerId) {
		let res = await instance.post("/customers/" + customerId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/customers/" + customerId);
		}
		return res;
	}
	static async getPortalUserById(portalUserId) {
		let res = await instance.post("/customers/portalUser/" + portalUserId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/customers/portalUser/" + portalUserId);
		}
		return res;
	}
	static async updateCustomer(form) {
		let res = await instance.put("/customers/", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/customers/", form);
		}
		return res;
	}
	static async updatePortalUser(form) {
		let res = await instance.put("/customers/portaluser", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/customers/portaluser", form);
		}
		return res;
	}
	static async activatePortalUser(portalUserId) {
		let res = await instance.post("/customers/activate/" + portalUserId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/customers/activate/" + portalUserId);
		}
		return res;
	}
	static async getCustomerRatings(customerId) {
		let res = await instance.post("/customers/ratings/" + customerId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/customers/ratings/" + customerId);
		}
		return res;
	}
	//#endregion
	//#region Machines
	static async getHolidays(year, country = "PL") {
		let res = await axios.get(`https://date.nager.at/api/v3/publicholidays/${year}/${country}`);
		if (res.status === 200) {
			return res.data;
		} else {
			return [];
		}
	}
	static async getConnectionLogs(machineId, month, year) {
		let res = await instance.post(`/machines/logs/connection/${machineId}/${year}/${month}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/logs/connection/${machineId}/${year}/${month}`);
		}
		return res;
	}

	static async getActivitiesLogs(machineId) {
		let res = await instance.post("/machines/logs/activity/" + machineId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/machines/logs/activity/" + machineId);
		}
		return res;
	}

	static async getLeakTests(machineId, month, year) {
		let res = await instance.post(`/machines/logs/leaktest/${machineId}/${year}/${month}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/logs/leaktest/${machineId}/${year}/${month}`);
		}
		return res;
	}
	static async getMaintenances(machineId, month, year) {
		let res = await instance.post(`/machines/logs/maintenance/${machineId}/${year}/${month}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/logs/maintenance/${machineId}/${year}/${month}`);
		}
		return res;
	}
	static async getMachineCharts(machineId, monthsBack) {
		let res = await instance.post(`/machines/logs/chart/${machineId}/${monthsBack}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/logs/chart/${machineId}/${monthsBack}`);
		}
		return res;
	}
	static async addMachine(machine) {
		const json = JSON.stringify(machine);
		console.log(json);
		let res = await instance.post(`/machines/register-manual`, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/register-manual`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async getCollisions(machineId, month, year) {
		let res = await instance.post(`/machines/logs/collision/${machineId}/${year}/${month}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/machines/logs/collision/${machineId}/${year}/${month}`);
		}
		return res;
	}

	static async getEula(machineId) {
		let res = await instance.post("/machines/eula/" + machineId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/machines/eula/" + machineId);
		}
		return res;
	}

	static async getMachineById(machineId) {
		let res = await instance.post("/machines/" + machineId);
		if (res.status !== 200) {
			await this.refreshToken();
			return await instance.post("/machines/" + machineId);
		}
		return res;
	}

	static async sendMergedData(form) {
		let res = await instance.put("/machines/conflicts", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/machines/conflicts", form);
		}
		return res;
	}

	static async updateMachine(form) {
		let res = await instance.put("/machines/", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/machines/", form);
		}
		return res;
	}
	static async removeIpc(machineId) {
		let res = await instance.put("/machines/removeipc/" + machineId);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.put("/machines/removeipc/" + machineId);
		}
		return res;
	}
	static async machineSendNote(note, machineId) {
		let json = JSON.stringify(note);
		let res = await instance.post("/machines/notes/add/" + machineId, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/machines/notes/add/" + machineId, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async getMachineNotes(machineId) {
		let res = await instance.post("/machines/notes/get/" + machineId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/machines/notes/get/" + machineId);
		}
		return res;
	}
	static async putMachineNote(note, machineId) {
		let json = JSON.stringify(note);
		let res = await instance.put("/machines/notes/edit/" + machineId, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/machines/notes/edit/" + machineId, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async deleteMachineNote(noteId, machineId) {
		let res = await instance.delete("/machines/notes/delete/" + machineId + "/" + noteId);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.delete("/machines/notes/delete/" + machineId + "/" + noteId);
		}
		return res;
	}
	//#endregion
	//#region Users
	static async getUserById(userId) {
		let res = await instance.post("/users/" + userId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/users/" + userId);
		}
		return res;
	}
	static async updateUser(form) {
		let res = await instance.put("/users/", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/users/", form);
		}
		return res;
	}
	static async addUser(form) {
		let res = await instance.post("/users/", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/users/", form);
		}
		return res;
	}
	//#endregion
	//#region Dealers
	static async getDealerById(dealerId) {
		let res = await instance.post("/dealers/" + dealerId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/dealers/" + dealerId);
		}
		return res;
	}
	static async updateDealer(form) {
		let res = await instance.put("/dealers/", form);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("/dealers/", form);
		}
		return res;
	}
	//#endregion
	//#region Packages
	static async getPackageUrls(uploadInfo) {
		let json = JSON.stringify(uploadInfo);
		let res = await instance.post("/packages/get-upload", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/packages/get-upload", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async uploadPackage(url, file) {
		try {
			await axios.put(url, file, {headers: {"x-ms-blob-type": "BlockBlob"}});
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	}
	static async addPackage(ess_package) {
		let json = JSON.stringify(ess_package);
		let res = await instance.post("/packages/", json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("/packages/", json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	//#endregion
	//#region Portal
	static async openPortalRequest() {
		return await instance.post("portal/open");
	}
	static async portalLogin(hwId, serialNumber) {
		let json = JSON.stringify({hwId: hwId, serial: serialNumber});
		return await instance.post("authorize/portal-login", json, {headers: {"Content-Type": "application/json"}});
	}
	static async submitRequest(request) {
		let json = JSON.stringify(request);
		return await instance.post("portal/submit", json, {headers: {"Content-Type": "application/json"}});
	}
	//#endregion
	//#region ServiceRequests
	static async getServiceRequestTranslation(serviceRequestId) {
		const res = await instance.post(`/requests/getTranslation/${serviceRequestId}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/requests/getTranslation/${serviceRequestId}`);
		}
		return res;
	}

	static async changeSubCategory(faultId, subCategory) {
		var json = JSON.stringify(subCategory);
		let res = await instance.post(`/requests/changeSubCategory/${faultId}`, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/requests/changeSubCategory/${faultId}`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async changeCategory(faultId, category) {
		const json = JSON.stringify(category);
		let res = await instance.post(`/requests/changeCategory/${faultId}`, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/requests/changeCategory/${faultId}`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	static async assignUsers(ticketCode, userList) {
		let json = JSON.stringify(userList);
		let res = await instance.post("/requests/assign/" + ticketCode, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/requests/assign/" + ticketCode, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async getServiceRequest(serviceRequestId) {
		const res = await instance.post(`/requests/get/` + serviceRequestId);
		if (res.status === 200) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.post(`/requests/get/` + serviceRequestId);
		}
	}

	static async getServiceNotes(serviceRequestId) {
		let res = await instance.post(`/requests/notes/get/` + serviceRequestId);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/requests/notes/get/` + serviceRequestId);
		}
		return res;
	}

	static async addServiceNote(noteArgs, requestId) {
		let json = JSON.stringify(noteArgs);
		let res = await instance.post("requests/notes/add/" + requestId, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post("requests/notes/add/" + requestId, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async editServiceNote(note) {
		let json = JSON.stringify(note);
		let res = await instance.put("requests/notes/edit/" + note.requestId, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.put("requests/notes/edit/" + note.requestId, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async deleteServiceNote(note) {
		let res = await instance.delete("requests/notes/delete/" + note.requestId + "/" + note.noteId);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.delete("requests/notes/delete/" + note.requestId + "/" + note.noteId);
		}
		return res;
	}

	static async checkUploadToken(token) {
		return await instance.post(`/requests/checktoken/${token}`);
	}

	static async getUploadUrls(token, files) {
		let json = JSON.stringify(files);
		return await instance.post(`/requests/getupload/${token}`, json, {headers: {"Content-Type": "application/json"}});
	}
	static async uploadAttachment(url, type, file) {
		try {
			await axios.put(url, file, {headers: {"x-ms-blob-type": "BlockBlob", "Content-Type": type}});
			return true;
		} catch (e) {
			return false;
		}
	}
	static async sendFaultStatus(faultChangeArgs) {
		let faultArgs = {
			faultId: faultChangeArgs.faultId,
			faultStatus: faultChangeArgs.faultStatus,
			plannedDateTime: faultChangeArgs.plannedDateTime,
			comment: faultChangeArgs.comment,
			reason: faultChangeArgs.reason,
		};
		let json = JSON.stringify(faultArgs);
		const res = await instance.put("/requests/updatestatus", json, {headers: {"Content-Type": "application/json"}});
		if (res.status === 204) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.put("/requests/updatestatus", json, {headers: {"Content-Type": "application/json"}});
		}
	}

	static async banRequest(ticketCode, revert) {
		let res = await instance.post(`/requests/incrementUrgent/${ticketCode}/${revert}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/requests/incrementUrgent/${ticketCode}/${revert}`);
		}
		return res;
	}

	static async resetCounters(machineId) {
		let res = await instance.post("/requests/resetCounters/" + machineId);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post("/requests/resetCounters/" + machineId);
		}
		return res;
	}

	static async getServiceLogs(id) {
		const res = await instance.post(`/requests/getlogs/${id}`);
		if (res.status === 200) {
			return res;
		} else {
			await this.refreshToken();
			return await instance.post(`/requests/getlogs/${id}`);
		}
	}

	static async forwardServiceRequest(serviceRequestId, forwardReason) {
		let reason = JSON.stringify(forwardReason);
		let requestUri = `/requests/shareRequest/${serviceRequestId}`;
		let res = await instance.post(requestUri, reason, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(requestUri, reason, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async getIssueTimeline(faultId) {
		let res = await instance.post(`/requests/timeline/${faultId}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/requests/timeline/${faultId}`);
		}
		return res;
	}

	static async getUserTime(srId) {
		let res = await instance.post(`/requests/get-user-time/${srId}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/requests/get-user-time/${srId}`);
		}
		return res;
	}

	static async insertTimeClock(srId) {
		let res = await instance.post(`/requests/insert-timeclock/${srId}`);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/requests/insert-timeclock/${srId}`);
		}
		return res;
	}
	//#endregion
	//#region REPORTS
	static async getNewReport(requestId) {
		let res = await instance.post(`/reports/open/${requestId}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/reports/open/${requestId}`);
		}
		return res;
	}

	static async getReport(reportId) {
		let res = await instance.post(`/reports/${reportId}`);
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/reports/${reportId}`);
		}
		return res;
	}

	static async getUploadUrlReport(token, files) {
		let json = JSON.stringify(files);
		let res = await instance.post(`/reports/getUpload/${token}`, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 200) {
			await this.refreshToken();
			res = await instance.post(`/reports/getUpload/${token}`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}

	static async sendReport(report) {
		let res = await instance.post(`/reports`, json, {headers: {"Content-Type": "application/json"}});
		let json = JSON.stringify(report);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/reports`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	//#endregion
	//#region DEBUG
	static async debug_createMissingEssMachines() {
		await this.refreshToken();
		return await instance.post("/debug/machinedata/create", null, {timeout: 600000});
	}

	static async debug_updateCoords() {
		await this.refreshToken();
		return await instance.post("/debug/refreshcoords", null, {timeout: 600000});
	}
	static async debug_sendEmail(type, address) {
		let res = await instance.post(`/debug/mail/${type}/${address}`);
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/debug/mail/${type}/${address}`);
		}
		return res;
	}
	static async debug_sendTestLog(message) {
		let json = JSON.stringify(message);
		let res = await instance.post(`/debug/testlog`, json, {headers: {"Content-Type": "application/json"}});
		if (res.status !== 204) {
			await this.refreshToken();
			res = await instance.post(`/debug/testlog`, json, {headers: {"Content-Type": "application/json"}});
		}
		return res;
	}
	//#endregion
}
