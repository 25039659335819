import {SET_USER_LOADING_STATUS} from "redux/actions/users-actions";
import {SET_USER} from "redux/actions/users-actions";
import {fromUTC} from "util/Utilities";

const initialState = {
	fetchedUser: null,
	isLoading: true,
};

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER: {
			let user = action.payload.user;
			user.lastLogin = fromUTC(new Date(user?.lastLogin)).toJSON();
			user.modified = fromUTC(new Date(user?.modified)).toJSON();

			return {...state, fetchedUser: user};
		}
		case SET_USER_LOADING_STATUS: {
			return {...state, isLoading: action.payload.isLoading};
		}
		default:
			return state;
	}
};
