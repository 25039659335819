import {showAlert} from "redux/actions/Alert-actions";
import {call, put} from "redux-saga/effects";
import {packagesStore} from "util/DevExtremeStores";
import RestClient from "util/RestClient";
import {decodeUrl} from "util/Utilities";
import i18n from "../../i18n";
import {raisePropertyChanged} from "redux/actions/general-actions";
export function* uploadPackage(action) {
	try {
		yield put(showAlert("success", i18n.t("Upload started"), false));
		//Fetch upload urls from API
		const RES_URLS = yield call(async (payload) => {
			return await RestClient.getPackageUrls({
				packageType: payload.uploadData.type,
				version: payload.uploadData.version,
				metadata: payload.uploadData.metadata,
			});
		}, action.payload);
		if (RES_URLS.status !== 200) throw "Can't get upload urls";
		let arr = [];
		if (RES_URLS.data.length === 0) throw "No urls were returned";
		for (let i = 0; i < RES_URLS.data.length; i++) {
			//Decode urls
			let decoded_url = decodeUrl(RES_URLS.data[i].hash);
			arr.push({...action.payload.uploadData.metadata[i], url: decoded_url});
		}
		action.payload.uploadData.metadata = arr;
		let failCount = 0;
		yield call(async () => {
			await RestClient.refreshToken();
		});
		//Upload each file
		if (action.payload.uploadData.files.length === 0) throw "There are no files to upload.";
		for (let i = 0; i < action.payload.uploadData.files.length; i++) {
			yield put(showAlert("success", `${i18n.t("Uploading")}: ${Math.round((i / action.payload.uploadData.files.length) * 100)}%`, false));
			let result = yield call(async (payload) => {
				return await RestClient.uploadPackage(payload.uploadData.metadata[i].url, payload.uploadData.files[i]);
			}, action.payload);
			if (result) {
				failCount = 0;
			} else {
				failCount++;
				i--;
				if (failCount > 3) throw "Error occured while uploading";
			}
		}
		//Send update request to database
		const res = yield call(async (payload) => {
			let ess_package = {
				version: payload.uploadData.version,
				type: payload.uploadData.type,
				totalFiles: payload.uploadData.files.length,
			};
			return await RestClient.addPackage(ess_package);
		}, action.payload);
		if (res.status !== 200) throw "Can't save package to database.";
		yield put(raisePropertyChanged());
		yield put(showAlert("success", i18n.t("Package uploaded successfully")));
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", e));
	}
}
