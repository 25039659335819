import i18n from "../../i18n";
import {call, put} from "redux-saga/effects";
import {showAlert} from "redux/actions/Alert-actions";
import {setNewReport, setReport, setReportLoadingStatus, setSendReportResult} from "redux/actions/serviceReports-actions";
import RestClient from "util/RestClient";

export function* uploadReportFiles(action) {
	try {
		const res = yield call(async (payload) => {
			let fileList = payload.meta.map((m) => m.fileName);
			return await RestClient.getUploadUrlReport(payload.token, fileList);
		}, action.payload);

		if (res.status === 200) {
			yield call(async (payload) => {
				for (let i = 0; i < res.data.length; i++) {
					let url = decodeUrl(res.data[i]);
					let response = await RestClient.uploadAttachment(url, payload.meta[i].type, payload.files[i]);
					if (!response) throw "An error occured during upload.";
				}
			}, action.payload);
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("An error occured during upload")));
	}
}

export function* getReport(action) {
	yield put(setReportLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getReport(payload.reportId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setReport(res.data));
		} else {
			throw "Couldn't fetch report";
		}
	} catch (e) {
		console.error(e);
	}
	yield put(setReportLoadingStatus(false));
}

export function* getNewReport(action) {
	yield put(setReportLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getNewReport(payload.requestId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setNewReport(res.data));
		} else {
			throw "Couldn't fetch report";
		}
	} catch (e) {
		console.error(e);
	}
	yield put(setSendReportResult(0));
	yield put(setReportLoadingStatus(false));
}

export function* sendReport(action) {
	yield put(setReportLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.sendReport(payload.report);
		}, action.payload);
		if (res.status === 204) {
			yield put(setSendReportResult(1));
		} else {
			throw "Couldn't send report";
		}
	} catch (e) {
		console.error(e);
		yield put(setSendReportResult(2));
	}
	yield put(setReportLoadingStatus(false));
}
