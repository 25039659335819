import crypto from "crypto";

export function HashPassword(salt, password) {
	let hashA = crypto.createHash("sha512").update(password).setEncoding("utf-8").digest("hex").toUpperCase();
	let hashB = crypto.createHash("sha256").update(salt).setEncoding("utf-8").digest("hex");
	let auth = hashA + salt + hashB;
	let secureAuth = crypto.createHash("sha512").update(auth).setEncoding("utf-8").digest("hex");
	return secureAuth;
}

export function HashShortPassword(password) {
	return crypto.createHash("sha512").update(password).setEncoding("utf-8").digest("hex").toUpperCase();
}

export function HashFromShort(salt, hashedPassword) {
	let hashB = crypto.createHash("sha256").update(salt).setEncoding("utf-8").digest("hex");
	let auth = hashedPassword + salt + hashB;
	let secureAuth = crypto.createHash("sha512").update(auth).setEncoding("utf-8").digest("hex");
	return secureAuth;
}

export function GeneratePassword() {
	let password = randomString(12);
	return HashShortPassword(password);
}

function randomString(length) {
	let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
	let result = "";
	for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
	return result;
}
