import {confirmToggleSubscription, setAddressedObjects, setCustomerNotifications, setDocs, setLanguages} from "redux/actions/general-actions";
import {call, put} from "redux-saga/effects";
import RestClient from "util/RestClient";
import {logError as logErrorAction} from "redux/actions/general-actions";
import {setChangelog} from "redux/actions/general-actions";
import {setServiceNotifications} from "redux/actions/general-actions";
import {setMachineNotifications} from "redux/actions/general-actions";
import {showAlert} from "redux/actions/Alert-actions";
import i18n from "../../i18n";

export function* fetchDocs() {
	try {
		const res = yield call(async () => {
			return await RestClient.fetchDocs();
		});
		if (res.status === 200) {
			yield put(setDocs(res.data));
		} else {
			throw "Couldn't fetch docs";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't fetch docs")));
	}
}

export function* toggleMailSubscription() {
	try {
		const res = yield call(async () => {
			return await RestClient.toggleMailSubscription();
		});
		if (res.status === 204) {
			yield put(confirmToggleSubscription());
			yield put(showAlert("success", i18n.t("Subscription changed")));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't change subscription")));
	}
}

export function* bugReport(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.bugReport(payload.error);
		}, action.payload);
		if (res.status !== 204) {
			throw "Error occurred while submitting bug report.";
		}
		yield put(showAlert("success", i18n.t("Bug report submitted")));
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Can't submit bug report")));
	}
}

export function* getServiceNotifications() {
	try {
		const res = yield call(async () => {
			return await RestClient.getServiceNotifications();
		});
		if (res.status === 200) {
			yield put(setServiceNotifications(res.data));
		}
	} catch (e) {
		console.error(e);
	}
}

export function* getCustomerNotifications() {
	try {
		const res = yield call(async () => {
			return await RestClient.getCustomerNotifications();
		});
		if (res.status === 200) {
			yield put(setCustomerNotifications(res.data));
		}
	} catch (e) {
		console.error(e);
	}
}

export function* getMachineNotifications() {
	try {
		const res = yield call(async () => {
			return await RestClient.getMachineNotifications();
		});

		if (res.status === 200) {
			yield put(setMachineNotifications(res.data));
		}
	} catch (e) {
		console.error(e);
	}
}

export function* getChangelog() {
	try {
		const res = yield call(async () => {
			return await RestClient.getChangelog();
		});
		if (res.status === 200 || res.status === 204) {
			yield put(setChangelog(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("general-saga/getChangelog: " + e.message))
	}
}

export function* getAddressedObjects() {
	try {
		const res = yield call(async () => {
			return await RestClient.getAddressedObjects();
		});
		if (res.status === 200) {
			yield put(setAddressedObjects(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("general-saga/getAddressedObject: " + e.message))
	}
}

export function* logError(action) {
	try {
		yield call(async (payload) => {
			return await RestClient.logError(payload.error);
		}, action.payload);
	} catch (e) {
		console.error(e);
	}
}
