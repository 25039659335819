export const SHOW_ALERT = "SHOW_ALERT";
export const RESET_ALERT = "RESET_ALERT";

/**
 * Shows alert
 * @param {string} color - bootstrap color of alert ("Danger"/"Success")
 * @param {string} message - alert message.
 * @param {boolean} timeOut - should vanish after 3 seconds, default true
 * @returns
 */
export const showAlert = (color, message, timeOut = true) => ({
	type: SHOW_ALERT,
	payload: {
		color,
		message,
		timeOut,
	},
});

export const resetAlert = () => ({
	type: RESET_ALERT,
});
