import {takeEvery, takeLatest} from "redux-saga/effects";
import {loginUser, loginGetUser, logoutUser, loginChangePassword, tryLogin, changeAvatar, setNewPassword, resetPassword} from "./login-saga";
import {assignUsers, changeCategory, changeSubCategory, checkUploadToken, forwardRequest, getIssueTimeline, getUserTime, insertTimeClock, uploadAttachments} from "./serviceRequests-saga";
import {LOGIN_USER, LOGOUT_USER, LOGIN_GET_USER, LOGIN_CHANGE_PASSWORD, TRY_LOGIN, CHANGE_AVATAR} from "redux/actions/login-actions";
import {
	ASSIGN_USERS,
	CHANGE_CATEGORY,
	CHANGE_SUB_CATEGORY,
	CHECK_UPLOAD_TOKEN,
	FORWARD_SERVICE_REQUEST,
	GET_ISSUE_TIMELINE,
	GET_USER_TIME,
	INSERT_TIME_CLOCK,
	UPLOAD_FILES,
} from "redux/actions/serviceRequests-actions";
import {CHECK_RESET_TOKEN} from "redux/actions/login-actions";
import {checkResetToken} from "./login-saga";
import {FORGOT_PASSWORD} from "redux/actions/login-actions";
import {forgotPassword} from "./login-saga";
import {GET_SERVICE_REQUEST} from "redux/actions/serviceRequests-actions";
import {getServiceRequest, sendFaultStatus} from "./serviceRequests-saga";
import {CREATE_MISSING_ESS_MACHINES, SHOW_ALL_ALERTS, UPDATE_COORDS} from "redux/actions/debug-actions";
import {createMissingEssMachines, showAllAlerts, updateCoords} from "./debug-saga";
import {REFRESH_TOKEN} from "redux/actions/login-actions";
import {refreshToken} from "./login-saga";
import {SEND_FAULT_STATUS} from "redux/actions/serviceRequests-actions";
import {RESET_PASSWORD} from "redux/actions/login-actions";
import {SET_NEW_PASSWORD} from "redux/actions/login-actions";
import {
	ADD_MACHINE,
	GET_ACTIVITIES_LOGS,
	GET_COLLISIONS,
	GET_CONNECTION_LOGS,
	GET_EULA,
	GET_LEAK_TESTS,
	GET_MACHINE_BY_ID,
	GET_MACHINE_CHARTS,
	GET_MAINTENANCE,
	SEND_MERGED_DATA,
} from "redux/actions/machine-actions";
import {addMachine, getActivitiesLogs, getCollisions, getConnectionLogs, getEula, getLeakTests, getMachineById, getMachineCharts, getMaintenances, sendMergedData} from "./machines-saga";
import {GET_RECORDS} from "redux/actions/searchbar-actions";
import {getRecords} from "./searchbar-saga";
import {UPDATE_MACHINE} from "redux/actions/machine-actions";
import {updateMachine} from "./machines-saga";
import {ACTIVATE_PORTAL_USER, GET_CUSTOMER_BY_ID, GET_CUSTOMER_RATINGS, GET_PORTAL_USER, UPDATE_PORTAL_USER} from "redux/actions/customer-actions";
import {activatePortalUser, getCustomerById, getCustomerRatings, getPortalUserById, updatePortalUser} from "./customers-saga";
import {UPDATE_CUSTOMER} from "redux/actions/customer-actions";
import {updateCustomer} from "./customers-saga";
import {GET_DEALER_BY_ID} from "redux/actions/dealer-actions";
import {getDealerById} from "./dealers-saga";
import {UPDATE_DEALER} from "redux/actions/dealer-actions";
import {updateDealer} from "./dealers-saga";
import {UPDATE_USER} from "redux/actions/users-actions";
import {updateUser} from "./users-saga";
import {GET_USER_BY_ID} from "redux/actions/users-actions";
import {getUserById} from "./users-saga";
import {FETCH_DOCS, GET_ADDRESSED_OBJECTS, GET_CUSTOMER_NOTIFICATIONS, TOGGLE_MAIL_SUBSCRIPTION} from "redux/actions/general-actions";
import {fetchDocs, getAddressedObjects, getCustomerNotifications, toggleMailSubscription} from "./general-saga";
import {LOG_ERROR} from "redux/actions/general-actions";
import {logError} from "./general-saga";
import {GET_CHANGELOG} from "redux/actions/general-actions";
import {getChangelog} from "./general-saga";
import {MACHINE_SEND_NOTE} from "redux/actions/machine-actions";
import {machineSendNote} from "./machines-saga";
import {GET_MACHINE_NOTES, REMOVE_IPC} from "redux/actions/machine-actions";
import {getMachineNotes, removeIpc} from "./machines-saga";
import {DELETE_MACHINE_NOTE} from "redux/actions/machine-actions";
import {deleteMachineNote} from "./machines-saga";
import {PUT_MACHINE_NOTE} from "redux/actions/machine-actions";
import {putMachineNote} from "./machines-saga";
import {UPLOAD_PACKAGE} from "redux/actions/package-actions";
import {uploadPackage} from "./packages-saga";
import {CONFIRM_BROWSER} from "redux/actions/login-actions";
import {confirmBrowser} from "./login-saga";
import {RESEND_CONFIRM_EMAIL} from "redux/actions/login-actions";
import {resendEmail} from "./login-saga";
import {SEND_EMAILS} from "redux/actions/debug-actions";
import {sendEmails} from "./debug-saga";
import {getServiceNotifications} from "./general-saga";
import {GET_SERVICE_NOTIFICATIONS} from "redux/actions/general-actions";
import {GET_MACHINE_NOTIFICATIONS} from "redux/actions/general-actions";
import {getMachineNotifications} from "./general-saga";
import {GET_SERVICE_NOTES} from "redux/actions/serviceRequests-actions";
import {getServiceNotes} from "./serviceRequests-saga";
import {deleteServiceNote} from "./serviceRequests-saga";
import {DELETE_SERVICE_NOTE} from "redux/actions/serviceRequests-actions";
import {EDIT_SERVICE_NOTE} from "redux/actions/serviceRequests-actions";
import {editServiceNote} from "./serviceRequests-saga";
import {ADD_SERVICE_NOTE} from "redux/actions/serviceRequests-actions";
import {addServiceNote} from "./serviceRequests-saga";
import {BUG_REPORT} from "redux/actions/general-actions";
import {bugReport} from "./general-saga";
import {BAN_REQUEST} from "redux/actions/serviceRequests-actions";
import {banRequest} from "./serviceRequests-saga";
import {RESET_COUNTERS} from "redux/actions/serviceRequests-actions";
import {resetCounters} from "./serviceRequests-saga";
import {SEND_TEST_LOG} from "redux/actions/debug-actions";
import {sendTestLog} from "./debug-saga";
import {ADD_USER} from "redux/actions/users-actions";
import {addUser} from "./users-saga";
import {addLanguage, addMissingTag, fetchTranslation, getLanguages, getServiceRequestTranslation, saveUserLanguage, updateAllLanguages} from "./translations-saga";
import {ADD_LANGUAGE, ADD_MISSING_TAG, FETCH_TRANSLATION, GET_LANGUAGES, GET_SERVICE_REQUEST_TRANSLATION, SAVE_USER_LANGUAGE, UPDATE_ALL_LANGUAGES} from "redux/actions/translation-actions";
import {GET_NEW_REPORT, GET_REPORT, SEND_REPORT, UPLOAD_REPORT_FILES} from "redux/actions/serviceReports-actions";
import {getNewReport, getReport, sendReport, uploadReportFiles} from "./serviceReports-saga";

function* rootSaga() {
	yield takeLatest(GET_USER_TIME, getUserTime);
	yield takeLatest(INSERT_TIME_CLOCK, insertTimeClock);
	yield takeLatest(CHANGE_SUB_CATEGORY, changeSubCategory);
	yield takeLatest(ADD_MACHINE, addMachine);
	yield takeEvery(GET_ISSUE_TIMELINE, getIssueTimeline);
	yield takeLatest(GET_SERVICE_REQUEST_TRANSLATION, getServiceRequestTranslation);
	yield takeLatest(FORWARD_SERVICE_REQUEST, forwardRequest);
	yield takeLatest(GET_REPORT, getReport);
	yield takeLatest(UPLOAD_REPORT_FILES, uploadReportFiles);
	yield takeLatest(SEND_REPORT, sendReport);
	yield takeLatest(GET_NEW_REPORT, getNewReport);
	yield takeLatest(GET_MACHINE_CHARTS, getMachineCharts);
	yield takeLatest(GET_CUSTOMER_RATINGS, getCustomerRatings);
	yield takeLatest(UPDATE_PORTAL_USER, updatePortalUser);
	yield takeLatest(GET_PORTAL_USER, getPortalUserById);
	yield takeLatest(GET_COLLISIONS, getCollisions);
	yield takeLatest(GET_LEAK_TESTS, getLeakTests);
	yield takeLatest(GET_MAINTENANCE, getMaintenances);
	yield takeLatest(GET_ACTIVITIES_LOGS, getActivitiesLogs);
	yield takeLatest(GET_CONNECTION_LOGS, getConnectionLogs);
	yield takeLatest(SEND_MERGED_DATA, sendMergedData);
	yield takeLatest(CREATE_MISSING_ESS_MACHINES, createMissingEssMachines);
	yield takeLatest(GET_EULA, getEula);
	yield takeLatest(ACTIVATE_PORTAL_USER, activatePortalUser);
	yield takeLatest(GET_CUSTOMER_NOTIFICATIONS, getCustomerNotifications);
	yield takeLatest(FETCH_DOCS, fetchDocs);
	yield takeLatest(SHOW_ALL_ALERTS, showAllAlerts);
	yield takeLatest(UPDATE_ALL_LANGUAGES, updateAllLanguages);
	yield takeLatest(ADD_LANGUAGE, addLanguage);
	yield takeLatest(ADD_MISSING_TAG, addMissingTag);
	yield takeLatest(FETCH_TRANSLATION, fetchTranslation);
	yield takeLatest(SAVE_USER_LANGUAGE, saveUserLanguage);
	yield takeLatest(GET_LANGUAGES, getLanguages);
	yield takeLatest(CHANGE_CATEGORY, changeCategory);
	yield takeLatest(ASSIGN_USERS, assignUsers);
	yield takeLatest(REFRESH_TOKEN, refreshToken);
	yield takeLatest(FORGOT_PASSWORD, forgotPassword);
	yield takeLatest(UPLOAD_FILES, uploadAttachments);
	yield takeLatest(CHECK_UPLOAD_TOKEN, checkUploadToken);
	yield takeLatest(LOGIN_USER, loginUser);
	yield takeLatest(LOGIN_GET_USER, loginGetUser);
	yield takeLatest(LOGOUT_USER, logoutUser);
	yield takeLatest(RESET_PASSWORD, resetPassword);
	yield takeLatest(LOGIN_CHANGE_PASSWORD, loginChangePassword);
	yield takeLatest(SET_NEW_PASSWORD, setNewPassword);
	yield takeLatest(TRY_LOGIN, tryLogin);
	yield takeLatest(CHANGE_AVATAR, changeAvatar);
	yield takeLatest(CHECK_RESET_TOKEN, checkResetToken);
	yield takeLatest(GET_SERVICE_REQUEST, getServiceRequest);
	yield takeLatest(SEND_FAULT_STATUS, sendFaultStatus);
	yield takeLatest(UPDATE_COORDS, updateCoords);
	yield takeLatest(GET_MACHINE_BY_ID, getMachineById);
	yield takeLatest(GET_RECORDS, getRecords);
	yield takeLatest(UPDATE_MACHINE, updateMachine);
	yield takeLatest(GET_CUSTOMER_BY_ID, getCustomerById);
	yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
	yield takeLatest(GET_DEALER_BY_ID, getDealerById);
	yield takeLatest(UPDATE_DEALER, updateDealer);
	yield takeLatest(UPDATE_USER, updateUser);
	yield takeLatest(GET_USER_BY_ID, getUserById);
	yield takeLatest(GET_ADDRESSED_OBJECTS, getAddressedObjects);
	yield takeLatest(LOG_ERROR, logError);
	yield takeLatest(GET_CHANGELOG, getChangelog);
	yield takeLatest(MACHINE_SEND_NOTE, machineSendNote);
	yield takeLatest(GET_MACHINE_NOTES, getMachineNotes);
	yield takeLatest(DELETE_MACHINE_NOTE, deleteMachineNote);
	yield takeLatest(PUT_MACHINE_NOTE, putMachineNote);
	yield takeLatest(UPLOAD_PACKAGE, uploadPackage);
	yield takeLatest(CONFIRM_BROWSER, confirmBrowser);
	yield takeLatest(RESEND_CONFIRM_EMAIL, resendEmail);
	yield takeLatest(SEND_EMAILS, sendEmails);
	yield takeLatest(GET_SERVICE_NOTIFICATIONS, getServiceNotifications);
	yield takeLatest(GET_MACHINE_NOTIFICATIONS, getMachineNotifications);
	yield takeLatest(GET_SERVICE_NOTES, getServiceNotes);
	yield takeLatest(DELETE_SERVICE_NOTE, deleteServiceNote);
	yield takeLatest(EDIT_SERVICE_NOTE, editServiceNote);
	yield takeLatest(ADD_SERVICE_NOTE, addServiceNote);
	yield takeLatest(BUG_REPORT, bugReport);
	yield takeLatest(BAN_REQUEST, banRequest);
	yield takeLatest(RESET_COUNTERS, resetCounters);
	yield takeLatest(REMOVE_IPC, removeIpc);
	yield takeLatest(SEND_TEST_LOG, sendTestLog);
	yield takeLatest(ADD_USER, addUser);
	yield takeLatest(TOGGLE_MAIL_SUBSCRIPTION, toggleMailSubscription);
}

export default rootSaga;
