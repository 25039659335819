import React, {useEffect, useState} from "react";
import "styles/General/UserCard.scss";
import {connect} from "react-redux";
import UserAvatar from "../Utilities/UserAvatar";
import {unlockSecret} from "redux/actions/general-actions";
import {showAlert} from "redux/actions/Alert-actions";
import {Popup, ToolbarItem as PopupToolbar} from "devextreme-react/popup";
import UserMenu from "./UserMenu";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import usePopup from "hooks/usePopup";

export default function UserCard(props) {
	const user = props.loginReducer.currentUser ?? {dealer: {}};
	const [count, setCount] = useState(0);
	const popup = usePopup(400, 500);
	const {t} = useTranslation();
	const handleSecret = () => {
		if (count === 5) {
			props.unlockSecret();
			props.showAlert("success", "Secret unlocked.");
		}
		setCount(count + 1);
	};

	return (
		<>
			<Popup
				visible={popup.visible}
				title={t("User menu")}
				hideOnOutsideClick={true}
				showCloseButton={true}
				onHiding={popup.hide}
				{...(isMobile ? {} : popup.size)}
				dragEnabled={false}
				showTitle={true}>
				<UserMenu />
			</Popup>
			<div className="user-card__container">
				<div className="user-card__icon-container" onClick={handleSecret}>
					<UserAvatar>{user.avatarId}</UserAvatar>
				</div>
				<div className="user-card__content" onClick={popup.show}>
					<h6>{user.name}</h6>
					<h6>{user.dealer.name}</h6>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
	dealerReducer: state.dealers,
});
const mapDispatchToProps = (dispatch) => ({
	unlockSecret: () => {
		dispatch(unlockSecret());
	},
	showAlert: (color, message) => {
		dispatch(showAlert(color, message));
	},
});

UserCard = connect(mapStateToProps, mapDispatchToProps)(UserCard);
