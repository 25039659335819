import EssNavLink from "components/Utilities/EssNavLink";
import logo from "resources/My ESP Logo white.svg";
import {machine_icon as machines} from "util/base64icons";
import {dealers_list_icon as dealers} from "util/base64icons";
import UserCard from "components/Layout/UserCard";
import {faBug, faCloudUploadAlt, faHeadset, faPen, faUsers, faUsersCog} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {faAddressBook} from "@fortawesome/free-regular-svg-icons";
import {getCustomerNotifications, getServiceNotifications} from "redux/actions/general-actions";
import {useEffect} from "react";
import {getMachineNotifications} from "redux/actions/general-actions";
import {useTranslation} from "react-i18next";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import {CUSTOMERS_PAGE, DASHBOARD_PAGE, DEBUG_PAGE, DISTRIBUTORS_PAGE, GROUPS_PAGE, MACHINES_PAGE, REPORTS_PAGE, SERVICE_REQUESTS_PAGE, UPDATE_PAGE, USER_PAGE} from "routes";

export default function SideNav(props) {
	useEffect(() => {
		props.getServiceNotifications();
		props.getMachineNotifications();
		props.getCustomerNotifications();
	}, []);
	const {t} = useTranslation();
	const masterGroup = props.loginReducer.currentUser?.masterGroup ?? {};
	const dealer = props.loginReducer.currentUser?.dealer;
	const root = masterGroup.groupLevel === 255 ?? false;
	const serviceNotifications = props.generalReducer.serviceNotificationsCount;
	const machineNotifications = dealer?.dealerId === 1 ? props.generalReducer.machineNotificationsCount : 0;
	const customerNotifications = props.generalReducer.customerNotificationsCount;

	return (
		<div className={props.collapsed ? "side-nav side-nav-hidden" : "side-nav side-nav-shown"}>
			<UserCard />
			<EssNavLink icon={faGlobeEurope} style={{filter: "none"}} to={"/" + DASHBOARD_PAGE}>
				{t("Dashboard")}
			</EssNavLink>
			<EssNavLink notifications={machineNotifications} src={machines} to={"/" + MACHINES_PAGE}>
				{t("Machines")}
			</EssNavLink>
			{masterGroup.canAccessCustomers && (
				<EssNavLink notifications={customerNotifications} icon={faAddressBook} to={"/" + CUSTOMERS_PAGE}>
					{t("Customers")}
				</EssNavLink>
			)}
			{masterGroup.canAccessDealers && (
				<EssNavLink src={dealers} to={dealer.dealerId !== 1 ? `/${DISTRIBUTORS_PAGE}/${dealer?.name}/${dealer?.dealerId}` : "/" + DISTRIBUTORS_PAGE}>
					{dealer?.dealerId !== 1 ? t("Distributor") : t("Distributors")}
				</EssNavLink>
			)}
			{(masterGroup.canAccessServiceRequests || masterGroup.canBeAssignedToServiceRequest) && (
				<EssNavLink notifications={serviceNotifications} icon={faHeadset} to={"/" + SERVICE_REQUESTS_PAGE}>
					{t("Service requests")}
				</EssNavLink>
			)}
			{/* {(masterGroup.canAccessServiceRequests || masterGroup.canBeAssignedToServiceRequest) && (
				<EssNavLink icon={faPen} to={"/" + REPORTS_PAGE}>
					{t("Service reports")}
				</EssNavLink>
			)} */}
			{masterGroup.canAccessUsers && (
				<EssNavLink icon={faUsers} to={"/" + USER_PAGE}>
					{t("Users")}
				</EssNavLink>
			)}
			{masterGroup.canAccessGroups && (
				<EssNavLink icon={faUsersCog} to={"/" + GROUPS_PAGE}>
					{t("Groups")}
				</EssNavLink>
			)}
			{masterGroup.canManageUpdate && (
				<EssNavLink icon={faCloudUploadAlt} to={"/" + UPDATE_PAGE}>
					{t("Update")}
				</EssNavLink>
			)}
			{root && (
				<EssNavLink icon={faBug} to={"/" + DEBUG_PAGE}>
					{t("Debug")}
				</EssNavLink>
			)}
			{/* {process.env.NODE_ENV === "development" && <span style={{color: "red", position: "absolute", bottom: "50px", left: 0, zIndex: 100}}>DEBUG</span>} */}
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
	generalReducer: state.general,
});

const mapDispatchToProps = (dispatch) => ({
	getServiceNotifications: () => {
		dispatch(getServiceNotifications());
	},
	getMachineNotifications: () => {
		dispatch(getMachineNotifications());
	},
	getCustomerNotifications: () => {
		dispatch(getCustomerNotifications());
	},
});

SideNav = connect(mapStateToProps, mapDispatchToProps)(SideNav);
