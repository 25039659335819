import {Popup, ToolbarItem as PopupToolbar} from "devextreme-react/popup";
import useDevexForm from "hooks/useDevexForm";
import usePopup from "hooks/usePopup";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Form, {SimpleItem as Item, GroupItem, Label, RequiredRule, PatternRule, ButtonItem, EmailRule} from "devextreme-react/form";
import {customersStore} from "util/DevExtremeStores";
import {updatePortalUser} from "redux/actions/customer-actions";

export default function PortalUserEdit(props) {
	const popup = usePopup(400, 125);
	const form = useDevexForm();
	const {t} = useTranslation();
	const toggleEdit = props.toggleEdit;
	const portalUser = JSON.parse(JSON.stringify(props.children));

	const submitButtonOptions = {
		text: t("Save changes"),
		type: "success",
		useSubmitBehavior: false,
		onClick: popup.show,
	};

	const handleSubmit = () => {
		if (form.validate()) {
			let fd = form.parseToFormData(portalUser.portalUserId);
			props.updatePortalUser(fd);
			form.reset();
			toggleEdit();
		}
		popup.hide();
	};
	const closeButtonOptions = {
		text: t("Cancel"),
		icon: "close",
		onClick: popup.hide,
	};

	const customerOptions = {
		dataSource: customersStore,
		displayExpr: "name",
		valueExpr: "customerId",
		searchEnabled: true,
		showClearButton: true,
	};

	const saveButtonOptions = {
		text: t("Save"),
		icon: "save",
		onClick: handleSubmit,
	};
	return (
		<>
			<Popup visible={popup.visible} {...(isMobile ? {} : popup.size)} showCloseButton={false} dragEnabled={false} showTitle={false}>
				<PopupToolbar widget="dxButton" toolbar="bottom" location="before" options={closeButtonOptions} />
				<PopupToolbar widget="dxButton" toolbar="bottom" location="after" options={saveButtonOptions} />
				<h5 style={{textAlign: "center"}}>{t("Do you want to save changes?")}</h5>
			</Popup>
			<div style={{width: "100%", height: "100%"}}>
				<form onSubmit={(e) => handleSubmit(e)}>
					<Form
						ref={form.ref}
						formData={portalUser}
						readOnly={false}
						onFieldDataChanged={form.onValueChanged}
						showColonAfterLabel={true}
						showValidationSummary={false}
						labelLocation="top"
						validationGroup="machineData">
						<Item dataField="name" label={{text: t("Name")}} editorType="dxTextBox">
							<RequiredRule message={t("Name is required")} />
						</Item>

						<Item dataField="login" label={{text: t("Login")}}>
							<RequiredRule message={t("Login is required")} />
						</Item>
						<Item dataField="email" label={{text: t("Email")}}>
							<RequiredRule message={t("Email is required")} />
							<EmailRule message={t("Invalid email")} />
						</Item>
						<Item dataField="phone" label={{text: t("Phone number")}} />
						<Item dataField="isMainAccount" editorType="dxCheckBox" label={{text: t("Main Account")}} />
						<Item dataField="portalActive" editorType="dxCheckBox" label={{text: t("Active")}} />
						<Item dataField="customerId" label={{text: t("Customer")}} editorType="dxSelectBox" editorOptions={customerOptions} />
						<ButtonItem buttonOptions={submitButtonOptions} horizontalAlignment="left" />
						<div style={{height: "1rem"}}></div>
					</Form>
				</form>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	updatePortalUser: (formData) => {
		dispatch(updatePortalUser(formData));
	},
});

PortalUserEdit = connect(mapStateToProps, mapDispatchToProps)(PortalUserEdit);
