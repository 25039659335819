export const LOGIN_USER = "LOGIN/LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN/LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN/LOGIN_FAILED";
export const LOGIN_GET_USER = "LOGIN/GET_USER";
export const LOGIN_SET_USER = "LOGIN/SET_USER";
export const SET_LOGIN_LOADING_STATUS = "LOGIN/SET_LOADING_STATUS";
export const LOGOUT_USER = "LOGIN/LOGOUT_USER";
export const SET_LOGGING_OUT_STATUS = "LOGIN/SET_LOGGING_OUT_STATUS";
export const LOGIN_CHANGE_PASSWORD = "LOGIN/CHANGE_PASSWORD";
export const TRY_LOGIN = "LOGIN/TRY";
export const CHANGE_AVATAR = "LOGIN/CHANGE_AVATAR";
export const CHECK_RESET_TOKEN = "LOGIN/CHECK_RESET_TOKEN";
export const SET_RESET_TOKEN_STATUS = "LOGIN/SET_RESET_TOKEN_STATUS";
export const FORGOT_PASSWORD = "LOGIN/FORGOT_PASSWORD";
export const SET_FORGOT_ERROR = "LOGIN/SET_FORGOT_ERROR";
export const REFRESH_TOKEN = "LOGIN/REFRESH_TOKEN";
export const SET_REFRESH_TOKEN_STATUS = "LOGIN/SET_STATUS";
export const RESET_PASSWORD = "LOGIN/RESET_PASSWORD";
export const SET_NEW_PASSWORD = "LOGIN/SET_NEW_PASSWORD";
export const SET_UNTRUSTED_BROWSER = "LOGIN/SET_UNTRUSTED_BROWSER";
export const CONFIRM_BROWSER = "LOGIN/CONFIRM_BROWSER";
export const SET_CONFIRM_RESULT = "LOGIN/SET_CONFIRM_RESULT";
export const RESEND_CONFIRM_EMAIL = "LOGIN/RESEND_CONFIRM_EMAIL";

export const setLoginLoadingStatus = (isLoading) => ({
	type: SET_LOGIN_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const confirmBrowser = (token, navigate) => ({
	type: CONFIRM_BROWSER,
	payload: {
		token,
		navigate,
	},
});

export const setConfirmResult = (result) => ({
	type: SET_CONFIRM_RESULT,
	payload: {
		result,
	},
});

export const resendConfirmEmail = () => ({
	type: RESEND_CONFIRM_EMAIL,
});

export const setUntrustedBrowser = () => ({
	type: SET_UNTRUSTED_BROWSER,
});

export const setNewPassword = (newPassword, navigate) => ({
	type: SET_NEW_PASSWORD,
	payload: {
		newPassword,
		navigate,
	},
});

export const resetPassword = (userId) => ({
	type: RESET_PASSWORD,
	payload: {
		userId,
	},
});

export const setRefreshTokenStatus = (isBusy) => ({
	type: SET_REFRESH_TOKEN_STATUS,
	payload: {
		isBusy,
	},
});

export const refreshToken = () => ({
	type: REFRESH_TOKEN,
});

export const setForgotError = (status) => ({
	type: SET_FORGOT_ERROR,
	payload: {
		status,
	},
});

export const forgotPassword = (email) => ({
	type: FORGOT_PASSWORD,
	payload: {
		email,
	},
});

export const checkResetToken = (token) => ({
	type: CHECK_RESET_TOKEN,
	payload: {
		token,
	},
});

export const setResetTokenStatus = (isChecking, resettingUser = null) => ({
	type: SET_RESET_TOKEN_STATUS,
	payload: {
		isChecking,
		resettingUser,
	},
});

export const changeAvatar = (id, avatarId) => ({
	type: CHANGE_AVATAR,
	payload: {
		id,
		avatarId,
	},
});

export const loginUserAction = (credentials) => ({
	type: LOGIN_USER,
	payload: credentials,
});

export const loginSuccess = () => ({
	type: LOGIN_SUCCESS,
});

export const loginFailed = (error) => ({
	type: LOGIN_FAILED,
	payload: {
		error,
	},
});

export const logoutUser = () => ({
	type: LOGOUT_USER,
});

export const setLoggingOutStatus = (status) => ({
	type: SET_LOGGING_OUT_STATUS,
	payload: {
		status,
	},
});

export const tryLogin = () => ({
	type: TRY_LOGIN,
});

export const loginGetUser = () => ({
	type: LOGIN_GET_USER,
});

export const loginSetUser = (user) => ({
	type: LOGIN_SET_USER,
	payload: {
		user,
	},
});

export const loginChangePassword = (passwords) => ({
	type: LOGIN_CHANGE_PASSWORD,
	payload: {
		passwords,
	},
});
