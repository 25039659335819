export const LOGIN_PAGE = "login";
export const DASHBOARD_PAGE = "dashboard";
export const MACHINES_PAGE = "machines";
export const USER_PAGE = "users";
export const GROUPS_PAGE = "groups";
export const DISTRIBUTORS_PAGE = "distributors";
export const SERVICE_REQUESTS_PAGE = "service-requests";
export const UPDATE_PAGE = "update";
export const CUSTOMERS_PAGE = "customers";
export const DEBUG_PAGE = "debug";
export const PORTAL_USERS_PAGE = "portal-users";
export const REPORTS_PAGE = "reports";
export const NEW_REPORT_PAGE = "new-report";
export const SHOW_TIMELINES = "timeline";
