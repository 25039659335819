import {CONFIRM_TOGGLE_SUBSCRIPTION, TOGGLE_MAIL_SUBSCRIPTION} from "redux/actions/general-actions";
import {SET_RESET_TOKEN_STATUS} from "redux/actions/login-actions";
import {SET_LOGGING_OUT_STATUS} from "redux/actions/login-actions";
import {SET_REFRESH_TOKEN_STATUS} from "redux/actions/login-actions";
import {SET_CONFIRM_RESULT} from "redux/actions/login-actions";
import {SET_LOGIN_LOADING_STATUS} from "redux/actions/login-actions";
import {SET_UNTRUSTED_BROWSER} from "redux/actions/login-actions";
import {REFRESH_TOKEN} from "redux/actions/login-actions";
import {SET_FORGOT_ERROR} from "redux/actions/login-actions";
import {CHANGE_AVATAR} from "redux/actions/login-actions";
import {UPDATE_USER_LANGUAGE} from "redux/actions/translation-actions";
import {LOGIN_USER, LOGIN_SET_USER, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_USER} from "../actions/login-actions";

const initialState = {
	loggingOut: false,
	errorMessage: "",
	currentUser: null,
	forgotStatus: null,
	resetToken: {isChecking: true, resettingUser: null},
	isBusy: false,
	untrustedBrowser: false,
	confirmResult: 0,
	isLoggingIn: false,
};

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONFIRM_TOGGLE_SUBSCRIPTION: {
			let cUser = JSON.parse(JSON.stringify(state.currentUser));
			cUser.serviceRequestSubscription = !cUser.serviceRequestSubscription;
			return {...state, currentUser: cUser};
		}
		case UPDATE_USER_LANGUAGE: {
			let cUser = JSON.parse(JSON.stringify(state.currentUser));
			cUser.selectedLanguage = action.payload.language;
			return {...state, currentUser: cUser};
		}
		case SET_CONFIRM_RESULT: {
			return {...state, confirmResult: action.payload.result};
		}
		case SET_LOGIN_LOADING_STATUS: {
			return {...state, isLoggingIn: action.payload.isLoading};
		}
		case SET_UNTRUSTED_BROWSER: {
			return {...state, untrustedBrowser: true};
		}
		case SET_REFRESH_TOKEN_STATUS: {
			return {...state, isBusy: action.payload.isBusy};
		}
		case SET_FORGOT_ERROR: {
			return {...state, forgotStatus: action.payload.status};
		}
		case SET_RESET_TOKEN_STATUS: {
			return {...state, resetToken: action.payload};
		}
		case CHANGE_AVATAR: {
			let cUser = JSON.parse(JSON.stringify(state.currentUser));
			cUser.avatarId = action.payload.avatarID;
			return {...state, currentUser: cUser};
		}
		case LOGIN_SET_USER: {
			return {...state, currentUser: action.payload.user, untrustedBrowser: false};
		}
		case LOGIN_SUCCESS: {
			return {...state, errorMessage: ""};
		}
		case LOGIN_FAILED: {
			return {...state, errorMessage: action.payload.error};
		}
		case SET_LOGGING_OUT_STATUS: {
			return {...state, loggingOut: action.payload.status};
		}
		case LOGOUT_USER: {
			return {...state, currentUser: null};
		}
		default:
			return state;
	}
};
