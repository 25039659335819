import {loginChangePassword} from "redux/actions/login-actions";
import React, {useState} from "react";
import {connect} from "react-redux";
import Form, {SimpleItem as Item, GroupItem, Label, RequiredRule, PatternRule, ButtonItem, CompareRule} from "devextreme-react/form";
import Button from "devextreme-react/button";
import {useTranslation} from "react-i18next";
import useDevexForm from "hooks/useDevexForm";

export default function ChangePassword(props) {
	const form = useDevexForm();
	const hideModal = props.hideModal;

	const {t} = useTranslation();

	const passwordOptions = {
		mode: "password",
	};

	const buttonOptions = {
		text: t("Submit"),
		useSubmitBehavior: true,
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		form.validate();
		let passwordObject = {
			UserId: props.loginReducer.currentUser.essUserId,
			ShortOld: form.value.oldPassword,
			ShortNew: form.value.newPassword,
		};
		props.changePassword(passwordObject);
		form.reset();
		hideModal();
	};

	const checkPasswords = () => {
		return form.value?.newPassword ?? "";
	};

	return (
		<div className="user-card__change-password">
			<form onSubmit={handleSubmit}>
				<Form ref={form.ref} onFieldDataChanged={form.onValueChanged}>
					<Item dataField="oldPassword" label={{text: t("Old password")}} editorType="dxTextBox" editorOptions={passwordOptions}>
						<RequiredRule message={t("Old password is required")} />
					</Item>
					<Item dataField="newPassword" label={{text: t("New password")}} editorType="dxTextBox" editorOptions={passwordOptions}>
						<RequiredRule message={t("New password is required")} />
						<PatternRule
							pattern={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-=+,./|><\\`;:[{}\]])[A-Za-z\d!@#$%^&*()_\-=+,./<>|\\`;:[{}\]]{8,}$/}
							message={t("Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character")}
						/>
					</Item>
					<Item dataField="confirmNewPassword" label={{text: t("Confirm new password")}} editorType="dxTextBox" editorOptions={passwordOptions}>
						<RequiredRule message={t("Confirm password is required")} />
						<CompareRule message={t("Passwords don't match")} comparisonTarget={checkPasswords} />
					</Item>
					<ButtonItem buttonOptions={buttonOptions} />
				</Form>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	changePassword: (passwords) => {
		dispatch(loginChangePassword(passwords));
	},
});

ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
