import {PUSH_VALUE, SET_RECORDS} from "redux/actions/searchbar-actions";
import {SET_SEARCHBAR_VALUE} from "redux/actions/searchbar-actions";

const initialState = {
	searchbarValue: "",
	recordsList: [],
	listChanged: false,
};

export const searchbarReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SEARCHBAR_VALUE: {
			return {...state, searchbarValue: action.payload.value};
		}
		case SET_RECORDS: {
			return {...state, recordsList: action.payload.list};
		}
		case PUSH_VALUE: {
			let newValue = action.payload.value;
			let value = state.recordsList?.find((r) => r.findableId === newValue.findableId && r.path === newValue.path);
			let index = state.recordsList.indexOf(value);
			let recordsList = JSON.parse(JSON.stringify(state.recordsList));
			if (value) {
				recordsList[index] = {...value, findableId: newValue.findableId, path: newValue.path, name: newValue.name};
			} else {
				recordsList?.push({findableId: newValue.findableId, path: newValue.path, name: newValue.name});
			}
			return {...state, listChanged: !state.listChanged, recordsList: recordsList};
		}
		default:
			return state;
	}
};
