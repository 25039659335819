import {
	CHECK_UPLOAD_TOKEN,
	SET_NEW_SUB_CATEGORY,
	GET_SERVICE_REQUEST,
	SET_ISSUE_TIMELINE,
	SET_ISSUE_TIMELINE_LOADING_STATUS,
	SET_NEW_CATEGORY,
	SET_NOTES_LOADING,
	SET_SHARED,
	SET_USERS,
	SET_USER_TIME,
	IS_LOADING_USER_TIME,
} from "redux/actions/serviceRequests-actions";
import {SET_SERVICE_REQUEST_LOADING_STATUS} from "redux/actions/serviceRequests-actions";
import {SET_SERVICE_LOGS} from "redux/actions/serviceRequests-actions";
import {SET_SERVICE_LOGS_LOADING_STATUS} from "redux/actions/serviceRequests-actions";
import {SET_SERVICE_NOTES} from "redux/actions/serviceRequests-actions";
import {NOTIFY_FAULT_STATUS} from "redux/actions/serviceRequests-actions";
import {UPDATE_FAULT_STATUS} from "redux/actions/serviceRequests-actions";
import {SET_SERVICE_REQUEST} from "redux/actions/serviceRequests-actions";
import {SET_ATTACHMENT_UPLOAD_STATUS} from "redux/actions/serviceRequests-actions";
import {SET_CHECK_TOKEN_RESULT} from "redux/actions/serviceRequests-actions";
import {SET_TOKEN_LOADING_STATUS} from "redux/actions/serviceRequests-actions";
import {fromUTC} from "util/Utilities";

const initialState = {
	fetchedRequest: null,
	isLoadingLogs: true,
	isLoading: true,
	isLoadingNotes: true,
	uploadToken: {isLoading: true, isValid: false, errorMessage: ""},
	serviceLogs: [],
	uploadStatus: 0,
	issueTimelines: {},
	issueTimelinesLoadingStatuses: {},
	isLoadingUserTime: true,
	isUserTimerStopped: true,
	userTimerTotalSeconds: 0,
};

export const serviceRequestsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_TIME: {
			return {...state, isUserTimerStopped: action.payload.isStopped, userTimerTotalSeconds: action.payload.totalSeconds};
		}
		case IS_LOADING_USER_TIME: {
			return {...state, isLoadingUserTime: action.payload.isLoading};
		}
		case SET_NOTES_LOADING: {
			return {...state, isLoadingNotes: action.payload.isLoading};
		}
		case GET_SERVICE_REQUEST: {
			return {...state, issueTimelines: {}, issueTimelinesLoadingStatuses: {}};
		}
		case SET_ISSUE_TIMELINE_LOADING_STATUS: {
			let statuses = JSON.parse(JSON.stringify(state.issueTimelinesLoadingStatuses));
			statuses[action.payload.faultId] = action.payload.isLoading;
			return {...state, issueTimelinesLoadingStatuses: statuses};
		}
		case SET_ISSUE_TIMELINE: {
			let timeline = JSON.parse(JSON.stringify(state.issueTimelines));
			timeline[action.payload.faultId] = action.payload.timeline;
			return {...state, issueTimelines: timeline};
		}
		case SET_NEW_CATEGORY: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			let fault = fetchedRequest?.faults?.find((f) => f.faultId === action.payload.faultId);
			fault.category = action.payload.category;
			return {...state, fetchedRequest};
		}
		case SET_NEW_SUB_CATEGORY: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			let fault = fetchedRequest?.faults?.find((f) => f.faultId === action.payload.faultId);
			fault.subCategory = action.payload.subCategory;
			return {...state, fetchedRequest};
		}
		case SET_USERS: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			fetchedRequest.assignedUsers = action.payload.userList;
			return {...state, fetchedRequest};
		}
		case NOTIFY_FAULT_STATUS: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			let fault = action.payload.fault;
			if (fetchedRequest?.serviceRequestId === fault.serviceRequestId) {
				fetchedRequest.faults[fault.faultIndex - 1] = fault;
			}
			return {...state, fetchedRequest};
		}
		case SET_SERVICE_NOTES: {
			console.log(action.payload.notes);
			return {...state, fetchedRequest: {...state.fetchedRequest, notes: action.payload.notes}};
		}
		case SET_SERVICE_LOGS_LOADING_STATUS: {
			return {...state, isLoadingLogs: action.payload.isLoading};
		}
		case SET_SERVICE_LOGS: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			fetchedRequest.logs = action.payload.logs;
			return {...state, fetchedRequest};
		}
		case CHECK_UPLOAD_TOKEN: {
			return {...state, uploadStatus: 0};
		}
		case SET_CHECK_TOKEN_RESULT: {
			let uploadToken = JSON.parse(JSON.stringify(state.uploadToken));
			uploadToken.isValid = action.payload.result;
			uploadToken.errorMessage = action.payload.errorMessage;
			return {...state, uploadToken};
		}
		case SET_TOKEN_LOADING_STATUS: {
			let uploadToken = JSON.parse(JSON.stringify(state.uploadToken));
			uploadToken.isLoading = action.payload.isLoading;
			return {...state, uploadToken};
		}
		case SET_ATTACHMENT_UPLOAD_STATUS: {
			return {...state, uploadStatus: action.payload.status};
		}
		case SET_SERVICE_REQUEST_LOADING_STATUS: {
			return {...state, isLoading: action.payload.isLoading};
		}
		case UPDATE_FAULT_STATUS: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			let i = fetchedRequest.faults.findPropertyIndex("faultIndex", action.payload.faultIndex);
			if (i) {
				fetchedRequest.faults[i] = {...fetchedRequest.faults[i], ...action.payload.faultArgs};
			}
			return {...state, fetchedRequest};
		}
		case SET_SERVICE_REQUEST: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			let request = {...fetchedRequest, ...action.payload.request};
			request.creationDate = fromUTC(new Date(request?.creationDate)).toJSON();
			request.modifyDate = fromUTC(new Date(request?.modifyDate)).toJSON();
			request.faults = request.faults.map((f) => {
				return {...f, occurringDate: fromUTC(new Date(f?.occurringDate)).toJSON(), plannedDateTime: fromUTC(new Date(f?.plannedDateTime)).toJSON()};
			});
			return {...state, fetchedRequest: request};
		}
		case SET_SHARED: {
			let fetchedRequest = JSON.parse(JSON.stringify(state.fetchedRequest));
			fetchedRequest.shared = true;
			return {...state, fetchedRequest};
		}
		default:
			return state;
	}
};
