import {SET_CUSTOMER, SET_CUSTOMER_LOADING_STATUS, SET_CUSTOMER_RATINGS, SET_PORTAL_USER, SET_RATINGS_LOADING_STATUS} from "redux/actions/customer-actions";

const initialState = {
	fetchedCustomer: null,
	fetchedCustomerRatings: [],
	fetchedPortalUser: null,
	isLoading: true,
	isLoadingRatings: true,
};

export const customerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_RATINGS_LOADING_STATUS: {
			return {...state, isLoadingRatings: action.payload.isLoading};
		}
		case SET_CUSTOMER_RATINGS: {
			return {...state, fetchedCustomerRatings: action.payload.list};
		}
		case SET_PORTAL_USER: {
			return {...state, fetchedPortalUser: action.payload.portalUser};
		}
		case SET_CUSTOMER: {
			return {...state, fetchedCustomer: action.payload.customer};
		}
		case SET_CUSTOMER_LOADING_STATUS: {
			return {...state, isLoading: action.payload.isLoading};
		}
		default:
			return state;
	}
};
