import {fetchDocs} from "redux/actions/general-actions";
import {useEffect} from "react";
import {connect} from "react-redux";

export default function Docs(props) {
	useEffect(() => {
		if (props.loginReducer.currentUser) {
			props.fetchDocs();
		}
	}, [props.loginReducer.currentUser]);
	const docs = props.generalReducer.docs;
	return (
		<ul>
			{docs.map((d, index) => (
				<li key={index}>
					<a href={d?.url} target="_blank">
						{d?.title}
					</a>
				</li>
			))}
		</ul>
	);
}

const mapStateToProps = (state) => ({
	generalReducer: state.general,
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	fetchDocs: () => {
		dispatch(fetchDocs());
	},
});

Docs = connect(mapStateToProps, mapDispatchToProps)(Docs);
