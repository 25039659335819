import {call, put} from "@redux-saga/core/effects";
import {showAlert} from "redux/actions/Alert-actions";
import {setCustomerLoadingStatus, setCustomerRatings, setPortalUser, setRatingLoadingStatus} from "redux/actions/customer-actions";
import {setCustomer} from "redux/actions/customer-actions";
import {getCustomerNotifications, logError} from "redux/actions/general-actions";
import {pushValue} from "redux/actions/searchbar-actions";
import RestClient from "util/RestClient";
import i18n from "../../i18n";

export function* getCustomerRatings(action) {
	yield put(setRatingLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getCustomerRatings(payload.customerId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setCustomerRatings(res.data));
		} else {
			throw "Couldn't fetch ratings";
		}
	} catch (e) {
		console.error(e);
		yield put(setCustomerRatings([]));
	}
	yield put(setRatingLoadingStatus(false));
}

export function* activatePortalUser(action) {
	yield put(setCustomerLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.activatePortalUser(payload.portalUserId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setPortalUser(res.data));
			yield put(getCustomerNotifications());
			yield put(showAlert("success", i18n.t("Account activated successfully")));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't activate customer account")));
	}
	yield put(setCustomerLoadingStatus(false));
}

export function* getPortalUserById(action) {
	yield put(setCustomerLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getPortalUserById(payload.portalUserId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setPortalUser(res.data));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't fetch portal user information.")));
	}
	yield put(setCustomerLoadingStatus(false));
}

export function* getCustomerById(action) {
	yield put(setCustomerLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getCustomerById(payload.customerId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setCustomer(res.data));
			yield put(pushValue(res.data));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't fetch customer information.")));

		//yield put(logError("customers-saga/getCustomerById: " + e.message))
	}
	yield put(setCustomerLoadingStatus(false));
}

export function* updateCustomer(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.updateCustomer(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(setCustomer(res.data));
			yield put(showAlert("success", i18n.t("Customer information updated")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("customers-saga/updateCustomer: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't save customer")));
	}
}

export function* updatePortalUser(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.updatePortalUser(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(setPortalUser(res.data));
			yield put(showAlert("success", i18n.t("Portal user information updated")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("customers-saga/updateCustomer: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't save portal user")));
	}
}
