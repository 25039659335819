import {call, put} from "redux-saga/effects";
import RestClient from "../../util/RestClient";
import {showAlert} from "../actions/Alert-actions";
import {
	setActivitiesLogs,
	setActivitiesLogsStatus,
	setCollisions,
	setConnectionLogs,
	setConnectionLogsStatus,
	setEula,
	setLeakTests,
	setMachine,
	setMachineCharts,
	setMachineChartsLoadingStatus,
	setMaintenance,
} from "redux/actions/machine-actions";
import {clearIpc} from "redux/actions/machine-actions";
import {logError} from "redux/actions/general-actions";
import {setMachineLoadingStatus} from "redux/actions/machine-actions";
import {machinePushNote} from "redux/actions/machine-actions";
import {setMachineNotesLoadingStatus} from "redux/actions/machine-actions";
import {setMachineNotes} from "redux/actions/machine-actions";
import {removeMachineNote} from "redux/actions/machine-actions";
import {updateMachineNote} from "redux/actions/machine-actions";
import {UTCtoLocal} from "util/Utilities";
import {getMachineNotifications} from "redux/actions/general-actions";
import {pushValue} from "redux/actions/searchbar-actions";
import i18n from "../../i18n";

export function* addMachine(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.addMachine(payload.machine);
		}, action.payload);
		if (res.status === 200) {
			yield put(showAlert("success", i18n.t("Machine was added successfully")));
		} else {
			throw "Couldn't add machine";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't add new machine")));
	}
}

export function* getMachineCharts(action) {
	yield put(setMachineChartsLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getMachineCharts(payload.machineId, payload.monthsBack);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMachineCharts(res.data));
		} else {
			throw "Couldn't fetch charts";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't fetch charts")));
	}
	yield put(setMachineChartsLoadingStatus(false));
}

export function* getMaintenances(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getMaintenances(payload.machineId, payload.month, payload.year);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMaintenance(res.data));
		} else {
			throw "Can't fetch maintenances";
		}
	} catch (e) {
		console.error(e);
		yield put("Couldn't fetch maintenances");
	}
}

export function* getLeakTests(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getLeakTests(payload.machineId, payload.month, payload.year);
		}, action.payload);
		if (res.status === 200) {
			yield put(setLeakTests(res.data));
		} else {
			throw "Can't fetch leak tests";
		}
	} catch (e) {
		console.error(e);
		yield put("Couldn't fetch leak tests");
	}
}

export function* getCollisions(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getCollisions(payload.machineId, payload.month, payload.year);
		}, action.payload);
		if (res.status === 200) {
			yield put(setCollisions(res.data));
		} else {
			throw "Can't fetch collisions";
		}
	} catch (e) {
		console.error(e);
		yield put("Couldn't fetch collisions");
	}
}

export function* getActivitiesLogs(action) {
	yield put(setActivitiesLogsStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getActivitiesLogs(payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			if (res.data?.logs?.length > 0) {
				let total = 0;
				res.data.logs = res.data.logs.map((d, index) => {
					total += d.endTime;
					return {...d, index};
				});
				res.data.total = total;
			}
			yield put(setActivitiesLogs(res.data));
		} else {
			throw "Can't fetch connection logs";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", "Couldn't fetch activities logs"));
	}
	yield put(setActivitiesLogsStatus(false));
}

export function* getConnectionLogs(action) {
	yield put(setConnectionLogsStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getConnectionLogs(payload.machineId, payload.month, payload.year);
		}, action.payload);
		if (res.status === 200) {
			if (res.data?.logs?.length > 0) {
				let total = 0;
				res.data.logs = res.data.logs.map((d, index) => {
					total += d.endTime;
					return {...d, index};
				});
				res.data.total = total;
			}
			yield put(setConnectionLogs(res.data));
		} else {
			throw "Can't fetch connection logs";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", "Couldn't fetch connection logs"));
	}
	yield put(setConnectionLogsStatus(false));
}

export function* sendMergedData(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.sendMergedData(payload.mergedData);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMachine(res.data));
			yield put(showAlert("success", "Conflicts resolved"));
		} else {
			throw "Can't merge";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", "Couldn't merge data"));
	}
}

export function* getEula(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getEula(payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setEula(res.data));
		} else {
			throw "Can't fetch Eula";
		}
	} catch (e) {
		console.error(e);
		yield put(setEula(null));
	}
}

export function* removeIpc(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.removeIpc(payload.machineId);
		}, action.payload);
		if (res.status === 204) {
			yield put(clearIpc());
			yield put(showAlert("success", i18n.t("IPC information removed")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("machine-saga/removeIpc: " + e.message))

		yield put(showAlert("danger", i18n.t("Failed to remove IPC information")));
	}
}

export function* machineSendNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.machineSendNote(payload.note, payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			yield put(machinePushNote(res.data));
			yield put(showAlert("success", i18n.t("Note saved")));
		} else {
			throw "Couldn't save machine note.";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't save machine note")));
		//yield put(logError("machine-saga/machineSendNote: " + e.message))
	}
}

export function* putMachineNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.putMachineNote(payload.note, payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			yield put(updateMachineNote(res.data));
			yield put(showAlert("success", i18n.t("Note saved")));
		} else {
			throw "Couldn't save machine note.";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't save machine note")));
		//yield put(logError("machine-saga/putMachineNote: " + e.message))
	}
}

export function* deleteMachineNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.deleteMachineNote(payload.note.noteId, payload.machineId);
		}, action.payload);
		if (res.status === 204) {
			yield put(removeMachineNote(action.payload.note));
			yield put(showAlert("success", i18n.t("Note deleted")));
		} else {
			throw "Couldn't delete machine note.";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't delete machine note")));
		//yield put(logError("machine-saga/deleteMachineNote: " + e.message))
	}
}

export function* getMachineNotes(action) {
	yield put(setMachineNotesLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getMachineNotes(payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMachineNotes(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("machine-saga/getMachineNotes" + e.message))
	}
	yield put(setMachineNotesLoadingStatus(false));
}

export function* getMachineById(action) {
	yield put(setMachineLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getMachineById(payload.machineId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMachine(res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("machine-saga/getMachineById: " + e.message))
	}
	yield put(setMachineLoadingStatus(false));
}

export function* updateMachine(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.updateMachine(payload.form);
		}, action.payload);
		if (res.status === 200) {
			yield put(setMachine(res.data));
			yield put(pushValue(res.data.value));
			yield put(getMachineNotifications());
			yield put(showAlert("success", i18n.t("Machine information updated")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("machine-saga/updateMachine: " + e.message))

		yield put(showAlert("danger", i18n.t("Couldn't save machine")));
	}
}
