export const GET_RECORDS = "SEARCHBAR/GET_RECORDS";
export const SET_RECORDS = "SEARCHBAR/SET_RECORDS";
export const SET_SEARCHBAR_VALUE = "SEARCHBAR/SET_SEARCHBAR_VALUE";
export const PUSH_VALUE = "SEARCHBAR/PUSH_VALUE";

export const pushValue = (value) => ({
	type: PUSH_VALUE,
	payload: {
		value,
	},
});

export const getRecords = () => ({
	type: GET_RECORDS,
});

export const setRecords = (list) => ({
	type: SET_RECORDS,
	payload: {
		list,
	},
});

export const setSearchbarValue = (value) => ({
	type: SET_SEARCHBAR_VALUE,
	payload: {
		value,
	},
});
