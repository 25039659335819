import {useEffect, useState} from "react";
import {connect} from "react-redux";
import Form, {SimpleItem as Item, RequiredRule, ButtonItem} from "devextreme-react/form";
import EssSpinner from "components/Utilities/EssSpinner";
import {saveUserLanguage} from "redux/actions/translation-actions";
import {useTranslation} from "react-i18next";

export default function SelectLanguage(props) {
	const {t} = useTranslation();
	const hideModal = props.hideModal;
	const [languageOptions, setLanguagesOptions] = useState([]);
	const currentLanguage = props.loginReducer.currentUser?.selectedLanguage?.code ?? "en";
	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

	const dataChanged = (e) => {
		setSelectedLanguage(e.value);
	};

	useEffect(() => {
		const options = {
			dataSource: props.translationReducer.languagesList,
			valueExpr: "code",
			displayExpr: "name",
			onValueChanged: dataChanged,
		};
		setLanguagesOptions(options);
	}, [props.translationReducer.languagesList]);

	const save = () => {
		hideModal();
		if (selectedLanguage !== currentLanguage) {
			props.saveUserLanguage(selectedLanguage);
		}
	};

	const buttonOptions = {
		icon: "save",
		text: t("Save"),
		onClick: save,
	};

	return languageOptions.length === 0 ? (
		<p>{t("Couldn't fetch languages")}</p>
	) : (
		<Form formData={{language: selectedLanguage}}>
			<Item dataField="language" label={{text: t("Language")}} editorType="dxSelectBox" editorOptions={languageOptions} />
			<ButtonItem buttonOptions={buttonOptions} />
		</Form>
	);
}

const mapStateToProps = (state) => ({
	translationReducer: state.translations,
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	saveUserLanguage: (code) => {
		dispatch(saveUserLanguage(code));
	},
});

SelectLanguage = connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
