export const GET_MACHINE_BY_ID = "MACHINES/GET_BY_ID";
export const SET_MACHINE = "MACHINES/SET";
export const UPDATE_MACHINE = "MACHINES/UPDATE";
export const SET_MACHINE_LOADING_STATUS = "MACHINES/SET_LOADING";
export const GET_MACHINE_NOTES = "MACHINES/GET_NOTES";
export const SET_MACHINE_NOTES = "MACHINES/SET_NOTES";
export const SET_MACHINE_NOTES_LOADING_STATUS = "MACHINES/SET_LOADING_NOTES";
export const UPDATE_FETCHED_MACHINE_STATE = "MACHINES/UPDATE_FETCHED_MACHINE_STATE";
export const NOTIFY_DEALER_CHANGED = "MACHINES/NOTIFY_DEALER_CHANGED";
export const SET_MACHINE_CHARTS_LOADING_STATUS = "MACHINES/SET_MACHINE_CHARTS_LOADING_STATUS";

//send request to db
export const REMOVE_IPC = "MACHINES/REMOVE_IPC";
export const MACHINE_SEND_NOTE = "MACHINES/SEND_NOTE";
export const PUT_MACHINE_NOTE = "MACHINES/PUT_MACHINE_NOTE";
export const DELETE_MACHINE_NOTE = "MACHINES/DELETE_MACHINE_NOTE";
export const SEND_MERGED_DATA = "MACHINES/SEND_MERGED_DATA";
export const GET_MACHINE_CHARTS = "MACHINES/GET_MACHINE_CHARTS";

//update info in reducer
export const SET_MACHINE_CHARTS = "MACHINES/SET_MACHINE_CHARTS";
export const CLEAR_IPC = "MACHINES/CLEAR_IPC";
export const MACHINE_PUSH_NOTE = "MACHINES/PUSH_NOTE";
export const UPDATE_MACHINE_NOTE = "MACHINES/UPDATE_MACHINE_NOTE";
export const REMOVE_MACHINE_NOTE = "MACHINES/REMOVE_MACHINE_NOTE";
export const SET_RESET_COUNTERS = "MACHINES/SET_RESET_COUNTERS";
export const GET_EULA = "MACHINES/GET_EULA";
export const SET_EULA = "MACHINES/SET_EULA";
export const GET_CONNECTION_LOGS = "MACHINE/GET_CONNECTION_LOGS";
export const SET_CONNECTION_LOGS = "MACHINE/SET_CONNECTION_LOGS";
export const SET_CONNECTION_LOGS_STATUS = "MACHINE/SET_CONNECTION_LOGS_STATUS";
export const GET_ACTIVITIES_LOGS = "MACHINE/GET_ACTIVITIES_LOGS";
export const SET_ACTIVITIES_LOGS = "MACHINE/SET_ACTIVITIES_LOGS";
export const SET_ACTIVITIES_LOGS_STATUS = "MACHINE/SET_ACTIVITIES_LOGS_STATUS";
export const GET_LEAK_TESTS = "MACHINE/GET_LEAK_TESTS";
export const SET_LEAK_TESTS = "MACHINE/SET_LEAK_TESTS";
export const GET_COLLISIONS = "MACHINE/GET_COLLISIONS";
export const SET_COLLISIONS = "MACHINE/SET_COLLISIONS";
export const GET_MAINTENANCE = "MACHINE/GET_MAINTENANCE";
export const SET_MAINTENANCE = "MACHINE/SET_MAINTENANCE";
export const ADD_MACHINE = "MACHINE/ADD_MACHINE";

export const addMachine = (machine) => ({
	type: ADD_MACHINE,
	payload: {
		machine,
	},
});

export const getMachineCharts = (machineId, monthsBack) => ({
	type: GET_MACHINE_CHARTS,
	payload: {
		machineId,
		monthsBack,
	},
});

export const setMachineCharts = (list) => ({
	type: SET_MACHINE_CHARTS,
	payload: {
		list,
	},
});

export const setMachineChartsLoadingStatus = (isLoading) => ({
	type: SET_MACHINE_CHARTS_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getMaintenance = (machineId, month, year) => ({
	type: GET_MAINTENANCE,
	payload: {
		machineId,
		month,
		year,
	},
});

export const setMaintenance = (list) => ({
	type: SET_MAINTENANCE,
	payload: {
		list,
	},
});

export const getCollisions = (machineId, month, year) => ({
	type: GET_COLLISIONS,
	payload: {
		machineId,
		month,
		year,
	},
});

export const setCollisions = (list) => ({
	type: SET_COLLISIONS,
	payload: {
		list,
	},
});

export const getLeakTests = (machineId, month, year) => ({
	type: GET_LEAK_TESTS,
	payload: {
		machineId,
		month,
		year,
	},
});

export const setLeakTests = (list) => ({
	type: SET_LEAK_TESTS,
	payload: {
		list,
	},
});

export const getActivitiesLogs = (machineId) => ({
	type: GET_ACTIVITIES_LOGS,
	payload: {
		machineId,
	},
});

export const setActivitiesLogs = (activities) => ({
	type: SET_ACTIVITIES_LOGS,
	payload: {
		activities,
	},
});

export const setActivitiesLogsStatus = (isLoading) => ({
	type: SET_ACTIVITIES_LOGS_STATUS,
	payload: {
		isLoading,
	},
});

export const getConnectionLogs = (machineId, month, year) => ({
	type: GET_CONNECTION_LOGS,
	payload: {
		machineId,
		month,
		year,
	},
});

export const setConnectionLogs = (connections) => ({
	type: SET_CONNECTION_LOGS,
	payload: {
		connections,
	},
});

export const setConnectionLogsStatus = (isLoading) => ({
	type: SET_CONNECTION_LOGS_STATUS,
	payload: {
		isLoading,
	},
});

export const sendMergedData = (mergedData) => ({
	type: SEND_MERGED_DATA,
	payload: {
		mergedData,
	},
});

export const getEula = (machineId) => ({
	type: GET_EULA,
	payload: {
		machineId,
	},
});

export const setEula = (eula) => ({
	type: SET_EULA,
	payload: {
		eula,
	},
});

export const setResetCounters = () => ({
	type: SET_RESET_COUNTERS,
});

export const notifyDealerChanged = (machine) => ({
	type: NOTIFY_DEALER_CHANGED,
	payload: {
		machine,
	},
});

export const updateMachineState = (state) => ({
	type: UPDATE_FETCHED_MACHINE_STATE,
	payload: {
		state,
	},
});

export const putMachineNote = (note, machineId) => ({
	type: PUT_MACHINE_NOTE,
	payload: {
		note,
		machineId,
	},
});

export const updateMachineNote = (note) => ({
	type: UPDATE_MACHINE_NOTE,
	payload: {
		note,
	},
});

export const deleteMachineNote = (note, machineId) => ({
	type: DELETE_MACHINE_NOTE,
	payload: {
		note,
		machineId,
	},
});

export const removeMachineNote = (note) => ({
	type: REMOVE_MACHINE_NOTE,
	payload: {
		note,
	},
});

export const getMachineNotes = (machineId) => ({
	type: GET_MACHINE_NOTES,
	payload: {
		machineId,
	},
});

export const setMachineNotes = (notes) => ({
	type: SET_MACHINE_NOTES,
	payload: {
		notes,
	},
});

export const setMachineNotesLoadingStatus = (isLoading) => ({
	type: SET_MACHINE_NOTES_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const machineSendNote = (note, machineId) => ({
	type: MACHINE_SEND_NOTE,
	payload: {
		note,
		machineId,
	},
});

export const machinePushNote = (note) => ({
	type: MACHINE_PUSH_NOTE,
	payload: {
		note,
	},
});

export const setMachineLoadingStatus = (isLoading) => ({
	type: SET_MACHINE_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const clearIpc = () => ({
	type: CLEAR_IPC,
});

export const removeIpc = (machineId) => ({
	type: REMOVE_IPC,
	payload: {
		machineId,
	},
});

export const setMachine = (machine) => ({
	type: SET_MACHINE,
	payload: {
		machine,
	},
});

export const getMachineById = (machineId) => ({
	type: GET_MACHINE_BY_ID,
	payload: {
		machineId,
	},
});

export const updateMachine = (form) => ({
	type: UPDATE_MACHINE,
	payload: {
		form,
	},
});
