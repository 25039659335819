export const GET_CUSTOMER_BY_ID = "CUSTOMERS/GET_BY_ID";
export const SET_CUSTOMER = "CUSTOMERS/SET_FETCHED";
export const UPDATE_CUSTOMER = "CUSTOMERS/UPDATE";
export const UPDATE_PORTAL_USER = "CUSTOMERS/UPDATE_PORTAL_USER";
export const SET_CUSTOMER_LOADING_STATUS = "CUSTOMERS/SET_LOADING";
export const ACTIVATE_PORTAL_USER = "CUSTOMERS/ACTIVATE";
export const GET_PORTAL_USER = "CUSTOMERS/GET_PORTAL_USER";
export const SET_PORTAL_USER = "CUSTOMERS/SET_PORTAL_USER";
export const GET_CUSTOMER_RATINGS = "CUSTOMERS/GET_CUSTOMER_RATINGS";
export const SET_CUSTOMER_RATINGS = "CUSTOMERS/SET_CUSTOMER_RATINGS";
export const SET_RATINGS_LOADING_STATUS = "CUSTOMERS/SET_RATINGS_LOADING_STATUS";

export const setRatingLoadingStatus = (isLoading) => ({
	type: SET_RATINGS_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getCustomerRatings = (customerId) => ({
	type: GET_CUSTOMER_RATINGS,
	payload: {
		customerId,
	},
});

export const setCustomerRatings = (list) => ({
	type: SET_CUSTOMER_RATINGS,
	payload: {
		list,
	},
});

export const getPortalUser = (portalUserId) => ({
	type: GET_PORTAL_USER,
	payload: {
		portalUserId,
	},
});

export const setPortalUser = (portalUser) => ({
	type: SET_PORTAL_USER,
	payload: {
		portalUser,
	},
});

export const activatePortalUser = (portalUserId) => ({
	type: ACTIVATE_PORTAL_USER,
	payload: {
		portalUserId,
	},
});

export const setCustomerLoadingStatus = (isLoading) => ({
	type: SET_CUSTOMER_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getCustomerById = (customerId) => ({
	type: GET_CUSTOMER_BY_ID,
	payload: {
		customerId,
	},
});

export const setCustomer = (customer) => ({
	type: SET_CUSTOMER,
	payload: {
		customer,
	},
});

export const updateCustomer = (form) => ({
	type: UPDATE_CUSTOMER,
	payload: {
		form,
	},
});

export const updatePortalUser = (form) => ({
	type: UPDATE_PORTAL_USER,
	payload: {
		form,
	},
});
