import React, {Suspense, useEffect, useState} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import {machine_icon as machines, dealers_list_icon as dealers} from "util/base64icons";
import {faBug, faCloudUploadAlt, faHeadset, faUsers, faUsersCog, faAddressBook, faGlobeEurope, faPen} from "@fortawesome/free-solid-svg-icons";
import useQuery from "hooks/useQuery";
import "styles/General/main.scss";
import "styles/General/Modal.scss";
import "styles/Ess/EssTable.scss";
import Layout from "components/Layout/Layout";
import GlobalAlert from "components/Utilities/GlobalAlert";
import EssSpinner from "components/Utilities/EssSpinner";
import MachineSignalR from "components/Machines/MachineSignalR";
import AddBrowser from "components/Login/AddBrowser";
import {machinesStore, serviceRequestActiveStore, serviceRequestArchiveStore} from "util/DevExtremeStores";
import {fetchTranslation, getLanguages} from "redux/actions/translation-actions";
import TranslationManager from "components/Utilities/TranslationManager";
import {Navigate, Route, Routes} from "react-router-dom";
import {
	CUSTOMERS_PAGE,
	DASHBOARD_PAGE,
	DEBUG_PAGE,
	DISTRIBUTORS_PAGE,
	GROUPS_PAGE,
	LOGIN_PAGE,
	MACHINES_PAGE,
	NEW_REPORT_PAGE,
	PORTAL_USERS_PAGE,
	REPORTS_PAGE,
	SERVICE_REQUESTS_PAGE,
	SHOW_TIMELINES,
	UPDATE_PAGE,
	USER_PAGE,
} from "routes";
import PortalUserDetails from "components/Customers/PortalUserDetails";
import TestPage from "components/_Debug/TestPage";
const NewReport = React.lazy(() => import("components/ServiceReports/NewReport"));
const ReportsList = React.lazy(() => import("components/ServiceReports/ReportsList"));
const ReportDetails = React.lazy(() => import("components/ServiceReports/ReportDetails"));
const ServiceRequestDetails4 = React.lazy(() => import("components/ServiceRequest/ServiceRequestDetails4"));
const AttachmentUploadPage = React.lazy(() => import("components/ServiceRequest/AttachmentUploadPage"));
const Dashboard = React.lazy(() => import("components/Dashboard/Dashboard"));
const LogsList = React.lazy(() => import("components/_Debug/LogsList"));
const DealerDetails = React.lazy(() => import("components/Dealers/DealerDetails"));
const UserDetails = React.lazy(() => import("components/Users/UserDetails"));
const CustomerDetails = React.lazy(() => import("components/Customers/CustomerDetails"));
const MachineDetails = React.lazy(() => import("components/Machines/MachineDetails"));
const Debug = React.lazy(() => import("components/_Debug/Debug"));
const CustomerTabs = React.lazy(() => import("components/Customers/CustomerTabs"));
const ServiceRequest = React.lazy(() => import("components/ServiceRequest/ServiceRequest"));
const SetPassword = React.lazy(() => import("components/Utilities/SetPassword"));
const Update = React.lazy(() => import("components/UpdatePage/Update"));
const Dealers = React.lazy(() => import("components/Dealers/Dealers"));
const Login = React.lazy(() => import("components/Login/Login"));
const Machines = React.lazy(() => import("components/Machines/Machines"));
const Users = React.lazy(() => import("components/Users/Users"));
const Groups = React.lazy(() => import("components/Groups/Groups"));

export default function App(props) {
	const {sri, srn, mi, mn} = useQuery();
	const [redirect, setRedirect] = useState("/dashboard");
	useEffect(() => {
		async function effect() {
			setRedirect("/dashboard");
			if (sri && srn) {
				let existActive = await serviceRequestActiveStore.byKey(parseInt(sri));
				let existArchive = await serviceRequestArchiveStore.byKey(parseInt(sri));
				if ((existActive && existActive.name === srn) || (existArchive && existArchive.name === srn)) setRedirect(`/service-requests/${srn}/${sri}`);
			}
			if (mi && mn) {
				let existMachine = await machinesStore.byKey(parseInt(mi));
				if (existMachine && existMachine.name === mn) setRedirect(`/machines/${mn}/${mi}`);
			}
		}
		effect();
		props.fetchLanguage(window.localStorage.language ?? "en");
	}, [sri, srn, mi, mn]);

	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		setLoggedIn(props.loginReducer.currentUser && !props.loginReducer.loggingOut);
	}, [props.loginReducer.currentUser, props.loginReducer.loggingOut]);

	const isScrollable = props.generalReducer.isScrollable;
	const masterGroup = props.loginReducer.currentUser?.masterGroup;
	const root = props.loginReducer.currentUser?.masterGroup.groupLevel === 255 ?? false;
	return (
		<div className={classNames({"main-app": loggedIn && !isScrollable, "main-app-anonymous": !loggedIn, "main-app-full": isScrollable})}>
			<Routes>
				<Route
					exact
					path={"/" + LOGIN_PAGE}
					element={
						!loggedIn ? (
							<Suspense fallback={<EssSpinner light />}>
								<Login />
							</Suspense>
						) : (
							<Navigate to={redirect} />
						)
					}
				/>
				<Route exact path="/addBrowser/:token" element={<AddBrowser />} />
				<Route
					exact
					path="/setpassword/:token"
					element={
						<Suspense fallback={<EssSpinner light />}>
							<SetPassword />
						</Suspense>
					}
				/>
				<Route
					exact
					path="/service/attachment/:token"
					element={
						<Suspense fallback={<EssSpinner light />}>
							<AttachmentUploadPage />
						</Suspense>
					}
				/>

				<Route
					path="*"
					element={
						loggedIn ? (
							<>
								<MachineSignalR />
								<TranslationManager />
								<Layout />
							</>
						) : (
							<Navigate to={"/" + LOGIN_PAGE} />
						)
					}>
					<Route
						exact
						path={DASHBOARD_PAGE}
						element={
							<Suspense fallback={<EssSpinner icon={faGlobeEurope} />}>
								<Dashboard />
							</Suspense>
						}
					/>

					<Route
						exact
						path={MACHINES_PAGE}
						element={
							<Suspense fallback={<EssSpinner src={machines} />}>
								<Machines />
							</Suspense>
						}
					/>
					<Route
						exact
						path={MACHINES_PAGE + "/:name/:machineId"}
						element={
							<Suspense fallback={<EssSpinner src={machines} />}>
								<MachineDetails />
							</Suspense>
						}
					/>
					{masterGroup?.canAccessUsers && (
						<Route
							exact
							path={USER_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faUsers} />}>
									<Users />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessUsers && (
						<Route
							exact
							path={USER_PAGE + "/:name/:userId"}
							element={
								<Suspense fallback={<EssSpinner icon={faUsers} />}>
									<UserDetails />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessGroups && (
						<Route
							exact
							path={GROUPS_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faUsersCog} />}>
									<Groups />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessDealers && (
						<Route
							exact
							path={DISTRIBUTORS_PAGE}
							element={
								<Suspense fallback={<EssSpinner src={dealers} />}>
									<Dealers />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessDealers && (
						<Route
							exact
							path={DISTRIBUTORS_PAGE + "/:name/:dealerId"}
							element={
								<Suspense fallback={<EssSpinner src={dealers} />}>
									<DealerDetails />
								</Suspense>
							}
						/>
					)}
					{(masterGroup?.canAccessServiceRequests || masterGroup?.canBeAssignedToServiceRequest) && (
						<Route
							exact
							path={SERVICE_REQUESTS_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faHeadset} />}>
									<ServiceRequest />
								</Suspense>
							}
						/>
					)}
					{(masterGroup?.canAccessServiceRequests || masterGroup?.canBeAssignedToServiceRequest) && (
						<Route
							exact
							path={SERVICE_REQUESTS_PAGE + "/:name/:serviceRequestId"}
							element={
								<Suspense fallback={<EssSpinner icon={faHeadset} />}>
									<ServiceRequestDetails4 />
								</Suspense>
							}
						/>
					)}
					{/* {(masterGroup?.canAccessServiceRequests || masterGroup?.canBeAssignedToServiceRequest) && (
						<Route
							exact
							path={NEW_REPORT_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faHeadset} />}>
									<NewReport />
								</Suspense>
							}
						/>
					)} */}
					{/* {(masterGroup?.canAccessServiceRequests || masterGroup?.canBeAssignedToServiceRequest) && (
						<Route
							exact
							path={REPORTS_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faPen} />}>
									<ReportsList />
								</Suspense>
							}
						/>
					)} */}
					{/* {(masterGroup?.canAccessServiceRequests || masterGroup?.canBeAssignedToServiceRequest) && (
						<Route
							exact
							path={`${REPORTS_PAGE}/:reportId`}
							element={
								<Suspense fallback={<EssSpinner icon={faPen} />}>
									<ReportDetails />
								</Suspense>
							}
						/>
					)} */}
					{masterGroup?.canManageUpdate && (
						<Route
							exact
							path={UPDATE_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faCloudUploadAlt} />}>
									<Update />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessCustomers && (
						<Route
							exact
							path={CUSTOMERS_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faAddressBook} />}>
									<CustomerTabs />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canAccessCustomers && (
						<Route
							exact
							path={CUSTOMERS_PAGE + "/:name/:customerId"}
							element={
								<Suspense fallback={<EssSpinner icon={faAddressBook} />}>
									<CustomerDetails />
								</Suspense>
							}
						/>
					)}
					{masterGroup?.canManagePortalUsers && (
						<Route
							exact
							path={PORTAL_USERS_PAGE + "/:name/:portalUserId"}
							element={
								<Suspense fallback={<EssSpinner icon={faAddressBook} />}>
									<PortalUserDetails />
								</Suspense>
							}
						/>
					)}
					{root && (
						<Route
							exact
							path={DEBUG_PAGE}
							element={
								<Suspense fallback={<EssSpinner icon={faBug} />}>
									<Debug />
								</Suspense>
							}
						/>
					)}
					{root && (
						<Route
							exact
							path={DEBUG_PAGE + "/logs"}
							element={
								<Suspense fallback={<EssSpinner icon={faBug} />}>
									<LogsList />
								</Suspense>
							}
						/>
					)}
				</Route>
			</Routes>
			<GlobalAlert />
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
	generalReducer: state.general,
});

const mapDispatchToProps = (dispatch) => ({
	fetchLanguage: (code) => {
		dispatch(fetchTranslation(code));
	},
});

App = connect(mapStateToProps, mapDispatchToProps)(App);
