import {call, put} from "redux-saga/effects";
import RestClient from "../../util/RestClient";
import {decodeUrl, sleep} from "util/Utilities";
import {showAlert} from "../actions/Alert-actions";
import {
	setCheckTokenResult,
	setIsLoadingUserTime,
	setIssueTimeline,
	setIssueTimelineLoadingStatus,
	setNewCategory,
	setNewSubCategory,
	setNotesLoading,
	setShared,
	setUserTime,
	getUserTime as getUserTimeAction,
	setUsers,
} from "redux/actions/serviceRequests-actions";
import {setTokenLoadingStatus} from "redux/actions/serviceRequests-actions";
import {setAttachmentUploadStatus} from "redux/actions/serviceRequests-actions";
import {setServiceRequestLoadingStatus, setServiceRequest} from "redux/actions/serviceRequests-actions";
import {updateFaultStatus} from "redux/actions/serviceRequests-actions";
import {setServiceLogs} from "redux/actions/serviceRequests-actions";
import {setServiceLogsLoadingStatus} from "redux/actions/serviceRequests-actions";
import {getServiceNotifications, raisePropertyChanged} from "redux/actions/general-actions";
import {setServiceNotes} from "redux/actions/serviceRequests-actions";
import {getServiceNotes as getServiceNotesAction} from "redux/actions/serviceRequests-actions";
import {setResetCounters} from "redux/actions/machine-actions";
import {getIssueTimeline as getIssueTimelineAction} from "redux/actions/serviceRequests-actions";
import i18n from "../../i18n";

export function* getUserTime(action) {
	yield put(setIsLoadingUserTime(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getUserTime(payload.srId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setUserTime(res.data?.isStopped, res.data?.totalSeconds));
		} else {
			throw "Couldn't fetch user time";
		}
	} catch (e) {
		console.error(e);
	}
	yield put(setIsLoadingUserTime(false));
}

export function* insertTimeClock(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.insertTimeClock(payload.srId);
		}, action.payload);
		if (res.status === 204) {
			yield put(getUserTimeAction(action.payload.srId));
		} else {
			throw "Couldn't save user time";
		}
	} catch (e) {
		yield put(showAlert("error", "Couldn't save user time"));
		console.error(e);
	}
}

export function* getIssueTimeline(action) {
	yield put(setIssueTimelineLoadingStatus(true, action.payload.faultId));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getIssueTimeline(payload.faultId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setIssueTimeline(res.data, action.payload.faultId));
		} else {
			throw "Couldn't fetch timeline for issue with ID: " + action.payload.faultId;
		}
	} catch (e) {
		console.error(e);
	}
	yield put(setIssueTimelineLoadingStatus(false, action.payload.faultId));
}

export function* forwardRequest(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.forwardServiceRequest(payload.serviceRequestId, payload.forwardReason);
		}, action.payload);
		if (res.status === 200) {
			yield put(showAlert("success", i18n.t("Request forwarded successfully")));
			yield put(getServiceNotesAction(action.payload.serviceRequestId));
			yield put(setShared());
		} else {
			throw "Couldn't forward request";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't forward request")));
	}
}

export function* changeSubCategory(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.changeSubCategory(payload.faultId, payload.subCategory);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Subcategory changed")));
			yield put(setNewSubCategory(action.payload.faultId, action.payload.subCategory));
			yield put(raisePropertyChanged());
		} else {
			throw "Couldn't change subcategory";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't change subcategory")));
	}
}

export function* changeCategory(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.changeCategory(payload.faultId, payload.category);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Category changed")));
			yield put(setNewCategory(action.payload.faultId, action.payload.category));
			yield put(raisePropertyChanged());
		} else {
			throw "Couldn't change category";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't change category")));
	}
}

export function* assignUsers(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.assignUsers(payload.ticketCode, payload.userList);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Users assigned")));
			yield put(setUsers(action.payload.userList));
			yield put(raisePropertyChanged());
		} else {
			throw "Couldn't assign users";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't assign users")));
	}
}

export function* banRequest(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.banRequest(payload.ticketCode, payload.revert);
		}, action.payload);
		if (res.status === 200) {
			yield put(showAlert("success", i18n.t("Request severity changed")));
			yield put(setServiceRequest(res.data));
		} else {
			throw "Couldn't ban request";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't ban this request")));
	}
}

export function* resetCounters(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.resetCounters(payload.machineId);
		}, action.payload);
		if (res.status === 204) {
			yield put(setResetCounters());
			yield put(showAlert("success", i18n.t("Counters resetted")));
		} else throw "Couldn't reset counters";
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't reset counters")));
	}
}

export function* getServiceNotes(action) {
	yield put(setNotesLoading(true));
	try {
		console.log(action.payload.requestId);
		const res = yield call(async (payload) => {
			return await RestClient.getServiceNotes(payload.requestId);
		}, action.payload);
		if (res.status === 200) {
			console.log(res.data);
			yield put(setServiceNotes(res.data));
		} else {
			throw "Couldn't fetch notes.";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't fetch notes")));
	}
	yield put(setNotesLoading(false));
}

export function* addServiceNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.addServiceNote({note: payload.note, filename: payload.file?.name ?? null}, payload.requestId);
		}, action.payload);
		if (res.status === 200) {
			yield call(async (file) => {
				if (res.data?.url) {
					console.log(decodeUrl(res.data.url));
					await RestClient.uploadPackage(decodeUrl(res.data.url), file);
				}
			}, action.payload.file);
			yield put(showAlert("success", i18n.t("Note saved")));
			yield put(getServiceNotesAction(action.payload.requestId));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't add new note")));
	}
}

export function* editServiceNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.editServiceNote(payload.note);
		}, action.payload);
		if (res.status === 200) {
			yield put(showAlert("success", i18n.t("Note saved")));
			yield put(getServiceNotesAction(action.payload.note.requestId));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't edit note")));
	}
}

export function* deleteServiceNote(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.deleteServiceNote(payload.note);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Note deleted")));
			yield put(getServiceNotesAction(action.payload.note.requestId));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't delete note")));
	}
}

export function* checkUploadToken(action) {
	yield put(setTokenLoadingStatus(true));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.checkUploadToken(payload.token);
		}, action.payload);
		if (res.status === 204) {
			yield put(setCheckTokenResult(true));
		}
	} catch (e) {
		console.error(e);
		let message = (e + "").includes("404") ? i18n.t("Invalid token") : i18n.t("Unexpected error");
		yield put(setCheckTokenResult(false, message));
	}
	yield put(setTokenLoadingStatus(false));
}

export function* sendFaultStatus(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.sendFaultStatus(payload.faultArgs);
		}, action.payload);
		if (res.status === 204) {
			yield put(updateFaultStatus(action.payload.ticketId, action.payload.faultIndex, action.payload.faultArgs));
			if (action.payload.faultArgs.comment) {
				yield put(getServiceNotesAction(action.payload.ticketId));
			}
			yield put(getIssueTimelineAction(action.payload.faultArgs.faultId));
			yield put(getServiceNotifications());
			yield put(showAlert("success", i18n.t("Status saved")));
		}
		if (res.status === 404) {
			console.error(`An following communication error occurred in ${res.data.location}: ${res.data.message}`);
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("serviceRequests-saga/sendFaultStatus: " + e.message))
		yield put(showAlert("danger", i18n.t("Error")));
	}
}

export function* uploadAttachments(action) {
	yield put(setAttachmentUploadStatus(3));
	try {
		const res = yield call(async (payload) => {
			let arr = payload.meta.map((f) => f.fileName);
			return await RestClient.getUploadUrls(payload.token, arr);
		}, action.payload);
		if (res.status === 200) {
			yield call(async (payload) => {
				for (let i = 0; i < res.data.length; i++) {
					let url = decodeUrl(res.data[i]);
					let response = await RestClient.uploadAttachment(url, payload.meta[i].type, payload.files[i]);
					if (!response) throw "An error occured during upload.";
				}
			}, action.payload);
			yield put(setAttachmentUploadStatus(1));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("serviceRequests-saga/uploadAttachments: " + e.message))

		yield put(setAttachmentUploadStatus(2));
	}
	yield call(async () => {
		await sleep(5000);
	});
	yield put(setAttachmentUploadStatus(0));
}

export function* getServiceRequest(action) {
	yield put(setServiceRequestLoadingStatus(true));
	yield put(setServiceLogsLoadingStatus(true));
	let srRes;
	try {
		srRes = yield call(async (payload) => {
			return await RestClient.getServiceRequest(payload.serviceRequestId);
		}, action.payload);
		if (srRes.status === 200) {
			yield put(setServiceRequest(srRes.data));
		} else {
			throw "Couldn't fetch full Service Request information";
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("serviceRequests-saga/getServiceRequest: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't fetch full service request information")));
		yield put(setServiceRequestLoadingStatus(false));
		return;
	}
	yield put(setServiceRequestLoadingStatus(false));
	yield put(getServiceNotesAction(srRes.data.serviceRequestId));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getServiceLogs(payload.serviceRequestId);
		}, action.payload);
		if (res.status === 200) {
			yield put(setServiceLogs(res.data));
		}
		if (res.status === 404) {
			yield put(showAlert("danger", i18n.t("Error")));
			console.error(`An following communication error occurred in ${res.data.location}: ${res.data.message}`);
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("serviceRequests-saga/getServiceLogs: " + e.message))

		yield put(showAlert("danger", i18n.t("Couldn't load logs")));
	}
	yield put(setServiceLogsLoadingStatus(false));
}
