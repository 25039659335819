import React, {useRef, useState} from "react";

export default function useDevexForm(initialState = {}) {
	const ref = useRef(null);
	const [value, forceValue] = useState(initialState);
	const onValueChanged = (e) => {
		let v = value;
		v[e.dataField] = e.value;
		forceValue(v);
	};
	const reset = () => forceValue(initialState);
	const validate = () => {
		let res = ref.current.instance.validate();
		return res.isValid && value && Object.keys(value).length !== 0;
	};
	const parseToFormData = (key) => {
		let fd = new FormData();
		if (key) {
			fd.append("key", key);
		}
		fd.append("values", JSON.stringify(value));
		return fd;
	};
	return {ref, value, onValueChanged, reset, validate, parseToFormData, forceValue};
}
