import {call, put} from "redux-saga/effects";
import {loginSuccess, loginFailed, loginSetUser, loginGetUser as loginGetUserAction} from "../actions/login-actions";
import RestClient from "../../util/RestClient";
import {showAlert} from "../actions/Alert-actions";
import {setResetTokenStatus} from "redux/actions/login-actions";
import {setForgotError} from "redux/actions/login-actions";
import {setLoggingOutStatus} from "redux/actions/login-actions";
import {setRefreshTokenStatus} from "redux/actions/login-actions";
import {reinitializeStores} from "util/DevExtremeStores";
import {logError, raisePropertyChanged} from "redux/actions/general-actions";
import {setUntrustedBrowser} from "redux/actions/login-actions";
import {setConfirmResult} from "redux/actions/login-actions";
import {sleep} from "util/Utilities";
import {setLoginLoadingStatus} from "redux/actions/login-actions";
import i18n from "../../i18n";
export function* confirmBrowser(action) {
	yield put(setConfirmResult(0));
	try {
		const res = yield call(async (payload) => {
			return await RestClient.confirmBrowser(payload.token);
		}, action.payload);
		if (res.status === 204) {
			yield put(setConfirmResult(1));
			yield call(async () => {
				await sleep(5000);
				window.close();
			});
		}
	} catch (e) {
		console.error(e);
		yield put(setConfirmResult(2));
	}
}

export function* resendEmail() {
	try {
		const res = yield call(async () => {
			return await RestClient.resendConfirmEmail();
		});
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Email sent")));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't resend email")));
	}
}

export function* refreshToken() {
	yield put(setRefreshTokenStatus(true));
	try {
		yield call(async () => {
			await RestClient.refreshToken();
		});
	} catch (e) {
		console.error(e);
	}
	yield put(setRefreshTokenStatus(false));
}

export function* forgotPassword(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.forgotPassword(payload.email);
		}, action.payload);
		if (res.status === 204) {
			yield put(setForgotError(""));
			yield put(showAlert("success", i18n.t("Email sent")));
		}
	} catch (e) {
		console.error(e);
		if ((e + "").includes("404")) {
			yield put(setForgotError(i18n.t("User not found")));
		} else {
			yield put(setForgotError(i18n.t("Unexpected error")));
			//yield put(logError(e.message))
		}
	}
}

export function* checkResetToken(action) {
	yield put(setResetTokenStatus(true));
	try {
		let res = yield call(async (payload) => {
			return await RestClient.checkResetToken(payload.token);
		}, action.payload);
		if (res.status === 200) {
			yield put(setResetTokenStatus(false, res.data));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("login-saga/checkResetToken: " + e.message))
		yield put(setResetTokenStatus(false));
	}
}

export function* loginUser(action) {
	yield put(setLoginLoadingStatus(true));
	try {
		const response = yield call(async (credentials) => {
			return await RestClient.loginUser(credentials.login, credentials.password);
		}, action.payload);
		if (response.status === 204) {
			yield put(loginGetUserAction());
		} else {
			yield put(loginFailed(i18n.t("Wrong username or password")));
		}
	} catch (e) {
		let error = e + "";
		if (error.includes("403")) {
			yield put(setUntrustedBrowser());
		} else if (error.includes("401") || error.includes("404")) {
			yield put(loginFailed(i18n.t("Wrong username or password")));
		} else {
			yield put(loginFailed(i18n.t("Unexpected error")));
			//yield put(logError(e.message))
		}
	}
	yield put(setLoginLoadingStatus(false));
}

export function* loginChangePassword(action) {
	try {
		const response = yield call(async (payload) => {
			return await RestClient.loginChangePassword(payload.passwords);
		}, action.payload);
		if (response.status === 204) {
			yield put(showAlert("success", i18n.t("Password has been changed")));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("login-saga/loginChangePassword: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't change password")));
	}
}

export function* tryLogin() {
	try {
		const response = yield call(async () => {
			return await RestClient.getUser();
		});
		if (response.status === 200) {
			yield put(loginSetUser(response.data));
			yield put(loginSuccess());
		}
	} catch {}
}

export function* loginGetUser() {
	try {
		const response = yield call(async () => {
			return await RestClient.getUser();
		});
		if (response.status === 200) {
			yield put(loginSetUser(response.data));
			yield put(loginSuccess());
		}
	} catch (e) {
		let err = e + "";
		if (err.includes("401")) yield put(loginFailed(i18n.t("User already logged in")));
		console.error(e);
	}
}

export function* logoutUser(action) {
	yield put(setLoggingOutStatus(true));
	try {
		yield call(async () => {
			await RestClient.revokeToken();
		}, action.payload);
		if (process.env.NODE_ENV === "production") {
			window.location.reload();
		}
	} catch (e) {}
	yield put(setLoggingOutStatus(false));
}

export function* changeAvatar(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.changeAvatar({
				essUserId: payload.id,
				AvatarID: payload.avatarId,
			});
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Avatar saved")));
			yield put(raisePropertyChanged());
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("login-saga/changeAvatar: " + e.message))
		yield put(showAlert("danger", i18n.t("Couldn't save avatar")));
	}
}

export function* resetPassword(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.resetPassword(payload.userId);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Password reset")));
		}
	} catch (e) {
		console.error(e);
		if ((e + "").includes("404")) {
			yield put(logoutUser());
		} else {
			//yield put(logError("login-saga/resetPassword: " + e.message))
			yield put(showAlert("danger", i18n.t("Mail client error")));
		}
	}
}

export function* setNewPassword(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.setNewPassword(payload.newPassword);
		}, action.payload);
		if (res.status) {
			yield put(showAlert("success", i18n.t("Success")));
			yield call(() => action.payload.navigate("/dashboard"));
		}
	} catch (e) {
		console.error(e);
		//yield put(logError("login-saga/setNewPassword: " + e.message))
		yield put(showAlert("danger", i18n.t("Error")));
	}
}
