export const GET_DEALER_BY_ID = "DEALERS/GET_BY_ID";
export const SET_DEALER = "DEALERS/SET";
export const UPDATE_DEALER = "DEALERS/UPDATE";
export const SET_DEALER_LOADING_STATUS = "DEALERS/SET_LOADING";

export const setDealerLoadingStatus = (isLoading) => ({
	type: SET_DEALER_LOADING_STATUS,
	payload: {
		isLoading,
	},
});

export const getDealerById = (dealerId) => ({
	type: GET_DEALER_BY_ID,
	payload: {
		dealerId,
	},
});

export const setDealer = (dealer) => ({
	type: SET_DEALER,
	payload: {
		dealer,
	},
});

export const updateDealer = (form) => ({
	type: UPDATE_DEALER,
	payload: {
		form,
	},
});
