import {showAlert} from "redux/actions/Alert-actions";
import {setInprogressMessage} from "redux/actions/debug-actions";
import {logError} from "redux/actions/general-actions";
import {call, put} from "redux-saga/effects";
import RestClient from "util/RestClient";
import i18n from "../../i18n";

export function* createMissingEssMachines() {
	yield put(setInprogressMessage(i18n.t("Creating machines")));
	try {
		yield call(async () => {
			return await RestClient.debug_createMissingEssMachines();
		});
		yield put(showAlert("success", i18n.t("Missing machine data created successfully")));
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Error")));
	}
	yield put(setInprogressMessage(null));
}

export function* sendTestLog(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.debug_sendTestLog(payload.message);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Test log sent")));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Error")));
	}
}

export function* sendEmails(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.debug_sendEmail(payload.type, payload.address);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Email sent")));
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Error")));
	}
}

export function* updateCoords() {
	yield put(setInprogressMessage(i18n.t("Updating coords")));
	try {
		const res = yield call(async () => {
			return await RestClient.debug_updateCoords();
		});
		if (res.status === 200) {
			yield put(showAlert("success", res.data));
		} else {
			throw "Couldn't update coords";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't update coords")));
	}
	yield put(setInprogressMessage(null));
}

export function* showAllAlerts() {
	try {
		yield put(showAlert("danger", i18n.t("Couldn't update coords")));
		yield put(showAlert("danger", i18n.t("Error")));
		yield put(showAlert("success", i18n.t("Customer information updated")));
		yield put(showAlert("danger", i18n.t("Couldn't save customer")));
		yield put(showAlert("success", i18n.t("Test log sent")));
		yield put(showAlert("success", i18n.t("Distributor information updated")));
		yield put(showAlert("danger", i18n.t("Couldn't resend email")));
		yield put(showAlert("danger", i18n.t("Couldn't save distributor information")));
		yield put(showAlert("success", i18n.t("Email sent")));
		yield put(showAlert("success", i18n.t("Password reset")));
		yield put(showAlert("danger", i18n.t("Can't submit bug report")));
		yield put(showAlert("success", i18n.t("Bug report submitted")));
		yield put(showAlert("danger", i18n.t("Couldn't change password")));
		yield put(showAlert("danger", i18n.t("Couldn't delete note")));
		yield put(showAlert("success", i18n.t("Note deleted")));
		yield put(showAlert("success", i18n.t("Machine information updated")));
		yield put(showAlert("danger", i18n.t("User not found")));
		yield put(showAlert("danger", i18n.t("Wrong username or password")));
		yield put(showAlert("danger", i18n.t("Failed to remove IPC information")));
		yield put(showAlert("success", i18n.t("Upload started")));
		yield put(showAlert("danger", i18n.t("Couldn't save avatar")));
		yield put(showAlert("danger", i18n.t("Mail client error")));
		yield put(showAlert("success", i18n.t("Password has been changed")));
		yield put(showAlert("danger", i18n.t("Couldn't fetch notes")));
		yield put(showAlert("danger", i18n.t("Couldn't save machine")));
		yield put(showAlert("danger", i18n.t("Couldn't save machine note")));
		yield put(showAlert("success", i18n.t("Success")));
		yield put(showAlert("danger", i18n.t("Couldn't load logs")));
		yield put(showAlert("danger", i18n.t("Couldn't add new note")));
		yield put(showAlert("danger", i18n.t("Invalid machine information")));
		yield put(showAlert("success", i18n.t("IPC information removed")));
		yield put(showAlert("success", i18n.t("Languages updated")));
		yield put(showAlert("danger", i18n.t("Couldn't update languages")));
		yield put(showAlert("success", i18n.t("Avatar saved")));
		yield put(showAlert("success", i18n.t("Users assigned")));
		yield put(showAlert("success", i18n.t("Request severity changed")));
		yield put(showAlert("success", i18n.t("Language added")));
		yield put(showAlert("danger", i18n.t("Couldn't add new language")));
		yield put(showAlert("danger", i18n.t("Couldn't reset counters")));
		yield put(showAlert("danger", i18n.t("Couldn't fetch full service request information")));
		yield put(showAlert("success", i18n.t("Counters resetted")));
		yield put(showAlert("danger", i18n.t("Couldn't save language")));
		yield put(showAlert("success", i18n.t("Note saved")));
		yield put(showAlert("danger", i18n.t("Couldn't assign users")));
		yield put(showAlert("danger", i18n.t("Unexpected error")));
		yield put(showAlert("danger", i18n.t("User already logged in")));
		yield put(showAlert("danger", i18n.t("Couldn't ban this request")));
		yield put(showAlert("success", i18n.t("Language saved")));
		yield put(showAlert("success", i18n.t("Status saved")));
		yield put(showAlert("success", i18n.t("Uploading")));
		yield put(showAlert("success", i18n.t("Category changed")));
		yield put(showAlert("danger", i18n.t("Couldn't save user information")));
		yield put(showAlert("success", i18n.t("User added successfully")));
		yield put(showAlert("danger", i18n.t("Couldn't edit note")));
		yield put(showAlert("danger", i18n.t("Couldn't change category")));
		yield put(showAlert("success", i18n.t("Package uploaded successfully")));
		yield put(showAlert("success", i18n.t("Invalid token")));
	} catch (e) {
		console.error(e);
	}
}
