export const SET_SIDE_NAV_VISIBILITY = "GENERAL/SET_SIDE_NAV_VISIBILITY";
export const SET_APP_SCROLLABILITY = "GENERAL/SET_APP_SCROLLABILITY";
export const UNLOCK_SECRET = "GENERAL/UNLOCK_SECRET";
export const GET_ADDRESSED_OBJECTS = "GENERAL/GET_ADDRESSED_OBJECTS";
export const SET_ADDRESSED_OBJECTS = "GENERAL/SET_ADDRESSED_OBJECTS";
export const LOG_ERROR = "GENERAL/LOG_ERROR";
export const GET_CHANGELOG = "GENERAL/GET_CHANGELOG";
export const SET_CHANGELOG = "GENERAL/SET_CHANGELOG";
export const SET_CHANGELOG_READED = "GENERAL/SET_READED";
export const GET_SERVICE_NOTIFICATIONS = "GENERAL/GET_NOTIFICATIONS";
export const SET_SERVICE_NOTIFICATIONS = "GENERAL/SET_NOTIFICATIONS";
export const GET_MACHINE_NOTIFICATIONS = "GENERAL/GET_MACHINE_NOTIFICATIONS";
export const SET_MACHINE_NOTIFICATIONS = "GENERAL/SET_MACHINE_NOTIFICATIONS";
export const GET_CUSTOMER_NOTIFICATIONS = "GENERAL/GET_CUSTOMER_NOTIFICATIONS";
export const SET_CUSTOMER_NOTIFICATIONS = "GENERAL/SET_CUSTOMER_NOTIFICATIONS";
export const RAISE_PROPERTY_CHANGED = "GENERAL/RAISE_PROPERTY_CHANGED";
export const BUG_REPORT = "GENERAL/BUG_REPORT";
export const TOGGLE_MAIL_SUBSCRIPTION = "GENERAL/TOGGLE_SUBSCRIPTION";
export const CONFIRM_TOGGLE_SUBSCRIPTION = "GENERAL/CONFIRM_TOGGLE";
export const FETCH_DOCS = "GENERAL/FETCH_DOCS";
export const SET_DOCS = "GENERAL/SET_DOCS";
export const SET_URL = "GENERAL/SET_URL";

export const setUrl = (url) => ({
	type: SET_URL,
	payload: {
		url,
	},
});

export const getCustomerNotifications = () => ({
	type: GET_CUSTOMER_NOTIFICATIONS,
});

export const setCustomerNotifications = (count) => ({
	type: SET_CUSTOMER_NOTIFICATIONS,
	payload: {
		count,
	},
});

export const fetchDocs = () => ({
	type: FETCH_DOCS,
});

export const setDocs = (list) => ({
	type: SET_DOCS,
	payload: {
		list,
	},
});

export const confirmToggleSubscription = () => ({
	type: CONFIRM_TOGGLE_SUBSCRIPTION,
});

export const toggleMailSubscription = () => ({
	type: TOGGLE_MAIL_SUBSCRIPTION,
});

export const bugReport = (error) => ({
	type: BUG_REPORT,
	payload: {
		error,
	},
});

export const raisePropertyChanged = () => ({
	type: RAISE_PROPERTY_CHANGED,
});

export const getMachineNotifications = () => ({
	type: GET_MACHINE_NOTIFICATIONS,
});
export const setMachineNotifications = (count) => ({
	type: SET_MACHINE_NOTIFICATIONS,
	payload: {
		count,
	},
});

export const getServiceNotifications = () => ({
	type: GET_SERVICE_NOTIFICATIONS,
});

export const setServiceNotifications = (count) => ({
	type: SET_SERVICE_NOTIFICATIONS,
	payload: {
		count,
	},
});

export const setReaded = () => ({
	type: SET_CHANGELOG_READED,
});

export const getChangelog = () => ({
	type: GET_CHANGELOG,
});

export const setChangelog = (changelog) => ({
	type: SET_CHANGELOG,
	payload: {
		changelog,
	},
});

export const unlockSecret = () => ({
	type: UNLOCK_SECRET,
});

export const setSideNavVisibility = (collapsed) => ({
	type: SET_SIDE_NAV_VISIBILITY,
	payload: {
		collapsed,
	},
});

export const logError = (error) => ({
	type: LOG_ERROR,
	payload: {
		error,
	},
});

export const getAddressedObjects = () => ({
	type: GET_ADDRESSED_OBJECTS,
});

export const setAddressedObjects = (featureCollection) => ({
	type: SET_ADDRESSED_OBJECTS,
	payload: {
		featureCollection,
	},
});

export const setAppScrollability = (isScrollable) => ({
	type: SET_APP_SCROLLABILITY,
	payload: {
		isScrollable,
	},
});
