import React from "react";
import EssSpinner from "./EssSpinner";

export default function LoadingWrapper(props) {
	const isLoading = props.isLoading;
	const icon = props.icon;
	const src = props.src;
	return isLoading ? (
		<div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
			{icon && <EssSpinner icon={icon} size={props.size} />}
			{src && <EssSpinner src={src} size={props.size} />}
			{!src && !icon && <EssSpinner size={props.size} />}
		</div>
	) : (
		<>{props.children}</>
	);
}
