import {connect} from "react-redux";
import {logoutUser} from "redux/actions/login-actions";
import {Button, Switch} from "devextreme-react";
import ChangeAvatar from "components/Login/ChangeAvatar";
import ChangePassword from "components/Login/ChangePassword";
import {Popup, ToolbarItem as PopupToolbar} from "devextreme-react/popup";
import {useEffect, useState} from "react";
import Form, {SimpleItem as Item, GroupItem, Label, RequiredRule, PatternRule, ButtonItem} from "devextreme-react/form";
import {isMobile} from "react-device-detect";
import {bugReport, toggleMailSubscription} from "redux/actions/general-actions";
import SelectLanguage from "./SelectLanguage";
import {useTranslation} from "react-i18next";
import {ButtonToggle} from "reactstrap";
import Docs from "./Docs";
import usePopup from "hooks/usePopup";
import useDevexForm from "hooks/useDevexForm";

export default function UserMenu(props) {
	const secret = props.generalReducer.secret;
	const changeAvatarPopup = usePopup(400, 500);
	const docsPopup = usePopup(400, 500);
	const languagePopup = usePopup(400, 500);
	const bugReportPopup = usePopup(400, 500);
	const changePassPopup = usePopup(400, 500);
	const form = useDevexForm();
	const {t} = useTranslation();
	const hidePopup = () => {
		changeAvatarPopup.hide();
		docsPopup.hide();
		languagePopup.hide();
		bugReportPopup.hide();
		changePassPopup.hide();
	};

	useEffect(() => {
		changeAvatarPopup.hide();
	}, [props.generalReducer.propertyChanged]);

	const submit = () => {
		if (form.validate()) {
			props.reportBug(form.value.comment);
			bugReportPopup.hide();
		}
	};

	const saveButtonOptions = {
		text: t("Submit"),
		icon: "todo",
		onClick: submit,
	};
	const canToggle = props.loginReducer.currentUser?.masterGroup?.canReceiveServiceRequests;
	const subscribed = props.loginReducer.currentUser?.serviceRequestSubscription;
	const toggleSubscription = () => {
		props.toggleMailSubscription();
	};

	return (
		<>
			<Popup showCloseButton={true} title={t("Change avatar")} visible={changeAvatarPopup.visible} {...(isMobile ? {} : changeAvatarPopup.size)} onHiding={hidePopup} dragEnabled={false}>
				<ChangeAvatar />
			</Popup>
			<Popup
				visible={changePassPopup.visible}
				showCloseButton={true}
				{...(isMobile ? {} : changePassPopup.size)}
				onHiding={hidePopup}
				dragEnabled={false}
				showTitle={true}
				title={t("Change password")}>
				<ChangePassword hideModal={hidePopup} />
			</Popup>
			<Popup title={t("Change language")} showCloseButton={true} visible={languagePopup.visible} {...(isMobile ? {} : languagePopup.size)} onHiding={hidePopup} dragEnabled={false}>
				<SelectLanguage hideModal={hidePopup} />
			</Popup>
			<Popup visible={bugReportPopup.visible} showCloseButton={true} {...(isMobile ? {} : bugReportPopup.size)} onHiding={hidePopup} dragEnabled={false} title={t("Report bug")}>
				<PopupToolbar widget="dxButton" toolbar="bottom" location="after" options={saveButtonOptions} />
				<Form ref={form.ref} onFieldDataChanged={form.onValueChanged}>
					<Item isRequired={true} dataField="comment" label={{text: t("Comment")}} editorType="dxTextArea" />
				</Form>
			</Popup>
			<Popup showCloseButton={true} visible={docsPopup.visible} {...(isMobile ? {} : docsPopup.size)} onHiding={hidePopup} dragEnabled={false} title={t("Documentation")}>
				<Docs />
			</Popup>
			<div className="user-card__menu">
				{secret && <Button onClick={changeAvatarPopup.show}>{t("Change avatar")}</Button>}
				<Button onClick={changePassPopup.show}>{t("Change password")}</Button>
				<Button onClick={languagePopup.show}>{t("Change language")}</Button>
				<Button onClick={docsPopup.show}>{t("Documentation")}</Button>
				<Button onClick={bugReportPopup.show}>{t("Report bug")}</Button>
				<Button onClick={props.logout}>{t("Logout")}</Button>
				{/* {canToggle && (
					<div onClick={toggleSubscription} style={{display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
						<span>{t("Service request mail subscription")}:</span>
						<Switch value={subscribed} switchedOffText={t("No")} switchedOnText={t("Yes")} />
					</div>
				)} */}
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	generalReducer: state.general,
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logoutUser());
	},
	reportBug: (comment) => {
		dispatch(bugReport(comment));
	},
	toggleMailSubscription: () => {
		dispatch(toggleMailSubscription());
	},
});

UserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenu);
