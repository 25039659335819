import React, {useState, useEffect} from "react";
import {HubConnectionBuilder, HttpTransportType} from "@microsoft/signalr";
import {connect} from "react-redux";

import {HOST_IP, SIGNALR_URL} from "util/RestClient";
import {faultStore, machinesStore, serviceRequestActiveStore} from "util/DevExtremeStores";
import {updateMachineState} from "redux/actions/machine-actions";
import {getIssueTimeline, getServiceNotes, notifyFaultStatus} from "redux/actions/serviceRequests-actions";
import {getMachineNotifications, getServiceNotifications} from "redux/actions/general-actions";
import {notifyDealerChanged} from "redux/actions/machine-actions";
import {raisePropertyChanged} from "redux/actions/general-actions";

export default function MachineSignalR(props) {
	const [connection, setConnection] = useState(null);

	useEffect(() => {
		const newConnection = new HubConnectionBuilder().withUrl(SIGNALR_URL).withAutomaticReconnect().build();

		setConnection(newConnection);
	}, []);

	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then((result) => {
					connection.on("machineStateChanged", (data) => {
						machinesStore.push([{type: "update", key: data.machineId, data}]);
						props.updateMachineState(data);
						props.raisePropertyChanged();
					});
					connection.on("dealerChanged", (data) => {
						machinesStore.push([{type: "update", key: data.machineId, data}]);
						props.machineDealerChanged(data);
						props.newMachine();
						props.raisePropertyChanged();
					});
					connection.on("faultStatusUpdate", (fault) => {
						faultStore.push([{type: "update", key: fault.faultId, data: fault}]);
						props.updateFaultStatus(fault);
						props.newServiceRequest();
						props.getServiceNotes(fault.serviceRequestId);
						props.getIssueTimeline(fault.faultId);
						props.raisePropertyChanged();
					});
					connection.on("newServiceRequest", (serviceRequest) => {
						serviceRequest.minState = "open";
						serviceRequest.warningCode = "none";
						serviceRequestActiveStore.push([{type: "insert", data: serviceRequest, index: 0}]);
						props.newServiceRequest();
					});
					connection.on("notifyNewMachine", (machine) => {
						machinesStore.push([{type: "insert", data: machine, index: 0}]);
						props.newMachine();
					});
					connection.send("GetConnectedMachines");
				})
				.catch((e) => console.error("Connection failed: ", e));
		}
	}, [connection]);

	return null;
}

const mapStateToProps = (state) => ({
	machineReducer: state.machines,
});

const mapDispatchToProps = (dispatch) => ({
	updateMachineState: (state) => {
		dispatch(updateMachineState(state));
	},
	updateFaultStatus: (fault) => {
		dispatch(notifyFaultStatus(fault));
	},
	newServiceRequest: () => {
		dispatch(getServiceNotifications());
	},
	newMachine: () => {
		dispatch(getMachineNotifications());
	},
	machineDealerChanged: (machine) => {
		dispatch(notifyDealerChanged(machine));
	},
	raisePropertyChanged: () => {
		dispatch(raisePropertyChanged());
	},
	getServiceNotes: (requestId) => {
		dispatch(getServiceNotes(requestId));
	},
	getIssueTimeline: (faultId) => {
		dispatch(getIssueTimeline(faultId));
	},
});

MachineSignalR = connect(mapStateToProps, mapDispatchToProps)(MachineSignalR);
