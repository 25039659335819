import {SET_INPROGRESS_MESSAGE} from "redux/actions/debug-actions";

const initailState = {
	inProgressMessage: null,
};

export const debugReducer = (state = initailState, action) => {
	switch (action.type) {
		case SET_INPROGRESS_MESSAGE: {
			return {...state, inProgressMessage: action.payload.message};
		}
		default:
			return state;
	}
};
