import React, {useState} from "react";

export default function usePopup(width = 500, height = 200) {
	const [visible, setVisible] = useState(false);
	const size = {width, height};
	const show = () => setVisible(true);
	const hide = () => setVisible(false);
	const toggle = () => setVisible(!visible);
	return {visible, show, hide, toggle, size};
}
