import React from "react";
import App from "./App";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {Provider} from "react-redux";
import rootSaga from "./redux/sagas/_root-saga";
import rootReducer from "./redux/reducers/_root-reducer";
import "./i18n";
import {UPDATE_CUSTOMER} from "redux/actions/customer-actions";
import {UPDATE_DEALER} from "redux/actions/dealer-actions";
import {SEND_MERGED_DATA, UPDATE_MACHINE} from "redux/actions/machine-actions";
import {ADD_USER, UPDATE_USER} from "redux/actions/users-actions";
import {SEND_REPORT, UPLOAD_REPORT_FILES} from "redux/actions/serviceReports-actions";
import {ADD_SERVICE_NOTE, UPLOAD_FILES} from "redux/actions/serviceRequests-actions";
import {UPLOAD_PACKAGE} from "redux/actions/package-actions";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			serializableCheck: {
				ignoredActions: [
					UPLOAD_PACKAGE,
					ADD_SERVICE_NOTE,
					UPLOAD_REPORT_FILES,
					SEND_REPORT,
					UPLOAD_FILES,
					UPDATE_CUSTOMER,
					UPDATE_DEALER,
					UPDATE_MACHINE,
					UPDATE_USER,
					ADD_USER,
					SEND_MERGED_DATA,
				],
			},
		}).prepend(sagaMiddleware),
});
sagaMiddleware.run(rootSaga);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>
	</Provider>
);
