import {showAlert} from "redux/actions/Alert-actions";
import {setInprogressMessage} from "redux/actions/debug-actions";
import {setLanguages, updateUserLanguage, getLanguages as getLanguagesAction} from "redux/actions/translation-actions";
import {call, put} from "redux-saga/effects";
import RestClient from "util/RestClient";
import i18n from "../../i18n";

export function* addLanguage(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.addLanguage(payload.code);
		}, action.payload);
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Language added")));
			yield put(getLanguagesAction());
		} else {
			throw "Couldn't add new language";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't add new language")));
	}
}

export function* updateAllLanguages() {
	yield put(setInprogressMessage(i18n.t("Updating all languages")));
	try {
		const res = yield call(async () => {
			return await RestClient.updateAllLanguages();
		});
		if (res.status === 204) {
			yield put(showAlert("success", i18n.t("Languages updated")));
		} else {
			throw "Couldn't update languages";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't update languages")));
	}
	yield put(setInprogressMessage(null));
}

export function* addMissingTag(action) {
	try {
		yield call(async (payload) => {
			return await RestClient.addMissingTag(payload.tag);
		}, action.payload);
	} catch {}
}

export function* getServiceRequestTranslation(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.getServiceRequestTranslation(payload.serviceRequestId);
		}, action.payload);
		if (res.status === 200) {
			res.data.map((data) => {
				i18n.addResource(window.localStorage.language, "translation", data.key, data.value);
			});
			i18n.changeLanguage(window.localStorage.language);
			// res.data.map((data) => {
			// 	i18n.addResource(window.localStorage.language, "translation", data);
			// });
		}
	} catch (e) {
		console.error(e);
	}
}

export function* fetchTranslation(action) {
	try {
		if (window.localStorage.translation && action.payload.code === window.localStorage.language) {
			console.debug("LOADED FROM STORAGE");
			i18n.addResourceBundle(action.payload.code, "translation", JSON.parse(window.localStorage.translation));
			i18n.changeLanguage(action.payload.code);
		} else {
			const res = yield call(async (payload) => {
				return await RestClient.fetchTranslation(payload.code);
			}, action.payload);
			if (res.status === 200) {
				console.debug("LANGUAGE FETCHED");
				window.localStorage.translation = JSON.stringify(res.data);
				window.localStorage.language = action.payload.code;
				i18n.addResourceBundle(action.payload.code, "translation", res.data);
				i18n.changeLanguage(action.payload.code);
			}
		}
	} catch (e) {
		console.error(e);
	}
}

export function* getLanguages() {
	try {
		const res = yield call(async () => {
			return await RestClient.getLanguages();
		});
		if (res.status === 200) {
			yield put(setLanguages(res.data));
		} else {
			throw "Couldn't fetch languages";
		}
	} catch (e) {
		console.error(e);
	}
}

export function* saveUserLanguage(action) {
	try {
		const res = yield call(async (payload) => {
			return await RestClient.saveUserLanguage(payload.code);
		}, action.payload);
		if (res.status === 200) {
			yield put(updateUserLanguage(res.data));
			yield put(showAlert("success", i18n.t("Language saved")));
		} else {
			throw "Couldn't save language";
		}
	} catch (e) {
		console.error(e);
		yield put(showAlert("danger", i18n.t("Couldn't save language")));
	}
}
