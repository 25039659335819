import {createStore} from "devextreme-aspnet-data-nojquery";
import RestClient, {API_URL} from "./RestClient";
import DataSource from "devextreme/data/data_source";
import {fromUTC} from "./Utilities";
import moment from "moment";
import i18n from "../i18n";

//Vice City Stories hehe

export const serviceRequestStates = [
	{value: "rejected", text: i18n.t("Rejected")},
	{value: "open", text: i18n.t("Request Received")},
	{value: "diagnoseonline", text: i18n.t("Diagnose online")},
	{value: "diagnosevisit", text: i18n.t("Diagnose visit")},
	{value: "partsshipping", text: i18n.t("Parts shipping")},
	{value: "partsawaiting", text: i18n.t("Parts awaiting")},
	{value: "partsacceptation", text: i18n.t("Parts acceptation")},
	{value: "plan", text: i18n.t("Plan")},
	{value: "realization", text: i18n.t("Realization")},
	{value: "waitingforconfirmation", text: i18n.t("Waiting for confirmation")},
	{value: "waitingforcustomer", text: i18n.t("Waiting for customer")},

	{value: "confirmedmanually", text: i18n.t("Confirmed manually")},
	{value: "confirmedbyexpiration", text: i18n.t("Confirmed by expiration")},
	{value: "confirmedbyservice", text: i18n.t("Confirmed by service")},

	//Porzućcie wszelką nadzieję, wy, którzy tu wchodzicie
	{value: "diagnoseOnline", text: i18n.t("Diagnose online")},
	{value: "diagnoseVisit", text: i18n.t("Diagnose visit")},
	{value: "partsShipping", text: i18n.t("Parts shipping")},
	{value: "partsAwaiting", text: i18n.t("Parts awaiting")},
	{value: "partsAcceptation", text: i18n.t("Parts acceptation")},
	{value: "waitingForConfirmation", text: i18n.t("Waiting for confirmation")},
	{value: "waitingForCustomer", text: i18n.t("Waiting for customer")},

	{value: "confirmedManually", text: i18n.t("Confirmed manually")},
	{value: "confirmedByExpiration", text: i18n.t("Confirmed by expiration")},
	{value: "confirmedByService", text: i18n.t("Confirmed by service")},

	//nie no serio musiałem taką patolę zrobić :/
	{value: "Rejected", text: i18n.t("Rejected")},
	{value: "Open", text: i18n.t("Request Sent")},
	{value: "DiagnoseOnline", text: i18n.t("Diagnose online")},
	{value: "DiagnoseVisit", text: i18n.t("Diagnose visit")},
	{value: "PartsShipping", text: i18n.t("Parts shipping")},
	{value: "PartsAwaiting", text: i18n.t("Parts awaiting")},
	{value: "PartsAcceptation", text: i18n.t("Parts acceptation")},
	{value: "Plan", text: i18n.t("Plan")},
	{value: "WaitingForCustomer", text: i18n.t("Waiting for customer")},

	{value: "Realization", text: i18n.t("Realization")},
	{value: "WaitingForConfirmation", text: i18n.t("Waiting for confirmation")},
	{value: "ConfirmedManually", text: i18n.t("Confirmed manually")},
	{value: "ConfirmedByExpiration", text: i18n.t("Confirmed by expiration")},
	{value: "ConfirmedByExpiration", text: i18n.t("Confirmed by service")},
];

export const headModels = [
	{value: "twelvemz", text: "12.0MZ"},
	{value: "tendotthree", text: "10.3"},
	{value: "other", text: i18n.t("Other")},
	{value: "tendotfive", text: "10.5"},
	{value: "eleven", text: "11.0"},
	{value: "Other", text: i18n.t("Other")},
	{value: "tenDotThree", text: "10.3"},
	{value: "tenDotFive", text: "10.5"},
	{value: "twelveMz", text: "12.0 MZ"},
	{value: "TenDotThree", text: "10.3"},
	{value: "TenDotFive", text: "10.5"},
	{value: "Eleven", text: "11.0"},
	{value: "TwelveMz", text: "12.0 MZ"},
];

export const headModelsForm = [
	{value: "tendotthree", text: "10.3"},
	{value: "tendotfive", text: "10.5"},
	{value: "eleven", text: "11.0"},
	{value: "twelvemz", text: "12.0MZ"},
	{value: "other", text: i18n.t("Other")},
];

export const warningCodes = [
	{value: "none", text: i18n.t("None")},
	{value: "toomany", text: i18n.t("Too many - warning")},
	{value: "toomanyban", text: i18n.t("Too many - banned")},
	{value: "stoppedmisuse", text: i18n.t("Out of order misuse - warning")},
	{value: "stoppedmisuseban", text: i18n.t("Out of order misuse - banned")},
	{value: "both", text: i18n.t("Too many & out of order misuse - warning")},
	{value: "bothban", text: i18n.t("Too many & out of order misuse - banned")},
	{value: "reverted", text: i18n.t("Reverted")},

	{value: "tooMany", text: i18n.t("Too many - warning")},
	{value: "tooManyBan", text: i18n.t("Too many - banned")},
	{value: "stoppedMisuse", text: i18n.t("Out of order misuse - warning")},
	{value: "stoppedMisuseBan", text: i18n.t("Out of order misuse - banned")},
	{value: "bothBan", text: i18n.t("Too many & out of order misuse - banned")},

	{value: "None", text: i18n.t("None")},
	{value: "TooMany", text: i18n.t("Too many - warning")},
	{value: "TooManyBan", text: i18n.t("Too many - banned")},
	{value: "StoppedMisuse", text: i18n.t("Out of order misuse - warning")},
	{value: "StoppedMisuseBan", text: i18n.t("Out of order misuse - banned")},
	{value: "Both", text: i18n.t("Too many & out of order misuse - warning")},
	{value: "BothBan", text: i18n.t("Too many & out of order misuse - banned")},
	{value: "Reverted", text: i18n.t("Reverted")},
];

export const machineStates = [
	{value: 0, text: i18n.t("Offline")},
	{value: 1, text: i18n.t("Standby")},
	{value: 2, text: i18n.t("Active")},
	{value: 3, text: i18n.t("Hold")},
	{value: 4, text: i18n.t("Error")},
	{value: 99, text: i18n.t("Online")},
];

export const laserLogTypes = [
	{value: "alarms", text: i18n.t("Error")},
	{value: "alarms2", text: i18n.t("Error 2")},
	{value: "chillerAlarms", text: i18n.t("Chiller Error")},
	{value: "chillerAlarms2", text: i18n.t("Chiller Error 2")},
	{value: "chillerWarnings", text: i18n.t("Chiller Warning")},
];

let isNotBusy = true;

export const RETRY_LIMIT = 10;

async function refreshToken(e) {
	if (e.xhr.status === 401 && isNotBusy) {
		isNotBusy = false;
		await RestClient.refreshToken();
		isNotBusy = true;
	}
}

export const dateFromUtc = (e) => {
	let dateUtc = fromUTC(e.value);
	let m = moment(dateUtc);
	return `${m.format("yy/MM/DD, HH:mm")}`;
};

export let logsStore = createStore({
	key: "logEntryId",
	loadUrl: `${API_URL}/main/logs`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let machinesStore = createStore({
	key: "machineId",
	loadUrl: `${API_URL}/machines/get`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let usersStore = createStore({
	key: "essUserId",
	loadUrl: `${API_URL}/users/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/users`,
	insertMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let portalUsersStore = createStore({
	key: "portalUserId",
	loadUrl: `${API_URL}/customers/portalusers`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let portalUsersByCustomerId = (customerId) => {
	return new DataSource({
		store: portalUsersStore,
		filter: ["customerId", "=", customerId],
		onAjaxError: (e) => refreshToken(e),
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
	});
};

export let assignableUsersStore = new DataSource({store: usersStore, filter: ["canBeAssignedToServiceRequest", "=", true], onAjaxError: (e) => refreshToken(e)});

export let groupsStore = createStore({
	key: "userGroupId",
	loadUrl: `${API_URL}/groups/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/groups`,
	insertMethod: "POST",
	updateUrl: `${API_URL}/groups`,
	updateMethod: "PUT",
	deleteUrl: `${API_URL}/groups`,
	deleteMethod: "DELETE",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export const groupsByAccessLevel = (accessLevel) => {
	return new DataSource({
		store: groupsStore,
		filter: ["groupLevel", "<", accessLevel],
		onAjaxError: (e) => refreshToken(e),
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
	});
};

export let customersStore = createStore({
	key: "customerId",
	loadUrl: `${API_URL}/customers/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/customers`,
	insertMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export const customersAlphabeticalStore = () => {
	return new DataSource({
		store: customersStore,
		sort: [{selector: "name", desc: false}],
	});
};

export let dealersStore = createStore({
	key: "dealerId",
	loadUrl: `${API_URL}/dealers/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/dealers`,
	insertMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export const dealersAlphabeticalStore = () => {
	return new DataSource({
		store: dealersStore,
		sort: [{selector: "name", desc: false}],
	});
};

export let packagesStore = createStore({
	key: "packageId",
	loadUrl: `${API_URL}/packages/get`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let setsStore = createStore({
	key: "setId",
	loadUrl: `${API_URL}/sets/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/sets`,
	insertMethod: "POST",
	updateUrl: `${API_URL}/sets`,
	updateMethod: "PUT",
	deleteUrl: `${API_URL}/sets`,
	deleteMethod: "DELETE",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let channelsStore = createStore({
	key: "channelId",
	loadUrl: `${API_URL}/channels/get`,
	loadMethod: "POST",
	insertUrl: `${API_URL}/channels`,
	insertMethod: "POST",
	updateUrl: `${API_URL}/channels`,
	updateMethod: "PUT",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let serviceRequestActiveStore = createStore({
	key: "serviceRequestId",
	loadUrl: `${API_URL}/requests/active`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let serviceReportsStore = createStore({
	key: "serviceReportId",
	loadUrl: `${API_URL}/reports/get`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let serviceRequestArchiveStore = createStore({
	key: "serviceRequestId",
	loadUrl: `${API_URL}/requests/archive`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let logDescriptorsStore = createStore({
	key: "codeId",
	loadUrl: `${API_URL}/requests/descriptions`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export let faultStore = createStore({
	key: "faultId",
	loadUrl: `${API_URL}/requests/faults`,
	loadMethod: "POST",
	onBeforeSend: (_, ajaxOptions) => {
		ajaxOptions.xhrFields = {withCredentials: true};
	},
	onAjaxError: (e) => refreshToken(e),
});

export function preloadStores() {
	machinesStore.load();
	customersStore.load();
	dealersStore.load();
	usersStore.load();
	portalUsersStore.load();
	groupsStore.load();
	serviceRequestActiveStore.load();
	faultStore.load();
}

export function getPackagesByType(type) {
	return new DataSource({
		store: packagesStore,
		filter: ["type", "=", type],
		onAjaxError: (e) => refreshToken(e),
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		sort: [{selector: "version", desc: true}],
	});
}

export function getMachinesByCustomer(customerId) {
	return new DataSource({
		store: machinesStore,
		filter: ["customerId", "=", customerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getUserLogs(userId) {
	return new DataSource({
		store: logsStore,
		filter: [[["actorId", "=", userId], "and", ["actor", "=", "user"]], "or", [["subjectId", "=", userId], "and", ["subject", "=", "user"]]],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getMachineLogs(machineId) {
	return new DataSource({
		store: logsStore,
		filter: [[["actorId", "=", machineId], "and", ["actor", "=", "machine"]], "or", [["subjectId", "=", machineId], "and", ["subject", "=", "machine"]]],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getMachinesByDealer(dealerId) {
	return new DataSource({
		store: machinesStore,
		filter: ["dealerId", "=", dealerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getFaultsByRequest(serviceRequestId) {
	return new DataSource({
		store: faultStore,
		filter: ["serviceRequestId", "=", serviceRequestId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getActiveRequestsByMachine(machineId) {
	return new DataSource({
		store: serviceRequestActiveStore,
		filter: ["machineId", "=", machineId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getArchiveRequestsByMachine(machineId) {
	return new DataSource({
		store: serviceRequestArchiveStore,
		filter: ["machineId", "=", machineId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getActiveRequestsByCustomer(customerId) {
	return new DataSource({
		store: serviceRequestActiveStore,
		filter: ["customerId", "=", customerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getArchiveRequestsByCustomer(customerId) {
	return new DataSource({
		store: serviceRequestArchiveStore,
		filter: ["customerId", "=", customerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getActiveRequestsByDealer(dealerId) {
	return new DataSource({
		store: serviceRequestActiveStore,
		filter: ["dealerId", "=", dealerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export function getArchiveRequestsByDealer(dealerId) {
	return new DataSource({
		store: serviceRequestArchiveStore,
		filter: ["dealerId", "=", dealerId],
		onBeforeSend: (_, ajaxOptions) => {
			ajaxOptions.xhrFields = {withCredentials: true};
		},
		onAjaxError: (e) => refreshToken(e),
	});
}

export const dxToUpperCase = (value) => {
	return value.valueText.toUpperCase();
};

export const setNone = (value) => {
	if (!value.value) return `(${i18n.t("none")})`;
	else return value.valueText;
};

export const concatCoords = (value) => {
	return value.latitude === 0 && value.longitude === 0 ? null : [value.latitude, value.longitude];
};
