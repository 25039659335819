import {
	CLEAR_IPC,
	SET_ACTIVITIES_LOGS,
	SET_ACTIVITIES_LOGS_STATUS,
	SET_COLLISIONS,
	SET_CONFLICT,
	SET_CONNECTION_LOGS,
	SET_CONNECTION_LOGS_STATUS,
	SET_EULA,
	SET_LEAK_TESTS,
	SET_MACHINE_CHARTS,
	SET_MACHINE_CHARTS_LOADING_STATUS,
	SET_MAINTENANCE,
} from "redux/actions/machine-actions";
import {MACHINE_PUSH_NOTE} from "redux/actions/machine-actions";
import {SET_MACHINE_NOTES_LOADING_STATUS} from "redux/actions/machine-actions";
import {REMOVE_MACHINE_NOTE} from "redux/actions/machine-actions";
import {NOTIFY_DEALER_CHANGED} from "redux/actions/machine-actions";
import {SET_RESET_COUNTERS} from "redux/actions/machine-actions";
import {UPDATE_FETCHED_MACHINE_STATE} from "redux/actions/machine-actions";
import {UPDATE_MACHINE_NOTE} from "redux/actions/machine-actions";
import {SET_MACHINE_NOTES} from "redux/actions/machine-actions";
import {SET_MACHINE_LOADING_STATUS} from "redux/actions/machine-actions";
import {SET_MACHINE} from "redux/actions/machine-actions";
import {capitalize, fromUTC} from "util/Utilities";

const initialState = {
	fetchedMachine: null,
	fetchedMachineLiveStatus: 0,
	fetchedMachineEula: null,
	fetchedConnections: null,
	fetchedActivities: null,
	fetchedCharts: null,
	fetchedMaintenances: null,
	fetchedLeaktests: null,
	fetchedCollisions: null,
	isLoading: true,
	isLoadingConnections: true,
	isLoadingActivities: true,
	isLoadingCharts: true,
	isLoadingNotes: true,
};

export const machinesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_MACHINE_CHARTS: {
			return {...state, fetchedCharts: action.payload.list};
		}
		case SET_LEAK_TESTS: {
			return {...state, fetchedLeaktests: action.payload.list};
		}
		case SET_MAINTENANCE: {
			return {...state, fetchedMaintenances: action.payload.list};
		}
		case SET_COLLISIONS: {
			return {...state, fetchedCollisions: action.payload.list};
		}
		case SET_ACTIVITIES_LOGS: {
			return {...state, fetchedActivities: action.payload.activities};
		}
		case SET_CONNECTION_LOGS: {
			return {...state, fetchedConnections: action.payload.connections};
		}
		case NOTIFY_DEALER_CHANGED: {
			if (state.fetchedMachine.machineId === action.payload.machine.machineId) {
				return {...state, fetchedMachine: action.payload.machine};
			}
			return state;
		}
		case SET_EULA: {
			return {...state, fetchedMachineEula: action.payload.eula};
		}
		case SET_RESET_COUNTERS: {
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.urgentSRCounter = 0;
			machine.regularSRCounter = 0;
			return {...state, fetchedMachine: machine};
		}
		case SET_MACHINE: {
			let machine = action.payload.machine.value;
			machine.headModel = machine?.headModel?.toLowerCase() ?? "other";
			machine.automationType = capitalize(machine?.automationType);
			machine.laserType = machine?.laserType.toUpperCase();
			machine.automationOrientation = capitalize(machine?.automationOrientation);
			machine.registerDate = fromUTC(new Date(machine?.registerDate))?.toJSON();
			machine.machineCommissionDate = fromUTC(new Date(machine?.machineCommissionDate))?.toJSON();
			machine.automationCommissionDate = fromUTC(new Date(machine?.automationCommissionDate))?.toJSON();
			machine.lastLogin = fromUTC(new Date(machine?.lastLogin))?.toJSON();

			return {...state, fetchedMachine: machine, fetchedMachineLiveStatus: action.payload.machine.state, isConflict: false};
		}
		case UPDATE_FETCHED_MACHINE_STATE: {
			if (action.payload.state.machineId === state.fetchedMachine.machineId) {
				return {...state, fetchedMachineLiveStatus: action.payload.state.stateId};
			}
			return state;
		}
		case CLEAR_IPC: {
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.licenseKey = "";
			machine.computerSerialNumber = "";
			machine.licenseKey = "";
			machine.registerDate = "";
			return {...state, fetchedMachine: machine};
		}
		case SET_MACHINE_NOTES: {
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.notes = action.payload.notes;
			return {...state, fetchedMachine: machine};
		}
		case MACHINE_PUSH_NOTE: {
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.notes.unshift(action.payload.note);
			return {...state, fetchedMachine: machine};
		}
		case UPDATE_MACHINE_NOTE: {
			let i = state.fetchedMachine.notes.findPropertyIndex("noteId", action.payload.note.noteId);
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.notes[i] = action.payload.note;
			return {...state, fetchedMachine: machine};
		}
		case REMOVE_MACHINE_NOTE: {
			let i = state.fetchedMachine.notes.findPropertyIndex("noteId", action.payload.note.noteId);
			let machine = JSON.parse(JSON.stringify(state.fetchedMachine));
			machine.notes.splice(i, 1);
			return {...state, fetchedMachine: machine};
		}
		case SET_MACHINE_NOTES_LOADING_STATUS: {
			return {...state, isLoadingNotes: action.payload.isLoading};
		}
		case SET_MACHINE_LOADING_STATUS: {
			return {...state, isLoading: action.payload.isLoading};
		}
		case SET_MACHINE_CHARTS_LOADING_STATUS: {
			return {...state, isLoadingCharts: action.payload.isLoading};
		}
		case SET_CONNECTION_LOGS_STATUS: {
			return {...state, isLoadingConnections: action.payload.isLoading};
		}
		case SET_ACTIVITIES_LOGS_STATUS: {
			return {...state, isLoadingActivities: action.payload.isLoading};
		}
		default:
			return state;
	}
};
