import {SET_LANGUAGES, UPDATE_USER_LANGUAGE} from "redux/actions/translation-actions";

const initialState = {
	languagesList: [],
};

export const translationReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LANGUAGES: {
			return {...state, languagesList: action.payload.list};
		}
		default:
			return state;
	}
};
