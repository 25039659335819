import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import "styles/General/Login.scss";
import logo from "resources/My ESP Logo white.svg";
import {useEffect} from "react";
import {confirmBrowser} from "redux/actions/login-actions";
import EssSpinner from "components/Utilities/EssSpinner";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AddBrowser(props) {
	const {token} = useParams();
	const navigate = useNavigate();
	const {t} = useTranslation();
	useEffect(() => {
		props.confirmBrowser(token, navigate);
	}, []);
	const result = props.loginReducer.confirmResult;
	return (
		<div id="login__wrapper">
			<div className="login__container">
				{result === 0 && (
					<div>
						<EssSpinner light />
					</div>
				)}
				{result > 0 && (
					<div className="login__logo-container">
						<img src={logo} alt="logo" className="login__logo" />
					</div>
				)}
				<div style={{color: "white", textAlign: "center"}}>
					{result === 0 && <h5>{t("Confirming browser")}</h5>}
					{result === 1 && <h5>{t("Browser confirmed, you can return to login page")}</h5>}
					{result === 2 && <h5 style={{color: "red"}}>{t("Couldn't confirm browser")}</h5>}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	confirmBrowser: (token, navigate) => {
		dispatch(confirmBrowser(token, navigate));
	},
});

AddBrowser = connect(mapStateToProps, mapDispatchToProps)(AddBrowser);
