import {faAddressBook, faEdit, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EssSpinner from "components/Utilities/EssSpinner";
import LoadingWrapper from "components/Utilities/LoadingWrapper";
import YesNoIcon from "components/Utilities/YesNoIcon";
import {Button} from "devextreme-react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useParams} from "react-router";
import {Table} from "reactstrap";
import {activatePortalUser, getPortalUser} from "redux/actions/customer-actions";
import "styles/Customers/CustomerDetails.scss";
import "styles/Ess/EssTabs.scss";
import {fromUTC} from "util/Utilities";
import PortalUserEdit from "./PortalUserEdit";

export default function PortalUserDetails(props) {
	const {portalUserId} = useParams();
	const {t} = useTranslation();
	const [editMode, setEditMode] = useState(false);
	const toggleEdit = () => setEditMode(!editMode);
	useEffect(() => {
		props.getPortalUserById(parseInt(portalUserId));
	}, [portalUserId]);
	const activate = () => {
		props.activatePortalUser(parseInt(portalUserId));
	};
	const portalUser = props.customerReducer.fetchedPortalUser;
	const isLoading = props.customerReducer.isLoading;
	return (
		<div className="portal-user__container">
			<LoadingWrapper icon={faAddressBook} isLoading={isLoading}>
				<div style={{width: "100%", height: "2.5rem", display: "flex", justifyContent: "space-between"}}>
					<h5>
						{editMode && t("Edit")} {t("Portal user")}
					</h5>
					<div>
						{!portalUser?.portalActive && portalUser?.portalPending && (
							<Button onClick={activate} style={{width: "2rem", height: "2rem", marginRight: "10px"}} hint={t("Activate")}>
								<FontAwesomeIcon icon={faPowerOff} color="black" />
							</Button>
						)}
						<Button onClick={toggleEdit} style={{width: "2rem", height: "2rem"}} hint={t("Edit")}>
							<FontAwesomeIcon icon={faEdit} color="black" />
						</Button>
					</div>
				</div>
				<div className="mi-section">
					{editMode ? (
						<PortalUserEdit toggleEdit={toggleEdit}>{portalUser}</PortalUserEdit>
					) : (
						<div>
							<Table striped={true} className="custom-table">
								<tbody>
									<tr>
										<td>{t("Name")}:</td>
										<th>{portalUser?.name ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Customer")}:</td>
										<th>{portalUser?.customer?.name ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Login")}:</td>
										<th>{portalUser?.login ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Email")}:</td>
										<th>{portalUser?.email ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Phone")}:</td>
										<th>{portalUser?.phone ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Guid")}:</td>
										<th>{portalUser?.guid ?? "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Last login")}:</td>
										<th>{portalUser?.lastLogin ? fromUTC(new Date(portalUser.lastLogin)).toLocaleString() : "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Modified")}:</td>
										<th>{portalUser?.modified ? fromUTC(new Date(portalUser.modified)).toLocaleString() : "N/A"}</th>
									</tr>
									<tr>
										<td>{t("Main account")}:</td>
										<th>
											<YesNoIcon width="1.5rem" height="1.5rem">
												{portalUser?.isMainAccount}
											</YesNoIcon>
										</th>
									</tr>
									<tr>
										<td>{t("Active")}:</td>
										<th>
											<YesNoIcon width="1.5rem" height="1.5rem">
												{portalUser?.portalActive}
											</YesNoIcon>
										</th>
									</tr>
									{portalUser?.portalPending && (
										<tr>
											<td>{t("Pending")}:</td>
											<th>
												<YesNoIcon width="1.5rem" height="1.5rem">
													{portalUser?.portalPending}
												</YesNoIcon>
											</th>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					)}
				</div>
			</LoadingWrapper>
		</div>
	);
}

const mapStateToProps = (state) => ({
	customerReducer: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
	getPortalUserById: (portalUserId) => {
		dispatch(getPortalUser(portalUserId));
	},
	activatePortalUser: (portalUserId) => {
		dispatch(activatePortalUser(portalUserId));
	},
});

PortalUserDetails = connect(mapStateToProps, mapDispatchToProps)(PortalUserDetails);
