export const UPDATE_COORDS = "DEBUG/UPDATE_COORDS";
export const SET_INPROGRESS_MESSAGE = "DEBUG/SET_INPROGRESS_MESSAGE";
export const SEND_EMAILS = "DEBUG/SEND_EMAILS";
export const SEND_TEST_LOG = "DEBUG/SEND_TEST_LOG";
export const SHOW_ALL_ALERTS = "DEBUG/SHOW_ALL_ALERTS";
export const CREATE_MISSING_ESS_MACHINES = "DEBUG/CREATE_MISSING_MACHINES";

export const createMissingEssMachines = () => ({
	type: CREATE_MISSING_ESS_MACHINES,
});

export const showAllAlerts = () => ({
	type: SHOW_ALL_ALERTS,
});

export const sendTestLog = (message) => ({
	type: SEND_TEST_LOG,
	payload: {
		message,
	},
});

export const setInprogressMessage = (message) => ({
	type: SET_INPROGRESS_MESSAGE,
	payload: {
		message,
	},
});

export const sendEmails = (type, address) => ({
	type: SEND_EMAILS,
	payload: {
		type,
		address,
	},
});

export const updateCoords = () => ({
	type: UPDATE_COORDS,
});
