import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect} from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {capitalize} from "util/Utilities";

export default function Location(props) {
	const [history, setHistory] = useState(["/dashboard"]);
	const [currentLocation, setCurrentLocation] = useState("Dashboard");
	const [previousLocation, setPreviousLocation] = useState(null);
	const {t} = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		let _location = window.location.pathname;
		let pathArray = _location.split("/");
		pathArray.shift();
		if (pathArray.length === 1) {
			setHistory([_location]);
			setCurrentLocation(t(parseLocation(pathArray[0])));
		} else {
			let _history = [...history];
			_history.push(_location);
			setHistory(_history);
			let arr = _location.split("/");
			let currentLocationString = `${parseLocation(arr[2])}`;
			let arr2 = _history[_history.length - 2].split("/");
			let previousLocationString = arr2.length > 2 ? `${parseLocation(arr2[2])}` : `${parseLocation(arr2[1])}`;
			setCurrentLocation(t(currentLocationString));
			setPreviousLocation(t(previousLocationString));
		}
	}, [window.location.pathname]);

	const parseLocation = (location) => {
		return capitalize(decodeURI(location)).replace("-", " ");
	};

	const goBack = () => {
		let _history = [...history];
		_history.pop();
		let destination = _history.pop();
		navigate(destination);
		setHistory(_history);
	};

	return history.length < 2 ? (
		<div className="location">{currentLocation}</div>
	) : (
		<div className="location">
			<span style={{color: "#0d6efd", cursor: "pointer"}} onClick={goBack}>
				{previousLocation}
			</span>
			<FontAwesomeIcon icon={faArrowRight} color="black" style={{marginLeft: "5px", marginRight: "5px"}} />
			<span>{currentLocation}</span>
		</div>
	);
}
