import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {changeAvatar} from "redux/actions/login-actions";
import React from "react";
import {
	faUser as User,
	faUserNinja as Ninja,
	faUserTie as Corp,
	faUserSecret as Incognito,
	faUserNurse as Nurse,
	faUserInjured as Injured,
	faUserGraduate as Graduate,
	faUserAstronaut as Astronaut,
	faAngry,
	faAtom,
	faBiohazard,
	faBomb,
	faCat,
	faChessKing,
	faCrow,
	faDiceD20,
	faDog,
	faDove,
	faDragon,
	faFish,
	faFlushed,
	faFrog,
	faGem,
	faGhost,
	faGrinTears,
	faHatWizard,
	faJedi,
	faMask,
	faMeteor,
	faPeace,
	faPizzaSlice,
	faRobot,
	faSkull,
	faSnowman,
	faSpider,
	faSun,
	faUserMd,
	faYinYang,
} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

export default function ChangeAvatar(props) {
	const userId = props.loginReducer.currentUser?.essUserId;

	const changeAvatar = (id) => {
		props.changeAvatar(userId, id);
	};

	return (
		<div className="avatar__container">
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(1)} icon={User} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(2)} icon={Ninja} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(3)} icon={Corp} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(4)} icon={Incognito} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(5)} icon={Nurse} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(6)} icon={Injured} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(7)} icon={Graduate} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(8)} icon={Astronaut} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(9)} icon={faUserMd} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(10)} icon={faAtom} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(11)} icon={faBiohazard} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(12)} icon={faBomb} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(13)} icon={faCat} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(14)} icon={faChessKing} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(15)} icon={faCrow} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(16)} icon={faDiceD20} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(17)} icon={faDog} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(18)} icon={faDove} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(19)} icon={faDragon} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(20)} icon={faFish} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(21)} icon={faFlushed} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(22)} icon={faFrog} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(23)} icon={faGem} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(24)} icon={faGhost} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(25)} icon={faGrinTears} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(26)} icon={faHatWizard} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(27)} icon={faJedi} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(28)} icon={faMask} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(29)} icon={faMeteor} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(30)} icon={faPeace} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(31)} icon={faPizzaSlice} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(32)} icon={faRobot} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(33)} icon={faSkull} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(34)} icon={faSnowman} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(35)} icon={faSpider} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(36)} icon={faSun} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(37)} icon={faAngry} />
			<FontAwesomeIcon className="user-card__icon" color="white" onClick={() => changeAvatar(38)} icon={faYinYang} />
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginReducer: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	changeAvatar: (id, avatarId) => {
		dispatch(changeAvatar(id, avatarId));
	},
});

ChangeAvatar = connect(mapStateToProps, mapDispatchToProps)(ChangeAvatar);
