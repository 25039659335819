export const GET_LANGUAGES = "TRANSLATION/GET_LANGUAGES";
export const SET_LANGUAGES = "TRANSLATION/SET_LANGUAGES";
export const SAVE_USER_LANGUAGE = "TRANSLATION/SAVE_USER_LANGUAGE";
export const UPDATE_USER_LANGUAGE = "TRANSLATION/UPDATE_USER_LANGUAGE";
export const FETCH_TRANSLATION = "TRANSLATION/FETCH_TRANSLATION";
export const ADD_MISSING_TAG = "TRANSLATION/ADD_MISSING_TAG";
export const ADD_LANGUAGE = "TRANSLATION/ADD_LANGUAGE";
export const UPDATE_ALL_LANGUAGES = "TRANSLATION/UPDATE_LANGUAGES";
export const GET_SERVICE_REQUEST_TRANSLATION = "TRANSLATION/GET_SERVICE_REQ_TRANSLATION";

export const getServiceRequestTranslation = (serviceRequestId) => ({
	type: GET_SERVICE_REQUEST_TRANSLATION,
	payload: {
		serviceRequestId,
	},
});

export const updateAllLanguages = () => ({
	type: UPDATE_ALL_LANGUAGES,
});

export const addLanguage = (code) => ({
	type: ADD_LANGUAGE,
	payload: {
		code,
	},
});

export const addMissingTag = (tag) => ({
	type: ADD_MISSING_TAG,
	payload: {
		tag,
	},
});

export const fetchTranslation = (code) => ({
	type: FETCH_TRANSLATION,
	payload: {
		code,
	},
});

export const saveUserLanguage = (code) => ({
	type: SAVE_USER_LANGUAGE,
	payload: {
		code,
	},
});

export const updateUserLanguage = (language) => ({
	type: UPDATE_USER_LANGUAGE,
	payload: {
		language,
	},
});

export const getLanguages = () => ({
	type: GET_LANGUAGES,
});

export const setLanguages = (list) => ({
	type: SET_LANGUAGES,
	payload: {
		list,
	},
});
